import React, {useState, useEffect} from 'react';
import AdminPage from 'components/AdminPage';
import AwardToolbar from 'crud/awards/AwardToolbar'
import AwardCard from 'crud/awards/AwardCard'
import AwardForm from 'crud/awards/AwardForm'
import { object, string, boolean, array } from 'yup';
import useFirestore from 'hooks/useFirestore';

export default function Awards({ params }) {

	const [subTitle, setSubTile] = useState("");

	const {getItem} =  useFirestore();

	useEffect(()=>{
		getItem("topics", params.topicId).then((result) => {
			setSubTile(`Temática: ${result.item.title}`);
		})
	}, [getItem, params])

	const schema = object({
		actived: boolean(),
		title: string().required('El título es obligatorio'),
		roles: array(
			object({
				value: string(),
				label: string(),
				checked: boolean()
			})
		),
		pdf: array(),
		description: string().required('La descripcion es obligatoria')
	});

	const sortOptions = [
		{
			value: 'title',
			label: 'Título'
		},
		{
			value: 'description',
			label: 'Descripción'
		}
	];

	const awardCard = ({ item, selected, onSelectEditedItem, onSelectDeletedItem, onCleanItemId }) => (
		<AwardCard onSelectEditedItem={onSelectEditedItem} onSelectDeletedItem={onSelectDeletedItem} onCleanItemId={onCleanItemId} item={item} selected={selected} topicId={params.topicId} />
	);

	return (
		<AdminPage
			title="Premios"
			subTitle={subTitle}
			seoTitle="Administración de premios"
			sortOptions={sortOptions}
			collection={`topics/${params.topicId}/awards`}
			schema={schema}
			ItemFormFields={AwardForm}
			ItemCard={awardCard}
			ItemToolBar={AwardToolbar}
			backTo={{title: "Volver a temáticas", url: `/administracion/tematicas/`}}

		/>
	);
}

import * as React from 'react';
import { icons } from 'definitions';

export default function AwardIcon(props) {
	return (
		<svg height={icons.entities.height} width={icons.entities.width} fill={icons.entities.color} viewBox="0 0 512 512" {...props} style={{ ...props.style, cursor: 'pointer' }}>
			<path d="M222.25 172.143L214.401 217l41.099-21.179L296.599 217l-7.849-44.857L322 140.374l-45.95-6.545-20.55-40.813-20.55 40.813-45.95 6.545z" />
			<path d="M418.913 39c.064-6.437.087-12.779.087-19V0H91v20c0 6.221.023 12.563.087 19H0v20c0 51.185 13.454 94.431 41.131 132.212 23.498 32.075 53.051 54.588 79.125 74.45 16.226 12.36 30.689 23.394 40.918 34.548 20.079 26.582 44.855 49.663 73.826 68.778V402h-10c-49.626 0-90 40.374-90 90v20h240v-20c0-49.626-40.374-90-90-90h-10v-33.012c28.919-19.081 53.66-42.114 73.72-68.636 10.331-11.24 25.087-22.366 41.65-34.838 26.354-19.844 56.224-42.336 79.987-74.374C498.379 153.362 512 110.139 512 59V39h-93.087zM40.86 79h51.338c1.935 39.112 6.886 80.589 19.317 121.626a360.375 360.375 0 003.096 9.674C78.983 180.355 46.493 142.702 40.86 79zM285 442c20.464 0 38.095 12.355 45.825 30h-151.65c7.73-17.645 25.361-30 45.825-30h60zm33.929-168.313c-3.831 4.189-7.347 8.507-10.435 13.02l.082.056c-14.644 17.261-32.291 33.232-53.576 47.498-21.285-14.266-38.932-30.236-53.576-47.499l.082-.056c-3.131-4.576-6.69-8.952-10.567-13.197C140.933 205.571 132.174 120.385 131.122 40h247.756c-1.053 80.456-9.828 165.719-59.949 233.687zm76.149-62.461c1.18-3.5 2.324-7.028 3.406-10.6 12.432-41.037 17.383-82.514 19.317-121.626h53.323c-5.79 64.352-39.454 102.106-76.046 132.226z" />
		</svg>
	);
}

import React from 'react';
import AdminPage from 'components/AdminPage';
import SponsorToolbar from 'crud/sponsors/SponsorToolbar'
import SponsorCard from 'crud/sponsors/SponsorCard'
import SponsorForm from 'crud/sponsors/SponsorForm'
import { object, string, boolean, array } from 'yup';

export default function Sponsors() {

	const schema = object({
		actived: boolean(),
		title: string().required('El título es obligatorio'),
		color: string().required('El color es obligatorio'),
		size: string().required('El tamaño de los logos es obligatorio'),
		logos: array().min(1, 'Debe cargar por lo menos un logo'),
	});

	const sortOptions = [
		{
			value: 'title',
			label: 'Título'
		},
		{
			value: 'order',
			label: 'Orden'
		}

	];

	return (
		<AdminPage
			title="Auspiciantes"
			seoTitle="Administración de auspiciantes"
			sortOptions={sortOptions}
			collection="sponsors"
			schema={schema}
			ItemFormFields={SponsorForm}
			ItemCard={SponsorCard}
			ItemToolBar={SponsorToolbar}
		/>
	);
}

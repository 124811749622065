import React, {useState, useEffect} from 'react';
import AdminPage from 'components/AdminPage';
import QuestToolbar from 'crud/questionnaire/QuestToolbar'
import QuestCard from 'crud/questionnaire/QuestCard'
import QuestForm from 'crud/questionnaire/QuestForm'
import { object, string, boolean, number, array } from 'yup';
import useFirestore from 'hooks/useFirestore';

export default function Questionnaire({ params }) {

	const [subTitle, setSubTile] = useState("");

	const {getItem} =  useFirestore();

	useEffect(()=>{
		getItem(`topics/${params.topicId}/awards/${params.awardId}/sections/`, params.sectionId).then((result) => {
			setSubTile(`Sección: ${result.item.title}`);
		})
	}, [getItem, params])

	const schema = object({
		actived: boolean(),
		title: string().required('El título es obligatorio'),
		helpText: string(),
		order: number().positive().required('El orden es obligatorio'),
		questionType: string().required('El tipo de pregunta es obligatoria'),
		wordCounter: number().when('questionType', {
			is: "text",
			then: number().positive().required('La cantidad de palabras es obligatoria')
		}),
		options: array().when('questionType', {
			is: "ponderation",
			then: array().min(1, 'Debe cargar las posibles opciones')
		}),
	});

	const sortOptions = [
		{
			value: 'order',
			label: 'Orden'
		},
		{
			value: 'title',
			label: 'Título'
		}
	];

	return (
		<AdminPage
			title="Cuestionario"
			subTitle={subTitle}
			seoTitle="Administración de cuestionarios"
			sortOptions={sortOptions}
			collection={`topics/${params.topicId}/awards/${params.awardId}/sections/${params.sectionId}/questionnaire`}
			schema={schema}
			ItemFormFields={QuestForm}
			ItemCard={QuestCard}
			ItemToolBar={QuestToolbar}
			orderBy="order"
			backTo={{title: "Volver a secciones", url: `/administracion/secciones/${params.topicId}/${params.awardId}`}}
		/>
	);
}

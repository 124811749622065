import * as React from "react";

export default function VoteIcon(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      width={props.width}
      height={props.height}
      strokeWidth={props.strokeWidth}
      style={{ ...props.style, cursor: "pointer" }}
      {...props}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M462 452H335.582l168.824-168.824c10.103-10.103 10.103-26.54 0-36.642L305.462 47.589c-4.894-4.894-11.4-7.589-18.321-7.589s-13.428 2.695-18.321 7.589L7.594 308.814C2.7 313.708.005 320.214.005 327.135c0 6.921 2.695 13.428 7.589 18.321L114.137 452H10c-5.523 0-10 4.477-10 10s4.477 10 10 10h452c5.523 0 10-4.477 10-10s-4.477-10-10-10zm-319.579 0L21.736 331.314c-1.116-1.116-1.731-2.6-1.731-4.179s.615-3.063 1.731-4.179L282.962 61.731c1.116-1.116 2.6-1.731 4.179-1.731s3.063.615 4.179 1.731l198.944 198.944a5.915 5.915 0 010 8.358L307.297 452H142.421z"
          fill="#353535"
          data-original="#000000"
        />
        <path
          d="M177.364 337.302c-2.396-4.977-8.374-7.067-13.348-4.672-4.977 2.396-7.068 8.372-4.672 13.348l20.698 42.988-42.987-20.698c-4.977-2.396-10.953-.303-13.348 4.672-2.396 4.976-.305 10.953 4.671 13.348l68.73 33.093a10 10 0 0013.348-13.348l-33.092-68.731zM250.627 295.004c-13.724-13.723-36.055-13.724-49.781 0-13.724 13.725-13.724 36.056 0 49.781l15.273 15.273c6.862 6.862 15.876 10.293 24.891 10.293 9.014 0 18.028-3.431 24.89-10.293 13.724-13.725 13.724-36.056 0-49.781l-15.273-15.273zm1.131 50.912c-5.926 5.927-15.57 5.926-21.496 0l-15.273-15.273c-5.927-5.926-5.927-15.569 0-21.496a15.152 15.152 0 0110.748-4.445c3.893 0 7.785 1.482 10.748 4.445l15.273 15.273c5.927 5.927 5.927 15.57 0 21.496zM372.213 225.46l-16.253 16.253a2.314 2.314 0 01-3.271 0l-9.678-9.678 10.748-10.748c3.905-3.905 3.905-10.237 0-14.143-3.905-3.905-10.237-3.905-14.143 0l-10.748 10.748-9.678-9.678a2.315 2.315 0 010-3.271l16.254-16.253c3.905-3.905 3.906-10.237 0-14.142-3.906-3.905-10.237-3.905-14.142 0l-16.254 16.254c-8.699 8.7-8.699 22.854 0 31.555l33.499 33.499a22.17 22.17 0 0015.778 6.535 22.168 22.168 0 0015.777-6.535l16.253-16.253c3.905-3.905 3.905-10.237 0-14.143-3.904-3.905-10.236-3.905-14.142 0zM278.593 245.542l9.263-9.263c3.905-3.905 3.905-10.237 0-14.142s-10.237-3.905-14.142 0l-16.332 16.332-.002.002-.002.003-16.332 16.332c-3.905 3.905-3.905 10.237 0 14.142 1.953 1.953 4.512 2.929 7.071 2.929s5.119-.977 7.071-2.929l9.262-9.263 43.841 43.841c1.953 1.953 4.512 2.929 7.071 2.929s5.119-.976 7.071-2.929c3.905-3.905 3.905-10.237 0-14.143l-43.84-43.841zM509.07 454.93A10.076 10.076 0 00502 452c-2.63 0-5.21 1.07-7.07 2.93A10.076 10.076 0 00492 462c0 2.63 1.07 5.21 2.93 7.07 1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.07-2.93S512 464.63 512 462s-1.07-5.21-2.93-7.07z"
          fill="#353535"
          data-original="#000000"
        />
      </g>
    </svg>
  );
}

VoteIcon.defaultProps = {
  height: "4.5rem",
  width: "4.5rem",
  strokeWidth: "2.5rem",
};

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { normalize } from 'styled-normalize'
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
${normalize}
	html {
		box-sizing: border-box;
		scroll-behavior: smooth;
		font-size: 62.5%; /*62.5% Me ayuda a resetear para los REMS */
	}

	*:focus {
		outline: 0 !important;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	body {
		font-family: 'Lato';
		font-style: normal;
		font-size: 1.4rem;
		line-height: 1.7rem;
		background: #ffffff;
		margin: 0;
		overflow-x: hidden;
	}
`;

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyle />
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

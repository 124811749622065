import { useRef, useCallback, useState, useEffect } from "react";

export default function useScroll() {
  const scrollRef = useRef(null);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (scroll) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [scroll]);

  const hideScroll = (hide) => {
    setScroll(hide);
  };

  const scrollToRef = useCallback(
    (afterFunction) => {
      scrollRef &&
        scrollRef.current &&
        window.scrollTo(0, scrollRef.current.offsetTop);

      scrollRef &&
        scrollRef.current &&
        afterFunction &&
        (window.onscroll = function () {
          try {
            if (
              scrollRef.current &&
              window.scrollY === scrollRef.current.offsetTop
            ) {
              afterFunction();
            }
          } catch {
            console.log("ERROR");
          }
        });

      return () => (window.onscroll = null);
    },
    [scrollRef]
  );

  return { scrollRef, scrollToRef, hideScroll };
}

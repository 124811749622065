import React, { useState, useEffect } from "react";
import { Container, DropZone, Legend, Files } from "./styles";
import { useDropzone } from "react-dropzone";
import { SectionTitle } from "App/styles";
import { ErrorMessage, FieldLabel } from "basics/styles";
import ProgressBar from "components/ProgressBar";
import useFiles from "hooks/useStorage";
import UploadIcon from "icons/UploadIcon";

export default function FileUploader({
  value,
  label,
  title,
  required,
  folder,
  id,
  register,
  setValue,
  error,
  accepted,
  mode,
  readOnly,
  multiple,
}) {
  const [files, setFiles] = useState([]);

  const { uploadFile } = useFiles();

  const { getRootProps, getInputProps } = useDropzone({
    accept: accepted,
    disabled: readOnly,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        setFiles((prevFiles) => [
          ...prevFiles,
          { name: file.name, url: "", progress: 0 },
        ]);

        const task = uploadFile(folder, file, file.name);

        const onProgress = (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            0
          );

          setFiles((prevFiles) => {
            return prevFiles.map((attachment) =>
              attachment.name === file.name
                ? { ...attachment, progress: progress }
                : attachment
            );
          });
        };
        const onError = () => {
          console.log("error");
        };

        const onComplete = () => {
          task.snapshot.ref.getDownloadURL().then((url) => {
            setFiles((prevFiles) => {
              return prevFiles.map((file) =>
                file.name === task.snapshot.metadata.name
                  ? { ...file, progress: 100, url: url }
                  : file
              );
            });
          });
        };

        task.on("state_changed", onProgress, onError, onComplete);
      });
    },
  });

  const onRemoveFile = (name) => {
    setFiles(
      files.filter(function (f) {
        return f.name !== name;
      })
    );
  };

  useEffect(() => {
    setFiles(value || []);
  }, [value]);

  useEffect(() => {
    setValue(
      id,
      files.map((file) => {
        return { name: file.name, url: file.url };
      })
    );
  }, [files, setValue, id]);

  useEffect(() => {
    register({ name: id });

    setValue(id, value || []);
  }, [register, setValue, id, value]);

  return (
    (!readOnly || files.length > 0) && (
      <Container>
        {title && <SectionTitle>{title}</SectionTitle>}
        {label && (
          <FieldLabel mode={mode} htmlFor={id} required={required}>
            {label}
          </FieldLabel>
        )}

        {!readOnly && (
          <DropZone {...getRootProps({ className: "dropzone" })}>
            <input
              type="file"
              name={id}
              {...getInputProps()}
              multiple={multiple}
            />
            <UploadIcon />
            <Legend mode={mode}>
              Arrastre y suelte los archivos aquí o{" "}
              <em>haga click para seleccionarlos</em>
            </Legend>
          </DropZone>
        )}
        <Files>
          {files.map((file) => {
            return (
              <ProgressBar
                key={file.name}
                readOnly={readOnly}
                url={file.url}
                percent={file.progress || (readOnly ? 100 : 0)}
                name={file.name}
                mode={mode}
                onRemoveFile={onRemoveFile}
              />
            );
          })}
        </Files>
        {error && <ErrorMessage mode={mode}>{error.message}</ErrorMessage>}
      </Container>
    )
  );
}

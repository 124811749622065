import { useReducer } from 'react';

const ACTIONS = {
	TOPIC_SELECTED: 'TS',
	AWARD_SELECTED: 'AS',
	FORM_COMPLETED: 'FC',
	RESET: 'R'
};

const ACTIONS_REDUCERS = {
	[ACTIONS.TOPIC_SELECTED]: (state, action) => ({
		...state,
		stepNumber: 1,
		pageTitle: "Premios",
		seoPageTitle: "Postulación - Premios",
		topic: action.payload
	}),
	[ACTIONS.AWARD_SELECTED]: (state, action) => ({
		...state,
		stepNumber: 2,
		pageTitle: "Formulario",
		seoPageTitle: "Postulación - Formulario",
		award: action.payload
	}),
	[ACTIONS.FORM_COMPLETED]: (state, action) => ({
		...state,
		stepNumber: 3
	}),
	[ACTIONS.RESET]: (state, action) => ({
		...state,
		stepNumber: 0,
		award: undefined,
		topic: undefined,
		pageTitle: "Temáticas",
		seoPageTitle: "Postulación - Temáticas",
	})
};

const reducer = (state, action) => {
    const actionReducer = ACTIONS_REDUCERS[action.type];
   return actionReducer ? actionReducer(state, action) : state;
};

export default function useEntryFlow() {
    
    const [ { topic, award, stepNumber, pageTitle, seoPageTitle }, dispatch ] = useReducer(reducer, {
		topic: undefined,
		award: undefined,
		pageTitle: "Temáticas",
		seoPageTitle: "Postulación - Temáticas",
        stepNumber: 0
	});

	return {
		topicSelected: (topic) => dispatch({ type: ACTIONS.TOPIC_SELECTED, payload: topic }),
        awardSelected: (award) => dispatch({ type: ACTIONS.AWARD_SELECTED, payload: award }),
        formCompleted: () => dispatch({ type: ACTIONS.FORM_COMPLETED }),
        formReset: () => dispatch({ type: ACTIONS.RESET }),
		topic,
		award,
		pageTitle,
		seoPageTitle,
        stepNumber
	};
}

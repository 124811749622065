import styled from "styled-components";

import { breakpoints } from "definitions";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;

  input {
    width: 100%;
  }
  ${breakpoints.tablet} {
    flex-direction: column;

    div,
    a,
    input {
      width: 100%;
    }

    input {
      margin-bottom: 10px;
    }
  }
`;

export const Legend = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin: 0;
  margin-bottom: 1.9rem;
`;

import styled from "styled-components";
import { colors, breakpoints } from "definitions";

export const EntryForm = styled.form`
  display: contents;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  background: white;
  margin-bottom: 45px;
  width: 100%;
`;

export const Header = styled.div`
  background: ${colors.background.yellow};
  height: 5.2rem;
  display: flex;
  align-items: center;
  color: ${colors.fonts.gray};
  width: 100%;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 2.4rem;
  width: 100%;
  text-align: center;
  color: white;
`;

export const FooterCol = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${breakpoints.tablet} {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
    }
  }
`;

export const LinkSocialNetwork = styled.a`
  text-decoration: none;
  font-weight: 300;
  border: none;
  color: black;
  box-sizing: border-box;
  border-radius: 4px;
  height: 3.8rem;
  padding: 0.8rem;
  width: 100%;
  text-decoration: underline;
`;

export const SocialNetworksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

import styled from "styled-components";
import { breakpoints, colors } from "definitions";

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 8rem;
`;

export const Burger = styled.div`
  width: 2rem;
  height: 2rem;
  right: 20px;
  z-index: 20;
  display: none;
  cursor: pointer;

  ${breakpoints.laptop} {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    position: absolute;
    /*margin-right: 4rem;*/
    padding-right: 60px;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) =>
    open ? `${colors.navigation.lightGray}` : `${colors.navigation.gray}`};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-of-type(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-of-type(2) {
      transform: ${({ open }) => (open ? "translateY(100%)" : "translateY(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-of-type(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

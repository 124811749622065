import React, { useEffect, useCallback } from "react";

import { BodyContainer } from "App/styles";

import { Container, Control } from "./styles";

import Form from "components/Form";
import { Topics } from "components/Topics";
import { Awards } from "components/Awards";
import { FormCreatedSuccessfully } from "components/FormFinalMessage";
import { Breadcrumb } from "components/Breadcrumb";
import { useTransition } from "react-spring";
import { MainTitle } from "components/MainTitle";

import useEntryFlow from "hooks/useFormFlow";
import useEntryForm from "hooks/useEntryForm";

export default function NewEntry() {
  const {
    topic,
    award,
    stepNumber,
    pageTitle,
    seoPageTitle,
    topicSelected,
    awardSelected,
    formCompleted,
    formReset,
  } = useEntryFlow();

  const { loadingForm, setUpNewForm, form } = useEntryForm();

  useEffect(() => {
    setUpNewForm(topic, award);

    return () => {};
  }, [award, setUpNewForm, topic]);

  const transitions = useTransition(stepNumber, {
    from: { position: "absolute", opacity: 0 },
    enter: { position: "relative", opacity: 1 },
    leave: { position: "absolute", opacity: 0 },
  });

  const GetFormComponent = useCallback(() => {
    switch (stepNumber) {
      case 0: {
        return (
          <Container>
            {transitions((style, item) => (
              <Control style={style}>
                <Topics onSelected={topicSelected} />
              </Control>
            ))}
          </Container>
        );
      }
      case 1: {
        return (
          <Container>
            {transitions((style, item) => (
              <Control style={style}>
                <Awards
                  topic={topic}
                  onBack={formReset}
                  onSelected={awardSelected}
                />
              </Control>
            ))}
          </Container>
        );
      }
      case 2: {
        return (
          <Form onCompleted={formCompleted} form={form} loading={loadingForm} />
        );
      }
      case 3: {
        return <FormCreatedSuccessfully />;
      }
      default: {
        return <h1>ERROR</h1>;
      }
    }
  }, [
    awardSelected,
    form,
    formCompleted,
    formReset,
    loadingForm,
    stepNumber,
    topic,
    topicSelected,
    transitions,
  ]);

  return (
    <React.Fragment>
      <BodyContainer>
        <Breadcrumb
          topicCompleted={stepNumber >= 1}
          awardCompleted={stepNumber >= 2}
          formCompleted={stepNumber === 3}
        />

        <MainTitle title={pageTitle} seoTitle={seoPageTitle} />

        {GetFormComponent()}
      </BodyContainer>
    </React.Fragment>
  );
}

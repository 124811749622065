import { useState, useCallback, useContext } from "react";

import { object, array, string, boolean, number, addMethod, lazy } from "yup";
import { wordCounter } from "helpers";
import { yupResolver } from "@hookform/resolvers";

import { firebaseDB, cloudFunctions, getDateTimeNow } from "firebase/client";

import useFirestore from "hooks/useFirestore";
import { CompetitionContext } from "contexts/CompetitionContext";
import { AuthContext } from "contexts/AuthContext";
import { FormStatus, MessageType } from "model/dictionary";

import { pdf } from "@react-pdf/renderer";

import EntryPDF from "components/EntryPDF";
import useFiles from "hooks/useStorage";
import { sortByProperty, baseUrl } from "helpers";

import { AlertContext } from "contexts/AlertContext";

const initialForm = {
  identity: "",
  entryNumber: "",
  topic: { id: "", title: "" },
  award: { id: "", title: "" },
  competition: { id: "", title: "" },
  avatar: "",
  attachments: [],
  firstName: "",
  lastName: "",
  staffPosition: "",
  state: "",
  city: "",
  email: "",
  videoLink: "",
  phone: "",
  projectName: "",
  companyName: "",
  website: "",
  linkedin: "",
  facebook: "",
  instagram: "",
  twitter: "",
  showPostulator: false,
  postulatorEmail: "",
  postulatorFullName: "",
  postulatorPhone: "",
  minifiedHeader: false,
  hasSurvey: false,
  status: [],
  sections: [],
};

export default function useEntryForm() {
  const { currentCompetition } = useContext(CompetitionContext);
  const { currentUserRoles } = useContext(AuthContext);
  const [loadingTopics, setLoadingTopics] = useState(true);
  const [loadingAwards, setLoadingAwards] = useState(true);
  const [loadingForm, setLoadingForm] = useState(true);
  const [savingForm, setSavingForm] = useState(false);

  const [form, setForm] = useState(initialForm);

  const { getItems, getItemByTitle, getItem } = useFirestore();

  const { UploadFileCompleted } = useFiles();

  const { showWaiting, showAlert } = useContext(AlertContext);

  const getTopics = useCallback(async () => {
    return getItems({
      setLoading: setLoadingTopics,
      collection: "topics",
      orderBy: "title",
    }).then((topics) => {
      if (!currentUserRoles.administrator) {
        topics = topics.filter((topic) => {
          const roles =
            topic.roles && topic.roles.filter((role) => role.checked);

          if (!roles || roles.length === 0) {
            return true;
          }

          return (
            roles.filter((role) => currentUserRoles[role.value]).length > 0
          );
        });
      }

      return topics;
    });
  }, [currentUserRoles, getItems]);

  const getAwards = useCallback(
    async (topicId) => {
      return getItems({
        setLoading: setLoadingAwards,
        collection: `topics/${topicId}/awards`,
        orderBy: "title",
      }).then((awards) => {
        if (!currentUserRoles.administrator) {
          awards = awards.filter((award) => {
            const roles =
              award.roles && award.roles.filter((role) => role.checked);

            if (!roles || roles.length === 0) {
              return true;
            }

            return (
              roles.filter((role) => currentUserRoles[role.value]).length > 0
            );
          });
        }

        return awards;
      });
    },
    [currentUserRoles, getItems]
  );

  const getFormIdentity = useCallback(() => {
    const id = firebaseDB.collection("entries").doc().id;

    return id;
  }, []);

  addMethod(string, "wordCounter", function (message) {
    return this.test("wordCounter", message, function (answer) {
      const { path, createError } = this;

      const maxLength = this.parent.wordCounter;

      const words = wordCounter(answer);

      if (words > maxLength) {
        return createError({
          path,
          message: `La repuesta debe tener como máximo ${maxLength} palabras`,
        });
      }

      return true;
    });
  });

  const getFormValidations = () => {
    return yupResolver(
      object({
        avatar: string(),
        videoUrl: string(),
        attachments: array(),
        videolink: string(),
        minifiedHeader: boolean(),
        state: string().when("minifiedHeader", {
          is: false,
          then: string().required("La provincia es obligatoria"),
        }),
        city: string().when("minifiedHeader", {
          is: false,
          then: string().required("La localidad es obligatoria"),
        }),
        firstName: string().required("El apellido es obligatorio"),
        lastName: string().required("El nombre es obligatorio"),
        email: string()
          .email("Ingrese un mail con el formato correcto")
          .required("El mail es obligatorio"),
        confirmEmail: string()
          .required("La confirmación del mail es obligatoria")
          .test(
            "email-match",
            "Los direcciones deben ser iguales",
            function (value) {
              return this.parent.email === value;
            }
          ),
        phone: string(),
        projectName: string().when("minifiedHeader", {
          is: false,
          then: string().required("El nombre del proyecto es obligatorio"),
        }),
        companyName: string().required(
          "El nombre de la compañia es obligatorio"
        ),
        website: lazy((value) =>
          !value
            ? string()
            : string().matches(
                /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
                "url con formato incorrecto"
              )
        ),
        linkedin: lazy((value) =>
          !value
            ? string()
            : string().matches(
                /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
                "url con formato incorrecto"
              )
        ),
        facebook: lazy((value) =>
          !value
            ? string()
            : string().matches(
                /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
                "url con formato incorrecto"
              )
        ),
        instagram: string(),
        twitter: string(),
        showPostulator: boolean(),
        postulatorEmail: string()
          .email("Ingrese un mail con el formato incorrecto")
          .when("showPostulator", {
            is: true,
            then: string().required("El mail es obligatorio"),
          }),
        postulatorFullName: string().when("showPostulator", {
          is: true,
          then: string().required("El nombre es obligatorio"),
        }),
        postulatorPhone: string(),
        sections: array().of(
          object().shape({
            id: string(),
            title: string(),
            order: number(),
            questions: array().of(
              object().shape({
                id: string(),
                title: string(),
                helpText: string(),
                wordCounter: number(),
                questionType: string(),
                options: array(
                  object({
                    value: string(),
                    label: string(),
                  })
                ),
                value: string()
                  .when("questionType", {
                    is: "text",
                    then: string()
                      .required("la repuesta es obligatoria")
                      .wordCounter(),
                  })
                  .when("questionType", {
                    is: "ponderation",
                    then: string().required("la repuesta es obligatoria"),
                  })
                  .when("questionType", {
                    is: "comments",
                    then: string(),
                  }),
              })
            ),
          })
        ),
      })
    );
  };

  const schema = {
    avatar: string(),
    videoUrl: string(),
    attachments: array(),
    videolink: string(),
    minifiedHeader: boolean(),
    state: string().when("minifiedHeader", {
      is: false,
      then: string().required("La provincia es obligatoria"),
    }),
    city: string().when("minifiedHeader", {
      is: false,
      then: string().required("La localidad es obligatoria"),
    }),
    firstName: string().required("El apellido es obligatorio"),
    lastName: string().required("El nombre es obligatorio"),
    email: string()
      .email("Ingrese un mail con el formato correcto")
      .required("El mail es obligatorio"),
    confirmEmail: string()
      .required("La confirmación del mail es obligatoria")
      .test(
        "email-match",
        "Los direcciones deben ser iguales",
        function (value) {
          return this.parent.email === value;
        }
      ),
    phone: string(),
    projectName: string().when("minifiedHeader", {
      is: false,
      then: string().required("El nombre del proyecto es obligatorio"),
    }),
    companyName: string().required("El nombre de la compañia es obligatorio"),
    website: lazy((value) =>
      !value
        ? string()
        : string().matches(
            /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
            "url con formato incorrecto"
          )
    ),
    linkedin: lazy((value) =>
      !value
        ? string()
        : string().matches(
            /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
            "url con formato incorrecto"
          )
    ),
    facebook: lazy((value) =>
      !value
        ? string()
        : string().matches(
            /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
            "url con formato incorrecto"
          )
    ),
    instagram: string(),
    twitter: string(),
    showPostulator: boolean(),
    postulatorEmail: string()
      .email("Ingrese un mail con el formato incorrecto")
      .when("showPostulator", {
        is: true,
        then: string().required("El mail es obligatorio"),
      }),
    postulatorFullName: string().when("showPostulator", {
      is: true,
      then: string().required("El nombre es obligatorio"),
    }),
    postulatorPhone: string(),
    sections: array().of(
      object().shape({
        id: string(),
        title: string(),
        order: number(),
        questions: array().of(
          object().shape({
            id: string(),
            title: string(),
            helpText: string(),
            wordCounter: number(),
            questionType: string(),
            options: array(
              object({
                value: string(),
                label: string(),
              })
            ),
            value: string()
              .when("questionType", {
                is: "text",
                then: string()
                  .required("la repuesta es obligatoria")
                  .wordCounter(),
              })
              .when("questionType", {
                is: "ponderation",
                then: string().required("la repuesta es obligatoria"),
              })
              .when("questionType", {
                is: "comments",
                then: string(),
              }),
          })
        ),
      })
    ),
  };

  const getQuestions = (section) => {
    let promise = new Promise((resolve, reject) => {
      section.ref
        .collection("questionnaire")
        .orderBy("order")
        .get()
        .then(({ docs }) => {
          resolve({
            id: section.id,
            title: section.data().title,
            order: section.data().order,
            questions: docs.map((doc) => {
              return { ...doc.data(), id: doc.id };
            }),
          });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });

    return promise;
  };

  const getSections = useCallback(async (topicId, awardId) => {
    return firebaseDB
      .collection(`topics/${topicId}/awards/${awardId}/sections`)
      .orderBy("order")
      .get()
      .then(async ({ docs }) => {
        let promises = [];

        docs.forEach((section) => {
          promises.push(getQuestions(section));
        });

        return await Promise.all(promises);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  }, []);

  const setUpNewForm = useCallback(
    async (topic, award) => {
      if (!topic || !award) {
        return;
      }

      setLoadingForm(true);

      const sections = await getSections(topic.id, award.id);

      const minifiedHeader =
        award.title.toLowerCase().includes("del año") &&
        !award.title.toLowerCase().includes("startup");

      const newForm = {
        ...initialForm,
        topic: topic,
        award: award,
        identity: getFormIdentity(),
        sections: sections,
        hasSurvey: award.hasSurvey || false,
        competition: {
          id: currentCompetition.id,
          title: currentCompetition.title,
        },
        minifiedHeader,
      };

      setForm(newForm);

      return newForm;
    },
    [currentCompetition, getFormIdentity, getSections]
  );

  const setUpFormStatus = (currentStatus, nextStatus) => {
    let newStatus = currentStatus;

    newStatus = newStatus.map((status) => ({ ...status, current: false }));

    newStatus = [
      ...newStatus,
      { type: nextStatus, current: true, createdAt: getDateTimeNow() },
    ];

    return [newStatus, newStatus.filter((status) => status.current)[0]];
  };

  const BuildForm = (data, form, nextStatus) => {
    const [newStatus, currentStatus] = setUpFormStatus(form.status, nextStatus);

    const ran = new Date().valueOf().toString();

    const entryNumber = `PS-${form.competition.title}-${ran.substr(
      ran.length - 6
    )}`;

    let formUpdated = data;

    if (
      currentStatus.type === FormStatus.DRAFT ||
      currentStatus.type === FormStatus.PREVIEW
    ) {
      formUpdated = {
        ...formUpdated,
        competition: form.competition,
        award: form.award,
        topic: form.topic,
        actived: true,
        entryNumber: entryNumber,
        currentStatus: currentStatus,
        createdAt: getDateTimeNow(),
        hasSurvey: false,
      };
    }

    formUpdated = {
      ...formUpdated,
      modifiedAt: getDateTimeNow(),
      status: newStatus,
    };

    return { formUpdated, currentStatus };
  };

  const sendEmails = async (form, currentStatus) => {
    if (currentStatus.type !== FormStatus.DRAFT) {
      return;
    }

    const entryUrl = `${baseUrl()}/editar-postulacion/${form.identity}`;

    const fields = {
      email: form.email,
      pdf: form.pdf,
      entryNumber: form.entryNumber,
      name: `${form.lastName}, ${form.firstName}`,
      phone: form.phone,
      company: form.companyName,
      topic: form.topic.title,
      award: form.award.title,
      date: new Intl.DateTimeFormat("es-Ar", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date()),
      url: entryUrl,
      year: currentCompetition.title,
    };

    try {
      if (!form.showPostulator) {
        var entryCreated = cloudFunctions.httpsCallable(
          "sendEntryCreatedEmail"
        );

        return await entryCreated(fields);
      } else {
        var entryCreatedByThird = cloudFunctions.httpsCallable(
          "sendEntryCreatedByThirdEmail"
        );

        await entryCreatedByThird({
          ...fields,
          third: form.postulatorFullName,
        });

        var entryCreatedToThird = cloudFunctions.httpsCallable(
          "sendEntryCreatedToThirdEmail"
        );

        return await entryCreatedToThird({
          email: form.postulatorEmail,
          name: `${form.lastName}, ${form.firstName}`,
          year: currentCompetition.title,
        });
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const sendConfirmEmail = useCallback(
    async (form) => {
      showWaiting("Reenviando mail de confirmación");

      const entryUrl = `${baseUrl()}/editar-postulacion/${form.identity}`;

      const fields = {
        email: form.email,
        pdf: form.pdf,
        entryNumber: form.entryNumber,
        name: `${form.lastName}, ${form.firstName}`,
        phone: form.phone,
        company: form.companyName,
        topic: form.topic.title,
        award: form.award.title,
        date: new Intl.DateTimeFormat("es-Ar", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(new Date()),
        url: entryUrl,
        year: currentCompetition.title,
      };

      if (!form.showPostulator) {
        var entryCreated = cloudFunctions.httpsCallable(
          "sendEntryCreatedEmail"
        );

        await entryCreated(fields);
      } else {
        var entryCreatedByThird = cloudFunctions.httpsCallable(
          "sendEntryCreatedByThirdEmail"
        );

        await entryCreatedByThird({
          ...fields,
          third: form.postulatorFullName,
        });

        var entryCreatedToThird = cloudFunctions.httpsCallable(
          "sendEntryCreatedToThirdEmail"
        );

        await entryCreatedToThird({
          email: form.postulatorEmail,
          name: `${form.lastName}, ${form.firstName}`,
          year: currentCompetition.title,
        });
      }

      showAlert(MessageType.SUCESSFULL, "Mail enviado", 2000);
    },
    [currentCompetition, showAlert, showWaiting]
  );

  const sendEmailToCEO = async (form) => {
    const entryUrl = `${baseUrl()}/editar-postulacion/${form.identity}`;

    const fields = {
      email: form.email,
      name: `${form.lastName}, ${form.firstName}`,
      url: entryUrl,
      year: currentCompetition.title,
      award: form.award.title,
      reason: form.reason,
    };

    var mailToCEO = cloudFunctions.httpsCallable("sendEmailToCEO");

    await mailToCEO(fields);
  };

  async function waitFormSaving(data, form, nextStatus) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const { formUpdated, currentStatus } = BuildForm(
            data,
            form,
            nextStatus
          );

          const blob = await pdf(EntryPDF({ entry: formUpdated })).toBlob();

          const fileName = `${formUpdated.entryNumber}.pdf`;

          const pdfUrl = await UploadFileCompleted(`entries`, blob, fileName);

          formUpdated.pdf = pdfUrl;

          await firebaseDB
            .collection("entries")
            .doc(formUpdated.identity)
            .set(formUpdated);

          await sendEmails(formUpdated, currentStatus);

          resolve(formUpdated);
        } catch (error) {
          console.log(error);
          reject(error);
        } finally {
          setSavingForm(false);
        }
      }, 3000);
    });
  }

  async function waitFormUpdating(form, nextStatus) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const [newStatus, currentStatus] = setUpFormStatus(
            form.status,
            nextStatus
          );

          const formUpdated = {
            ...form,
            modifiedAt: getDateTimeNow(),
            status: newStatus,
            currentStatus: currentStatus,
          };

          await firebaseDB
            .collection("entries")
            .doc(formUpdated.identity)
            .set(formUpdated);

          resolve(formUpdated);
        } catch (error) {
          reject(error);
        } finally {
          setSavingForm(false);
        }
      }, 3000);
    });
  }

  const SaveForm = async (data, form, nextStatus) => {
    setSavingForm(true);

    return await waitFormSaving(data, form, nextStatus);
  };

  const UpdateForm = async (form, nextStatus) => {
    setSavingForm(true);

    await waitFormUpdating(form, nextStatus);
  };

  const createCEOForm = async (
    firstName,
    lastName,
    company,
    email,
    reason,
    awardId
  ) => {
    let fields = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      actived: true,
      reason: reason,
      year: currentCompetition.title,
    };

    const topicItem = await getItemByTitle("topics", "INDUSTRIA");

    const awardItem = await getItem(`topics/${topicItem.id}/awards`, awardId);

    await firebaseDB
      .collection("ceos")
      .doc()
      .set({ ...fields, title: firstName, company: company, award: awardId });

    let ceoForm = await setUpNewForm(
      {
        id: topicItem.id,
        title: topicItem.title,
        hasSurvey: false,
      },
      { id: awardItem.item.id, title: awardItem.item.title }
    );

    const [newStatus, currentStatus] = setUpFormStatus(
      ceoForm.status,
      FormStatus.PREVIEW
    );

    const ran = new Date().valueOf().toString();

    const entryNumber = `PS-${ceoForm.competition.title}-${ran.substr(
      ran.length - 6
    )}`;

    const formUpdated = {
      ...ceoForm,
      ...fields,
      award: { id: awardItem.item.id, title: awardItem.item.title },
      companyName: company,
      minifiedHeader: true,
      actived: true,
      entryNumber: entryNumber,
      currentStatus: currentStatus,
      createdAt: getDateTimeNow(),
      modifiedAt: getDateTimeNow(),
      status: newStatus,
    };

    await firebaseDB
      .collection("entries")
      .doc(formUpdated.identity)
      .set(formUpdated);

    await sendEmailToCEO(formUpdated);
  };

  const getEntryForm = useCallback(
    (entryId) => {
      setLoadingForm(true);

      return getItem("entries", entryId)
        .then((result) => {
          let f = initialForm;

          if (result.exists) {
            f = result.item;
          }

          const status = f.status.filter((st) => st.current);

          return {
            form: f,
            exists: result.exists,
            currentStatus: status.length === 0 ? undefined : status[0].type,
          };
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoadingForm(false);
        });
    },
    [getItem]
  );

  const getCities = useCallback(async (stateId, terms) => {
    let response = await fetch(
      `https://apis.datos.gob.ar/georef/api/localidades?provincia=${stateId}&nombre=*${terms}*&campos=id, nombre`
    );

    let json = await response.json();

    return json.localidades.sort(sortByProperty("id")).map((resp) => {
      return { value: resp.id, label: resp.nombre };
    });
  }, []);

  return {
    loadingTopics,
    loadingAwards,
    loadingForm,
    getTopics,
    getAwards,
    setUpNewForm,
    getFormValidations,
    schema,
    form,
    SaveForm,
    UpdateForm,
    savingForm,
    getEntryForm,
    initialForm,
    getCities,
    setSavingForm,
    createCEOForm,
    sendConfirmEmail,
  };
}

import React from "react";

import { FieldSeparator } from "App/styles";

import {
  Container,
  MainSection,
  CardMainText,
  CardSecondaryText,
  Details,
} from "basics/cards";
import ItemCardToolBar from "components/ItemCardToolBar";
import UserIcon from "icons/entities/UserIcon";

export default function UserCard({
  item,
  selected,
  onSelectEditedItem,
  onSelectDeletedItem,
  onCleanItemId,
}) {
  return (
    <Container selected={selected}>
      <MainSection>
        <UserIcon />
        <Details>
          <CardMainText>{item.title}</CardMainText>
          <FieldSeparator />
          <CardSecondaryText weight="bold">{item.email}</CardSecondaryText>
          <FieldSeparator />
          <CardSecondaryText weight="normal">
            {item.roles
              .filter((role) => role.checked)
              .map((role) => role.label)
              .join(", ")}
          </CardSecondaryText>
        </Details>
      </MainSection>

      <ItemCardToolBar
        id={item.id}
        actived={item.actived}
        selected={selected}
        onCleanItemId={onCleanItemId}
        onSelectEditedItem={onSelectEditedItem}
        onSelectDeletedItem={onSelectDeletedItem}
      />
    </Container>
  );
}

import React from "react";
import { HeaderContainer, HeaderContentContainer, White } from "./styles";
import NavBar from "components/NavBar";
import { Link } from "wouter";
import MainLogo from "components/MainLogo";

export default function Header() {
  return (
    <HeaderContainer>
      <HeaderContentContainer>
        <White />
        <Link to="/">
          <MainLogo position="header" />
        </Link>
        <NavBar />
      </HeaderContentContainer>
    </HeaderContainer>
  );
}

export function slugify(string) {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\\-]+/g, "") // Remove all non-word characters
    .replace(/\\-\\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function isWord(str) {
  var alphaNumericFound = false;
  for (var i = 0; i < str.length; i++) {
    var code = str.charCodeAt(i);
    if (
      (code > 47 && code < 58) || // numeric (0-9)
      (code > 64 && code < 91) || // upper alpha (A-Z)
      (code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      alphaNumericFound = true;
      return alphaNumericFound;
    }
  }
  return alphaNumericFound;
}

export function wordCounter(text) {
  var words = text.split(" ");

  var wordCount = 0;

  for (var i = 0; i < words.length; i++) {
    if (words[i] !== " " && isWord(words[i])) {
      wordCount++;
    }
  }

  return wordCount;
}

export function ToReadableDate(timespan) {
  return new Intl.DateTimeFormat("es-Ar").format(timespan.toDate());
}

export function ToInputDate(timespan) {
  return new Date(timespan.seconds * 1000).toISOString().substring(0, 10);
}

export const capitalize = (str, lower = true) => {
  if (!str) {
    return "";
  }

  return (lower ? str.toLowerCase() : str).replace(
    /(?:^|\s|["'([{])+\S/g,
    (match) => match.toUpperCase()
  );
};

export const sortByProperty = (property) => {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
};

export const sortDescByProperty = (property) => {
  return function (a, b) {
    if (a[property] > b[property]) return -1;
    else if (a[property] < b[property]) return 1;

    return 0;
  };
};

export const getLabel = (options, value) => {
  return options.filter((sp) => {
    return sp.value === value;
  })[0].label;
};

export const addOpacityToColor = (color, opacity) => {
  const opacityHex = Math.round(opacity * 255).toString(16);
  return `${color}${opacityHex}`;
};

export const baseUrl = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getAnswer = (question) => {
  if (question.questionType === "ponderation") {
    return getLabel(question.options, question.value);
  }

  return question.value;
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  const array = Array.from(map, ([name, value]) => ({ name, value }));

  return array;
}

export function splitArray(array, size) {
  const result = [];

  for (let index = 0; index < array.length; index += 1) {
    let lastArray;

    if (result.length > 0) {
      lastArray = result[result.length - 1].items;
    }

    if (!lastArray || lastArray.length === size) {
      result.push({ id: uuidv4(), items: [array[index]] });
    } else {
      lastArray.push(array[index]);
    }
  }

  return result;
}

export function isLightColor(color) {
  const hex = color.replace("#", "");
  const cr = parseInt(hex.substr(0, 2), 16);
  const cg = parseInt(hex.substr(2, 2), 16);
  const cb = parseInt(hex.substr(4, 2), 16);
  const brightness = (cr * 299 + cg * 587 + cb * 114) / 1000;

  return brightness > 155;
}

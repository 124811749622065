import * as React from "react"

export default function Chevron(props) {
  return (
    <svg height={props.height} viewBox="0 0 21 21" width={props.width} {...props}
      style={{ ...props.style, cursor: 'pointer' }}>
      <path
        d="M14.5 8.5l-4 4-4-4"
        fill="#006EDF"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}



import React from 'react';
import styled from 'styled-components';
import { breakpoints } from "definitions";

const Row = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 350px;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  
  ${breakpoints.tabletXL} {
    flex-direction: column;
    height: auto;
    max-width: none;
  }
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
  margin-right: ${({ reverse }) => (reverse ? '0' : '20px')};
  margin-left: ${({ reverse }) => (reverse ? '20px' : '0')};
  padding-left: ${({ reverse }) => (reverse ? '20px' : '0')};
  padding-right: ${({ reverse }) => (reverse ? '0' : '20px')};
  width: 50%;

  ${breakpoints.tabletXL} {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const ContentWrapper = styled.div`
  width: 50%;
  flex-wrap: wrap;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  ${breakpoints.tabletXL} {
   width: 100%;
  }
  `;


const Tag = styled.div`
  color: #006EDF;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  width: fit-content;
  background: rgba(0, 110, 223, 0.10);
  `;

const Title = styled.h3`
  color: #000;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  `;

const Text = styled.p`
  color: #757575;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  `;

const Button = styled.a`
  padding: 8px 16px;
  background-color: #006EDF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
`;

const RowItem = ({ imageSrc, tag, title, text, button, reverse }) => {
  return (
    <Row reverse={reverse}>
      <ImageWrapper reverse={reverse}>
        <Image src={imageSrc} alt={title} />
      </ImageWrapper>
      <ContentWrapper>
        <Tag>{tag}</Tag>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Button href={button.link} target="_blank">{button.text}</Button>
      </ContentWrapper>
    </Row>
  );
};

const Section = styled.section`
  max-width: 124.5rem;
  width: 100%;

   ${breakpoints.laptop} {
      padding: 0 20px;
    }
  
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${breakpoints.tabletXL} {
   width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const ActionCards = () => {
  const items = [
    {
      imageSrc: '/assets/tickets_background.png',
      tag: 'Eventbrite',
      title: 'Conseguí tus entradas',
      text: '¡Asegura tu lugar en el evento que todos están esperando! Adquiere tus entradas a través de Eventbrite y prepárate para vivir una experiencia única e inolvidable.',
      button: {
        text: 'IR A EVENTBRITE',
        link: 'https://www.eventbrite.com.ar/e/983902848907?aff=oddtdtcreator'
      }
    },
    {
      imageSrc: '/assets/sponsor_background.png',
      tag: 'Sponsors',
      title: '¿Queres ser Sponsor?',
      text: '¡Sé patrocinador y destaca tu marca en un evento de gran impacto! Contáctanos para conocer las oportunidades y hacer que tu empresa brille.',
      button: {
        text: 'SUMARSE COMO SPONSOR',
        link: 'mailto:comercial@cessi.org.ar?subject=Consulta%20para%20ser%20Sponsor&body=Hola%2C%20quisiera%20obtener%20informaci%C3%B3n%20relacionada%20a%20ser%20sponsor%20del%20evento.'
      }
    },

  ];

  return (
    <Section>
      <List>
        {items.map((item, index) => (
          <RowItem
            key={index}
            imageSrc={item.imageSrc}
            tag={item.tag}
            title={item.title}
            text={item.text}
            button={item.button}
            reverse={index % 2 !== 0}
          />
        ))}
      </List>
    </Section>
  );
};

export default ActionCards;
import styled from 'styled-components';
import {breakpoints} from 'definitions';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
    padding-bottom: 25px;
    
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
    justify-content: space-between;

    ${breakpoints.phablet}
    {
        flex-direction: Column;
    }
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
    ${breakpoints.phablet}
    {
        justify-content: space-between;
    }
`;
export const colors = {
  icons: /*bordes, iconos, buttons*/ {
    darkBlue: `#08255A` /*second colors*/,
    white: `#FFFFFF` /*fourth colors*/,
    strongBlue: `#0046C6` /*third */,
    blue: `#1755C6`,
    yellowGreen: `#006EDF`,
    yellow: `#006EDF`,
    lightGray: `#24BCDC` /*new */,
    gray: `#006EDF`,
    darkGray: `#353535`,
  },
  borders: {
    lightGray: `#24BCDC`,
  },
  fonts: /*h`s*/ {
    darkBlue: `#08255A` /*second colors*/,
    white: `#FFFFFF` /*fourth colors*/,
    veryDarkGray: `#333333` /*fifth colors */,
    cyan: `#00C2FF` /*new */,
    red: `#D8000C`,
    blue: `#1755C6`,
    yellowGreen: `#006EDF`,
    yellow: `#006EDF`,
    lightGray: `#24BCDC` /*new */,
    gray: `#006EDF`,
    darkGray: `#353535`,
  },

  background: {
    white: `#FFFFFF` /*form , header, fourth colors*/,
    veryLightGray: `#E5E5E5` /*body */,
    veryDarkGray: `#353535` /*footer */,
    darkBlue: `#0000F2` /*hero */,
    strongBlue: `#0046C6` /*third */,
    lightBlue: `#53678C` /*new */,
    yellowGreen: `#006EDF`,
    red: `#cf2e2e`,
    green: `#339900`,
    yellow: `#006EDF`,
    lightGray: `#24BCDC` /*new */,
    darkGray: `#353535`,
    gray: `#006EDF`,
  },

  hover: {
    softYellow: `#D8EC6F` /*first hover */,
    darkBlue: `#1F4996` /*second hover */,
    red: "#902020",
    yellow: `#006EDF`,
    darkGray: `#333333`,
    gray: `#006EDF`,
    white: `#ffffff`,
    lightGray: `#24BCDC` /*new */,
  },

  disabled: /*se utiliza en calendar, en el h4 calendar,*/ {
    first: `#DDDDDD`,
    second: `#DDDDDD`,
  },

  navigation: {
    darkBlue: `#08255A` /*normal navigation */,
    selected: `rgba(8, 37, 90, 0.5)` /*no se usa */,
    brightBlue: `#367AF6` /*hover */,
    veryLightGray: `#DDDDDD` /*disabled */,
    lightGray: `#c4c4c4` /*new */,
    gray: `#006EDF`,
  },
};

export const icons = {
  cards: {
    height: `28`,
    width: `28`,
    color: `#006EDF`,
  },
  entities: {
    height: `3rem`,
    width: `3rem`,
    color: `#006EDF`,
  },
};

export const devices = {
  mobile: "mobile",
  phablet: "phablet",
  tablet: "tablet",
  tabletXL: "tabletXL",
  desktop: "desktop",
  laptop: "laptop",
  wide: "wide",
};

const unit = "px";

export const widths = {
  mobile: 320,
  phablet: 520,
  tablet: 768,
  tabletXL: 896,
  desktop: 992,
  laptop: 1024,
  wide: 1312,
};

export const breakpoints = {
  mobile: `@media screen and (max-width: ${widths.mobile}${unit})`,
  phablet: `@media screen and (max-width: ${widths.phablet}${unit})`,
  tablet: `@media screen and (max-width: ${widths.tablet}${unit})`,
  tabletXL: `@media screen and (max-width: ${widths.tabletXL}${unit})`,
  desktop: `@media screen and (max-width: ${widths.desktop}${unit})`,
  laptop: `@media screen and (max-width: ${widths.laptop}${unit})`,
  wide: `@media screen and (max-width: ${widths.wide}${unit})`,
};

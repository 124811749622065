import styled from "styled-components";
import { colors } from "definitions";

export const Container = styled.div`
    display: flex;
    flex-direction: Column;
    width: 100%;
}
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.fonts.gray};
`;

import React from 'react';

import { InputField } from 'basics';

import { FilterContainer } from 'components/ToolBar/styles';

export default function CriteriaToolbar({ updateFiltering }) {

	const handleTextFilter = (field, value) => {
		updateFiltering(field, '>=', value.length >= 3 ? value : undefined);
	};

	return (
		<FilterContainer>
			<InputField
				label="Filtrar por nombre de criterio"
				type="text"
				id="title"
				placeholder="ingrese por lo menos 3 letras"
				onChange={handleTextFilter}
			/>
		</FilterContainer>
	);
}

import React from "react";
import { Container } from "./styles";

import FacebookIcon from "icons/FacebookIcon";
import InstagramIcon from "icons/InstagramIcon";

import TwitterIcon from "icons/TwitterIcon";
import YoutubeIcon from "icons/YoutubeIcon";

export default function SocialNetworks({ location }) {

  const logoFill = location === "footer" ? "white" : "#006EDF";
  return (
    <Container>
      <a
        href="https://www.facebook.com/cessiargentina/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon fill={logoFill} />
      </a>

      <a
        href="https://www.instagram.com/cessiargentina/?hl=es"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon fill={logoFill} />
      </a>

      <a
        href="https://twitter.com/CessiArgenTIna"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon fill={logoFill} />
      </a>

      <a
        href="https://www.youtube.com/channel/UCHMcJtd3Wei4PllOMCI2VNg"
        target="_blank"
        rel="noopener noreferrer"
      >
        <YoutubeIcon fill={logoFill} />
      </a>
    </Container>
  );
}

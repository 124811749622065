import styled from "styled-components";
import { colors, breakpoints } from "definitions";
import { animated } from "react-spring";

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background.white};
`;

export const AnimatedContainer = styled(animated.div)`
  display: flex;
`;

export const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${colors.background.white};
  padding-bottom: 100px;
  width: 100%;
`;

export const HomeBodyContainer = styled(BodyContainer)`
  gap: 8rem;
`;


export const ModalBodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${colors.background.white};
  width: 100%;
  position: relative;
`;

export const ModalButtonClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px;
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${colors.fonts.darkGray};
`;

export const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  max-width: 124.5rem;
  width: 100%;
  padding: 4rem 0;
  gap: 1rem;
  ${breakpoints.phablet} {
    margin-top: 32px;
  }
`;

export const ContainerItemDeleteForm = styled.div`
  background-color: ${colors.fonts.red};
  height: auto;

  width: 100%;
  max-width: 124.5rem;

  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  border-top: solid 1px ${colors.background.lightGray};

  transition: all 400ms ease;
  transform: ${(props) =>
    !props.selected ? "translateY(-5px)" : "translateY(0px)"};

  padding: 25px;

  display: flex;
  flex-direction: column;
`;

export const ComponentTitle = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: ${colors.fonts.gray};
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  width: 100%;
  justify-content: center;
  ${breakpoints.phablet} {
    font-size: 34px;
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  border-radius: 6px;
  background: ${colors.background.lightGray};
`;

export const ContainerEntryItem = styled(ContainerItem)`
  width: 270px;
  height: 360px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.icons.yellow};
  left: 0px;
  color: white;
  width: 50px;
  border-radius: 6px 0 0 6px;
  flex-basis: 10%;
  padding: 0 0.5rem;
`;

export const TopicText = styled.span`
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  align-self: center;
  font-size: 18px;
`;

export const AwardText = styled.span`
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  align-self: center;
  font-size: 18px;
`;

export const Content = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
  }
`;

export const OverHeroContainer = styled.div`
  margin: 0 auto;
  margin: -4.6rem 0 0 0;
  max-width: 124.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ComponentItemDescription = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  width: auto;
  height: 150px;
  overflow-x: auto;
  text-align: center;
`;

export const ComponentItemTitle = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`;

export const SectionTitle = styled.h3`
  font-family: Lato;
  font-style: normal;
  font-weight: ${({ weight }) => (weight === 500 ? "500" : "600")};
  font-size: 18px;
  line-height: 22px;
  align-items: flex-start;
  color: ${colors.fonts.veryDarkGray};

  display: ${({ withHelp }) => (withHelp ? "flex" : "block")};
  align-items: center;

  span:after {
    content: "${({ required }) => (required ? " *" : "")}";
    color: ${colors.background.red};
  }
`;

export const HeaderSectionContainer = styled(OverHeroContainer)`
  background-color: ${colors.background.white};
  align-items: flex-end;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  flex-direction: row;
  padding: 25px;
  height: 125px;
  width: 95%;
  justify-content: space-between;

  .field-input-group {
    flex-basis: 20%;
  }

  button {
    margin-top: 25px;
  }

  ${breakpoints.laptop} {
    flex-wrap: wrap;
    height: auto;
    align-items: center;

    .field-input-group {
      flex-basis: 30%;
    }

    button {
      width: 100%;
    }
  }

  ${breakpoints.tabletXL} {
    .field-input-group {
      flex-basis: 45%;
    }
  }

  ${breakpoints.tablet} {
    width: 80%;
  }

  ${breakpoints.phablet} {
    .field-input-group {
      flex-basis: 100%;
    }
  }
`;

export const HeaderSectionEntriesContainer = styled(HeaderSectionContainer)`
  .field-input-group {
    flex-basis: 100% !important;
  }
`;

export const HorizontalLine = styled.div`
  height: 0.1rem;

  width: 100%;
  background: ${colors.background.lightGray};
  margin: 10px 0;
  padding: 0;
`;

export const FieldSeparator = styled.div`
  background: ${colors.background.lightGray};

  height: 30%;
  margin: 1rem;

  width: 0.1rem;

  ${breakpoints.tabletXL} {
    display: none;
  }
`;

export const VerticalLine = styled.div`
  background: ${colors.background.lightGray};

  height: 80%;
  margin: 0;

  width: 0.1rem;
`;

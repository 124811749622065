import {useCallback} from 'react';

import { firebaseStorage } from 'firebase/client';

export default function useStorage() {

	const getImages = useCallback((route) => {
		let promise = new Promise((resolve, reject) => {
			firebaseStorage
				.ref()
				.child(route)
				.listAll()
				.then(({ items }) => {

                    const promises = items.map(async item => {
                        const url = await item
                            .getDownloadURL();
                        return { name: item.name, url };
                    })
    
                    Promise.all(promises).then(files => resolve(files));
				})
				.catch(function(error) {
					reject(error);
				});
		});

		return promise;
	}, []);

	const uploadFile = (folder, file, fileName) => {
		const ref = firebaseStorage.ref(`${folder}/${fileName}`)
		const task = ref.put(file)

		
		return task
	  }

	const UploadFileCompleted = (folder, file, fileName) => {

		let promise = new Promise((resolve, reject) => {
		
			const task = uploadFile(folder, file, fileName);

			const onProgress = () => {}
				
			const onError = () => {};

			const onComplete = async () => {
					
				resolve(await task.snapshot.ref.getDownloadURL());
			};

			task.on('state_changed', onProgress, onError, onComplete);

		});

		return promise;
	  }

    return {getImages, uploadFile, UploadFileCompleted};
}

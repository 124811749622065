import styled from "styled-components";
import { colors } from "definitions";

export const LoginContainer = styled.div`
  z-index: 20;
  color: ${colors.icons.gray};
  position: initial;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

import React from "react";
import { BodyContainer, HeaderSectionContainer } from "App/styles";
import ListOfEntries from "components/ListOfEntries";
import { MainTitle } from "components/MainTitle";
import { SelectField } from "basics";
import { MoreButton, CleanButton, YellowButton } from "basics/buttons";
import useHistoricalEntries from "hooks/useHistoricalEntries";
import { FormStatus } from "model/options";

export default function Historical() {
  const {
    exportEntries,
    emptyMessage,
    award,
    competition,
    status,
    entries,
    awards,
    handleFilter,
    setMoreItems,
    moreItemsAvailable,
    competitions,
    resetFilter,
  } = useHistoricalEntries();

  return (
    <BodyContainer>
      <MainTitle title="Histórico" seoTitle="Histórico" />

      <HeaderSectionContainer>
        <SelectField
          id="award.id"
          value={award}
          options={awards}
          onChange={handleFilter}
          label="Premios"
          defaultOption="Todos los premios"
        />
        <SelectField
          id="currentStatus.type"
          value={status}
          options={FormStatus}
          onChange={handleFilter}
          label="Estados"
          defaultOption="Todos los estados"
        />
        <SelectField
          id="competition.id"
          value={competition}
          options={competitions}
          onChange={handleFilter}
          label="Competición"
          defaultOption="Todos los años"
        />

        <CleanButton onClick={() => resetFilter()}>Limpiar</CleanButton>
        {award && (
          <YellowButton onClick={() => exportEntries()}>Exportar</YellowButton>
        )}
      </HeaderSectionContainer>

      <ListOfEntries
        entries={entries}
        mode="historical"
        emptyMessage={emptyMessage}
      />

      {moreItemsAvailable && (
        <MoreButton
          onClick={() => {
            setMoreItems(true);
          }}
        >
          Ver más
        </MoreButton>
      )}
    </BodyContainer>
  );
}

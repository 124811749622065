import { useState, useEffect, useCallback } from "react";
import { firebaseDB } from "firebase/client";

export default function useCrudFlow(table, sortBy) {
  const [loading, setLoading] = useState(false);

  const [waiting, setWaiting] = useState(false);

  const [counter, setCounter] = useState(0);

  const [items, setItems] = useState([]);

  const [onlyActived, setOnlyActived] = useState(true);

  const [errors, setErrors] = useState([]);

  const [filteringQueries, setFilteringQueries] = useState([]);

  const [sortingQuery, setSortingQuery] = useState({
    field: sortBy,
    direction: "asc",
  });

  const reset = () => {
    setSortingQuery({ field: sortBy, direction: "asc" });
    setFilteringQueries([]);
  };

  const deleteItem = (id) => {
    setWaiting(true);

    let promise = new Promise((resolve, reject) => {
      firebaseDB
        .collection(table)
        .doc(id)
        .delete()
        .then(function () {
          resolve(true);
        })
        .catch(function (error) {
          setErrors(error);
          reject(error);
        })
        .finally(() => {
          setWaiting(false);
        });
    });

    return promise;
  };

  const switchActivationItem = (id, actived) => {
    return updateItem(id, { actived });
  };

  const updateItem = (id, fields) => {
    let promise = new Promise((resolve, reject) => {
      firebaseDB
        .collection(table)
        .doc(id)
        .set(fields, { merge: true })
        .then(function () {
          resolve(true);
        })
        .catch(function (error) {
          setErrors(error);
          reject(error);
          console.error(error);
        })
        .finally(() => {
          setWaiting(false);
        });
    });

    return promise;
  };

  const createItem = (fields) => {
    setWaiting(true);

    let promise = new Promise((resolve, reject) => {
      firebaseDB
        .collection(table)
        .doc()
        .set(fields)
        .then(function (doc) {
          resolve(doc);
        })
        .catch(function (error) {
          setErrors(error);
          reject(error);
          console.log(error);
        })
        .finally(() => {
          setWaiting(false);
        });
    });

    return promise;
  };

  const updateFiltering = (field, operator, value) => {
    let newfilteringQueries = filteringQueries.filter((item) => {
      return item.field !== field;
    });

    if (value) {
      newfilteringQueries = [
        ...newfilteringQueries,
        { field: field, operator: operator, value: value },
      ];
    }

    setFilteringQueries(newfilteringQueries);
  };

  const updateSorting = (field, direction) => {
    setSortingQuery({ field: field, direction: direction });
  };

  const getItems = useCallback(() => {
    setLoading(true);

    var query = firebaseDB.collection(table);

    let filtering = filteringQueries;

    if (onlyActived) {
      filtering = [{ field: "actived", operator: "==", value: true }].concat(
        filtering
      );
    }

    let orderingQueries = [sortingQuery];

    filtering.forEach((item) => {
      query = query.where(item.field, item.operator, item.value);

      if (
        sortingQuery.field !== item.field &&
        (item.operator === "<" ||
          item.operator === "<=" ||
          item.operator === ">" ||
          item.operator === ">=")
      ) {
        orderingQueries = [{ field: item.field, direction: "asc" }].concat(
          orderingQueries
        );
      }
    });

    orderingQueries.forEach((item) => {
      query = query.orderBy(item.field);
    });

    query
      .get()
      .then(({ docs }) => {
        const result = docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        setItems(result);

        setCounter(result.length);
      })
      .catch(function (error) {
        console.error(error);
        setErrors(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filteringQueries, onlyActived, sortingQuery, table]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  return {
    loading,
    waiting,
    errors,
    items,
    counter,
    getItems,
    updateFiltering,
    updateSorting,
    updateItem,
    createItem,
    deleteItem,
    switchActivationItem,
    reset,
    setOnlyActived,
  };
}

import styled from 'styled-components';
import { breakpoints, colors } from 'definitions';

export const Container = styled.p`
	font-family: Lato;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
    white-space: nowrap;
	display: flex;
	align-items: center;
	text-align: right;

	color: ${colors.fonts.veryDarkGray}

	${breakpoints.phablet} {
		margin-bottom: 15px;
		margin-top: 0px;
	}
`;

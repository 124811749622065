import * as React from "react";

const LinkedinLineIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.917 3.615v17.77a1.532 1.532 0 0 1-1.532 1.532H3.615a1.532 1.532 0 0 1-1.532-1.532V3.615a1.532 1.532 0 0 1 1.532-1.532h17.77a1.532 1.532 0 0 1 1.532 1.532ZM8.21 10.05H5.147v9.804h3.064v-9.804Zm.275-3.37a1.765 1.765 0 0 0-1.752-1.777h-.055a1.777 1.777 0 1 0 0 3.554 1.765 1.765 0 0 0 1.807-1.722V6.68Zm11.367 7.218c0-2.947-1.875-4.093-3.738-4.093a3.492 3.492 0 0 0-3.1 1.58h-.086V10.05h-2.88v9.804h3.064v-5.215a2.034 2.034 0 0 1 1.838-2.193h.116c.975 0 1.698.613 1.698 2.157v5.25h3.063l.025-5.955Z"
      stroke="#006EDF"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkedinLineIcon;

import React, {useState, useEffect} from 'react';
import AdminPage from 'components/AdminPage';
import SectionToolbar from 'crud/sections/SectionToolbar'
import SectionCard from 'crud/sections/SectionCard';
import SectionForm from 'crud/sections/SectionForm'
import { object, string, boolean, number } from 'yup';
import useFirestore from 'hooks/useFirestore';

export default function Sections({ params }) {

	const [subTitle, setSubTile] = useState("");

	const {getItem} =  useFirestore();

	useEffect(()=>{
		getItem(`topics/${params.topicId}/awards`, params.awardId).then((result) => {
			setSubTile(`Premio: ${result.item.title}`);
		})
	}, [getItem, params])

	const schema = object({
		actived: boolean(),
		order: number().positive().required('El orden es obligatorio'),
		title: string().required('El título es obligatorio'),
	});

	const sortOptions = [
		{
			value: 'order',
			label: 'Orden'
		},
		{
			value: 'title',
			label: 'Título'
		}
	];

	const sectionCard = ({ item, selected, onSelectEditedItem, onSelectDeletedItem, onCleanItemId }) => (
		<SectionCard onSelectEditedItem={onSelectEditedItem} onSelectDeletedItem={onSelectDeletedItem} onCleanItemId={onCleanItemId} item={item} selected={selected} topicId={params.topicId}  awardId={params.awardId} />
	);

	return (
		<AdminPage
			title="Secciones"
			subTitle={subTitle}
			seoTitle="Administración de secciones"
			sortOptions={sortOptions}
			collection={`topics/${params.topicId}/awards/${params.awardId}/sections`}
			schema={schema}
			ItemFormFields={SectionForm}
			ItemCard={sectionCard}
			ItemToolBar={SectionToolbar}
			orderBy="order"
			backTo={{title: "Volver a premios", url: `/administracion/premios/${params.topicId}`}}

		/>
	);
}

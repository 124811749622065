import React from 'react';
import ContentLoader from "react-content-loader"
import useBreakpoints from 'hooks/useBreakpoint';
import {devices} from 'definitions';

export default function EntryPostulationLoader() {
	
	const {IsLessThan} = useBreakpoints();

	return (

        IsLessThan(devices.phablet) ? 

            <ContentLoader 
            speed={2}
            width={280}
            height={400}
            viewBox="0 0 280 400"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="285" height="127" /> 
                <rect x="5" y="147" rx="0" ry="0" width="171" height="21" /> 
                <rect x="5" y="184" rx="0" ry="0" width="196" height="16" /> 
                <rect x="5" y="209" rx="0" ry="0" width="270" height="15" /> 
                <rect x="5" y="232" rx="0" ry="0" width="270" height="15" /> 
                <rect x="225" y="270" rx="5" ry="5" width="30" height="30" /> 
                <rect x="181" y="270" rx="5" ry="5" width="30" height="30" /> 
                <rect x="40" y="340" rx="0" ry="0" width="195" height="20" />        
            </ContentLoader>

            :  IsLessThan(devices.tablet) ? 
            
            <ContentLoader 
            speed={2}
            width={425}
            height={400}
            viewBox="0 0 425 400"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="425" height="127" /> 
                <rect x="5" y="147" rx="0" ry="0" width="171" height="21" /> 
                <rect x="5" y="184" rx="0" ry="0" width="196" height="16" /> 
                <rect x="5" y="209" rx="0" ry="0" width="270" height="15" /> 
                <rect x="5" y="232" rx="0" ry="0" width="270" height="15" /> 
                <rect x="325" y="270" rx="5" ry="5" width="30" height="30" /> 
                <rect x="370" y="270" rx="5" ry="5" width="30" height="30" /> 
                <rect x="40" y="340" rx="0" ry="0" width="345" height="20" />        
            </ContentLoader>

            :

            <ContentLoader 
            speed={2}
            width={1245}
            height={150}
            viewBox="0 0 1245 150"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="240" height="150" /> 
                <rect x="260" y="119" rx="0" ry="0" width="468" height="20" /> 
                <rect x="260" y="10" rx="0" ry="0" width="147" height="25" /> 
                <rect x="260" y="48" rx="0" ry="0" width="318" height="20" /> 
                <rect x="260" y="84" rx="0" ry="0" width="468" height="20" />

                <rect x="1090" y="65" rx="0" ry="0" width="30" height="30" />
                <rect x="1150" y="65" rx="0" ry="0" width="30" height="30" />
            </ContentLoader>
    )
}
import React from "react";
import { colors } from "definitions";

export default function CheckIcon(props) {
  return (
    <svg
      viewBox="0 0 20 20"
      height={props.height}
      width={props.width}
      aria-hidden="true"
      focusable="false"
      fill={props.color}
      style={{ ...props.style, cursor: "pointer", position: "absolute" }}
    >
      <path d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 01.27-1.951 1.392 1.392 0 011.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 011.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 01-1.12.656c-.022.002-.042.002-.064.002z"></path>
    </svg>
  );
}

CheckIcon.defaultProps = {
  height: `40px`,
  width: `40px`,
  color: `${colors.icons.gray}`,
};

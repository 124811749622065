import { useEffect, useState } from "react"


const isRelativeTimeFormatSupported = typeof Intl !== "undefined" && Intl.RelativeTimeFormat

export default function useTimeAgo(timestamp) {
    const [timeago, setTimeago] = useState(() => getDateDiffs(timestamp))
    
    useEffect(() => {
        if (isRelativeTimeFormatSupported) {
        const interval = setInterval(() => {
            const newTimeAgo = getDateDiffs(timestamp)
            setTimeago(newTimeAgo)
        }, 5000)
    
        return () => clearInterval(interval)
        }
    }, [timestamp])
    
    if (!isRelativeTimeFormatSupported) {
        return formatDate(timestamp)
    }
    
    const rtf = new Intl.RelativeTimeFormat("es", { style: "short" })
    
    const { value, unit } = timeago
    
    return rtf.format(value, unit)
}



const DATE_UNITS = [
    ["day", 86400],
    ["hour", 3600],
    ["minute", 60],
    ["second", 1],
]

const getDateDiffs = (timestamp) => {
    const now = Date.now()
    const elapsed = (timestamp - now) / 1000

    for (const [unit, secondsInUnit] of DATE_UNITS) {

        if (Math.abs(elapsed) > secondsInUnit || unit === "second") {
            const value = Math.round(elapsed / secondsInUnit)
            return { value, unit }
        }

    }
}



const isDateTimeFormatSupported = typeof Intl !== "undefined" && Intl.DateTimeFormat

export const formatDate = (timestamp, { language = "es-AR" } = {}) => {
    const date = new Date(timestamp)
    
    if (!isDateTimeFormatSupported) {
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
        }
        
        return date.toLocaleDateString(language, options)
    }
    
    const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    }
    
    return new Intl.DateTimeFormat(language, options).format(date)
}

export function useDateTimeFormat(timestamp) {
return formatDate(timestamp, { language: "es-AR" })
}
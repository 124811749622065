import React from "react";

export default function SadFaceIcon(props) {
  return (
    <svg
      {...props}
      height={props.height}
      width={props.width}
      style={{ ...props.style, cursor: "pointer" }}
      viewBox="0 0 32 32"
    >
      <path d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm-4.5 9A2.5 2.5 0 1 1 9 13.5a2.48 2.48 0 0 1 2.54-2.5Zm9.64 12.92a6 6 0 0 0-10.28 0l-1.71-1a8 8 0 0 1 13.7 0ZM20.5 16a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5Z" />
      <path
        d="M0 0h32v32H0z"
        style={{
          fill: "none",
        }}
      />
    </svg>
  );
}

SadFaceIcon.defaultProps = {
  height: "6.5rem",
  width: "6.5rem",
  fill: "black",
};

import React, { useState, useEffect, useCallback, Fragment, memo } from "react";
import {
  AwardText,
  ComponentContainer,
  ComponentItemDescription,
  ComponentItemTitle,
  ContainerEntryItem,
  Content,
  Line,
} from "App/styles";

import { Button, BackToAnchorButton } from "basics/buttons";
import SquareLoader from "loader/SquareLoader";
import useEntryForm from "hooks/useEntryForm";
import useScroll from "hooks/useScroll";
import DowloadIcon from "icons/DownloadIcon";
import { colors } from "definitions";

export function InternalAwards({ topic, onBack, onSelected }) {
  const [awards, setAwards] = useState([]);
  const { scrollRef, scrollToRef } = useScroll();
  const { loadingAwards, getAwards } = useEntryForm();

  const handleAwards = useCallback(() => {
    if (topic !== undefined) {
      getAwards(topic.id).then((awards) => {
        setAwards(awards);
      });
    }
  }, [getAwards, topic]);

  useEffect(() => {
    handleAwards();
  }, [handleAwards]);

  useEffect(() => {
    scrollToRef();
  }, [scrollToRef]);

  return (
    <Fragment>
      <ComponentContainer ref={scrollRef}>
        {loadingAwards ? (
          <SquareLoader counter={5} />
        ) : awards.length > 0 ? (
          <AwardList onSelected={onSelected} awards={awards} />
        ) : (
          <AwardItem
            award={{
              title: "Sin Premio",
              description: "No hay premios disponibles",
            }}
          />
        )}
      </ComponentContainer>


      <BackToAnchorButton onClick={onBack}>
        Volver a temáticas
      </BackToAnchorButton>
    </Fragment>
  );
}

const AwardList = memo(({ awards, onSelected }) => {
  return awards.map((award) => (
    <AwardItem onSelected={onSelected} key={award.id} award={award} />
  ));
});

const AwardItem = memo(({ award, onSelected }) => {
  return (
    <ContainerEntryItem location="awards">
      <Line location="awards">
        <AwardText>Premio</AwardText>
      </Line>
      <Content>
        <ComponentItemTitle>{award.title}</ComponentItemTitle>
        <ComponentItemDescription>{award.description}</ComponentItemDescription>

        {award.pdf && award.pdf.length > 0 && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={award.pdf[0].url}
            style={{ marginBottom: "14px" }}
          >
            <DowloadIcon color={`${colors.icons.yellowGreen}`} />
            Instructivo
          </a>
        )}
        <Button
          disabled={!onSelected}
          onClick={() =>
            onSelected({
              id: award.id,
              title: award.title,
              hasSurvey: award.hasSurvey || false,
            })
          }
        >
          Seleccionar
        </Button>
      </Content>
    </ContainerEntryItem>
  );
});

export const Awards = memo(InternalAwards);

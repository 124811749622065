import * as React from "react";

const PhoneLineIcon = (props) => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.86.834A.835.835 0 0 1 11.693 0a8.356 8.356 0 0 1 8.347 8.347.835.835 0 0 1-1.67 0 6.685 6.685 0 0 0-6.677-6.678.835.835 0 0 1-.835-.835Zm.834 4.174a3.339 3.339 0 0 1 3.339 3.339.835.835 0 0 0 1.669 0 5.013 5.013 0 0 0-5.008-5.008.835.835 0 1 0 0 1.669Zm7.59 8.964a2.588 2.588 0 0 1 0 3.654l-.76.876C11.688 25.047-4.948 8.415 1.496 1.557l.96-.834a2.572 2.572 0 0 1 3.612.033C6.094.782 7.64 2.791 7.64 2.791a2.588 2.588 0 0 1-.006 3.574l-.967 1.216a10.67 10.67 0 0 0 5.785 5.797l1.223-.973a2.587 2.587 0 0 1 3.574-.005s2.008 1.546 2.034 1.572Zm-1.149 1.214s-1.997-1.537-2.023-1.563a.918.918 0 0 0-1.293 0c-.023.023-1.706 1.365-1.706 1.365a.835.835 0 0 1-.817.127 12.528 12.528 0 0 1-7.363-7.352.835.835 0 0 1 .12-.835s1.342-1.685 1.365-1.706a.918.918 0 0 0 0-1.293 420.33 420.33 0 0 1-1.563-2.024.918.918 0 0 0-1.26.032l-.96.835c-4.71 5.662 9.707 19.279 14.669 14.59l.76-.877a.935.935 0 0 0 .071-1.3Z"
      fill="#006EDF"
    />
  </svg>
);

export default PhoneLineIcon;

import styled from "styled-components";
import { colors } from "definitions";

export const Container = styled.div`
  background-color: ${colors.background.white};

  width: 100%;
  max-width: 124.5rem;

  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  border-top: solid 1px ${colors.background.lightGray};
  padding: 0 0 25px 0;
  text-align: center;
  font-weight: 600;
  background-color: #f6f7f9;
  line-height: 1.5;
  border-radius: 0 0 6px 6px;
`;

export const Message = styled.label`
  background-color: ${({ type }) =>
    type === "cancel"
      ? `${colors.background.red}`
      : `${colors.background.darkBlue}`};
  text-align: center;
  font-size: 2rem;
  height: 8rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${colors.fonts.white};
  margin-bottom: 2rem;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

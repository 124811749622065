import React from "react";
import { breakpoints } from "definitions";
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 75vh;
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75vh;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

const OverlayImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 733px;
  height: auto;
  pointer-events: none;

   ${breakpoints.tablet}{
   top: 65%;
         width: 100%;
    }
`;

const MainVideo = () => {
    return (
        <Container>
            <StyledIframe
                src="https://www.youtube.com/embed/Gi0h3jJNsb0?rel=0&autoplay=1&controls=0&mute=1&loop=1&playlist=Gi0h3jJNsb0"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
            ></StyledIframe>
            <Overlay>
                <OverlayImage src="/assets/hero_image.png" alt="Overlay" />
            </Overlay>


        </Container>
    );
};

export default MainVideo;
import { breakpoints, colors } from "definitions";
import styled from "styled-components";
import { ContainerEntryItem, ComponentContainer } from "App/styles";

export const Container = styled.div`
  max-width: 124.5rem;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  ${breakpoints.max} {
    width: 95%;
  }

  ${breakpoints.tablet} {
    width: 80%;
  }

  ${breakpoints.mobile} {
    width: 95%;
  }
`;

export const GalleryContainer = styled.div`
  display: flex;
  position: relative;
  max-height: 50rem;
  overflow: hidden;
  margin-top: 2rem;
  border-radius: 0.6rem;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 124.5rem;

  ${breakpoints.tabletXL} {
    height: 25rem;
  }

  ${breakpoints.phablet} {
    height: 15rem;
    width: 27rem;
  }
`;

export const SectionContainer = styled.div`
  max-width: 124.5rem;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionItem = styled.div`
  max-width: 124.5rem;
  min-height: 10rem;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.6rem;
  margin-bottom: 4.9rem;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);

  ${breakpoints.phablet} {
    width: 27rem;
    margin: 0 auto;
    margin-bottom: 4.9rem;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;

  background-color: ${colors.fonts.gray};
  width: 100%;
  border-radius: 6px 6px 0px 0px;
  height: 5.2rem;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-left: 2.4rem;
    color: white;

    ${breakpoints.phablet} {
      margin-left: 1rem;
      font-size: 14px;
      line-height: 18px;
    }
  }

  ${breakpoints.phablet} {
    height: auto;
  }
`;

export const FinalistItem = styled(ContainerEntryItem)`
  flex-direction: column;
  border: 1px solid ${colors.fonts.gray};
`;

export const FinalistItemHeader = styled.div`
  height: 3rem;
  background-color: ${({ winner }) => (winner ? "#006EDF" : "#E5E5E5")};
  border-radius: 6px 6px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  img {
    height: 48px;
    width: 76px;
    margin-top: -45px;
  }
`;

export const FinalistItemBody = styled.div`
  padding: 1rem 2rem;
`;

export const FinalistItemTitle = styled.p`
  border-bottom: 1px solid gray;
  padding-bottom: 0.5rem;
`;

export const FinalistItemContent = styled.p`
  margin-bottom: 3rem;
  font-weight: bold;
`;

export const MentionBody = styled(ComponentContainer)`
  padding: 2rem;
  margin: 0;

  p {
    font-size: 14px;
    line-height: 24px;
  }

  ul {
    font-size: 14px;
    line-height: 24px;
    width: 100%;

    li {
      margin: 1rem 0;
    }
  }
`;

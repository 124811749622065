import styled from "styled-components";

export const Faces = styled.div`
  width: auto;
  height: auto;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

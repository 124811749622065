import React, { useState, useEffect } from "react";
import {
  MenuContainer,
  SubMenuContainer,
  MenuList,
  MenuItem,
  MenuItemLink,
  MainMenuItemLink,
  ExternalMenuItemLink,
  SubMenuList,
  MenuItemTitle,
  Arrow,
} from "./styles";

import MenuLoader from "loader/MenuLoader";
import useOutsideClose from "hooks/useOutsideClose";

export default function Menu({ open, onClose, roles, loading }) {
  const [menuItems, setMenuItems] = useState([]);

  const [menuItemSelected, setMenuItemSelected] = useState(0);

  const { node } = useOutsideClose(() => onClose());

  useEffect(() => {
    setMenuItems([
      {
        id: 40,
        title: "Información",
        visible: true,
        links: [{
          id: 80,
          title: "Postularse",
          visible: true,
          href: "/postularse",
        },
        {
          id: 110,
          title: "Entradas",
          visible: true,
          external: true,
          href: "https://www.eventbrite.com.ar/e/983902848907?aff=oddtdtcreator",
        },
        {
          id: 220,
          title: "Temáticas",
          visible: true,
          href: "/tematicas",
        },
        {
          id: 160,
          title: "Reglamento",
          visible: true,
          href: "/reglamento",
        }]
      },
      {
        id: 80,
        title: "Ediciones Anteriores",
        visible: true,
        href: "/ediciones-anteriores",
        links: [],
      },
      /* {
         id: 90,
         title: "Sponsors",
         visible: true,
         anchor: true,
         href: "#sponsors",
         links: [],
       },*/
      {
        id: 1,
        title: "Jurado",
        visible: roles.judge || roles.selector || roles.administrator,
        links: [
          {
            id: 2,
            title: "Revisión",
            visible: roles.selector,
            href: "/jurado/preseleccion",
          },
          {
            id: 15,
            title: "Finalistas",
            visible: roles.administrator,
            href: "/jurado/finalistas",
          },
          {
            id: 10,
            title: "Votación",
            visible: roles.judge,
            href: "/jurado/votacion",
          },
          {
            id: 23,
            title: "Evaluadores",
            visible: roles.administrator,
            href: "/jurado/evaluadores",
          },
          {
            id: 24,
            title: "Ganadores",
            visible: roles.administrator,
            href: "/jurado/ganadores",
          },
        ],
      },
      {
        id: 3,
        title: "Postulaciones",
        visible: roles.administrator,
        links: [
          {
            id: 5,
            title: "Histórico",
            visible: true,
            href: "/postulaciones/historico",
          },
          {
            id: 17,
            title: "Restauración",
            visible: true,
            href: "/postulaciones/restauracion",
          },
        ],
      },
      {
        id: 6,
        title: "Administración",
        visible: roles.administrator,
        links: [
          {
            id: 10,
            title: "Competiciones",
            visible: true,
            href: "/administracion/competiciones",
          },
          {
            id: 7,
            title: "Temáticas",
            visible: true,
            href: "/administracion/tematicas",
          },
          {
            id: 9,
            title: "Usuarios",
            visible: true,
            href: "/administracion/usuarios",
          },
          {
            id: 12,
            title: "Auspiciantes",
            visible: true,
            href: "/administracion/auspiciantes",
          },
          {
            id: 13,
            title: "Criterios",
            visible: true,
            href: "/administracion/criterios",
          },
          {
            id: 14,
            title: "Ceo",
            visible: true,
            href: "/administracion/ceo",
          },
          {
            id: 18,
            title: "Menciones",
            visible: true,
            href: "/administracion/menciones",
          },
          {
            id: 19,
            title: "Ganadores",
            visible: true,
            href: "/administracion/ganadores",
          },
          {
            id: 20,
            title: "Galeria",
            visible: true,
            href: "/administracion/galeria",
          },
          {
            id: 21,
            title: "Noticias",
            visible: true,
            href: "/administracion/noticias",
          },
        ],
      },
    ]);
  }, [roles]);

  useEffect(() => {
    handleMenuItemUnSelected();
  }, [open]);

  const handleClickOnMenuItem = (id) => {
    if (menuItemSelected === id) {
      handleMenuItemUnSelected();
    } else {
      setMenuItemSelected(id);
    }
  };

  const handleMenuItemUnSelected = () => {
    setMenuItemSelected(0);
  };

  return (
    <MenuContainer>
      {loading ? (
        <MenuLoader />
      ) : (
        <MenuList ref={node} open={open}>
          {menuItems
            .filter((menuItem) => menuItem.visible)
            .map((menuItem) => {
              return (
                menuItem.links.length === 0 ?
                  (<MainMenuItemLink onClick={onClose} to={menuItem.href} href={menuItem.href} >
                    {menuItem.title}
                  </MainMenuItemLink>) :
                  <MenuItem
                    key={menuItem.id}
                    onClick={() => {
                      handleClickOnMenuItem(menuItem.id);
                    }}
                    onMouseLeave={() => {
                      handleMenuItemUnSelected();
                    }}
                  >
                    <MenuItemTitle selected={menuItem.id === menuItemSelected}>
                      {menuItem.title}{" "}
                      <Arrow selected={menuItem.id === menuItemSelected} />
                    </MenuItemTitle>

                    <SubMenuContainer selected={menuItem.id === menuItemSelected}>
                      <SubMenuList selected={menuItem.id === menuItemSelected}>
                        {menuItem.links
                          .filter((link) => link.visible)
                          .map((link) => {
                            return (
                              <MenuItem key={link.id}>
                                {link.external ? (
                                  <ExternalMenuItemLink
                                    onClick={onClose}
                                    href={link.href}
                                    target="_blank"
                                  >
                                    {link.title}
                                  </ExternalMenuItemLink>
                                ) : (
                                  <MenuItemLink onClick={onClose} to={link.href}>
                                    {link.title}
                                  </MenuItemLink>)}
                              </MenuItem>)
                          })}
                      </SubMenuList>
                    </SubMenuContainer>
                  </MenuItem>
              );
            })}
        </MenuList>
      )}
    </MenuContainer>
  );
}

import React from "react";
import AdminPage from "components/AdminPage";
import { object, string, boolean, array } from "yup";
import NewsForm from "crud/news/NewForm";
import NewCard from "crud/news/NewsItemCard";
import NewToolbar from "crud/news/NewToolbar";

export default function News() {
  const schema = object({
    actived: boolean(),
    title: string().required("El título de la noticia es obligatorio"),
    description: string(),
    body: string().required("El cuerpo de la noticia es obligatorio"),
    date: string().required("La fecha de la noticia es obligatoria"),
    image: array().min(1, "Debe cargar por lo menos una imagen"),
  });

  const sortOptions = [
    {
      value: "date",
      label: "Fecha",
    },
  ];

  return (
    <AdminPage
      title="Noticias"
      seoTitle="Administración de noticias"
      sortOptions={sortOptions}
      collection="news"
      schema={schema}
      ItemFormFields={NewsForm}
      ItemCard={NewCard}
      ItemToolBar={NewToolbar}
    />
  );
}

import React, { useState, useContext, memo, useCallback } from "react";

import { ComponentTitle, BodyContainer } from "App/styles";

import { MainTitle } from "components/MainTitle";

import useCrudFlow from "hooks/useCrudFlow";

import ListOf from "components/ListOf";

import ToolBar from "components/ToolBar";

import ItemForm from "components/ItemForm";

import { BackToLinkButton } from "basics/buttons";

import { AlertContext } from "contexts/AlertContext";
import { MessageType } from "model/dictionary";
import AnimatedControl from "components/AnimatedControl";

const AdminPage = memo(
  ({
    title,
    subTitle,
    seoTitle,
    collection,
    schema,
    sortOptions,
    externalCreateItem,
    externalUpdateItem,
    externalDeleteItem,
    ItemFormFields,
    ItemCard,
    ItemToolBar,
    backTo,
    orderBy,
    realDeletion = false,
  }) => {
    const {
      loading,
      items,
      updateFiltering,
      updateSorting,
      reset,
      updateItem,
      createItem,
      setOnlyActived,
      deleteItem,
      switchActivationItem,
    } = useCrudFlow(collection, orderBy || "title");

    const [itemSelectedId, setItemSelectedId] = useState();
    const [itemDeletedId, setItemDeletedId] = useState();
    const [openNewForm, setOpenNewForm] = useState(false);
    const { showAlert } = useContext(AlertContext);

    const [waiting, setWaiting] = useState(false);

    const onOpenNewForm = useCallback(() => {
      setItemSelectedId();
      setOpenNewForm(!openNewForm);
    }, [openNewForm]);

    const onSelectEditedItem = useCallback(
      (id) => {
        setItemSelectedId(id === itemSelectedId ? null : id);
      },
      [itemSelectedId]
    );

    const onSelectDeletedItem = useCallback(
      (id) => {
        setItemDeletedId(id === itemDeletedId ? null : id);
      },
      [itemDeletedId]
    );

    const onCleanItemId = useCallback(
      (resetForm = true) => {
        setItemDeletedId();
        setItemSelectedId();
        setOpenNewForm(false);

        if (resetForm) {
          reset();
        }
      },
      [reset]
    );

    const executePromise = useCallback(
      (promise) => {
        setWaiting(true);

        promise
          .then(() => {
            onCleanItemId();

            showAlert(
              MessageType.SUCESSFULL,
              "Actualización satisfactoria",
              2000
            );
          })
          .catch((error) => {
            showAlert(MessageType.DANGER, `Se produjo un error ${error}`, 2000);
          })
          .finally(() => {
            setWaiting(false);
          });
      },
      [onCleanItemId, showAlert]
    );

    const onDeleteItem = useCallback(
      (id) => {
        var promise = externalDeleteItem
          ? externalDeleteItem(id)
          : realDeletion
          ? deleteItem(id)
          : switchActivationItem(id, false);

        executePromise(promise);
      },
      [
        deleteItem,
        executePromise,
        externalDeleteItem,
        realDeletion,
        switchActivationItem,
      ]
    );

    const onReactivateItem = useCallback(
      (id) => {
        executePromise(switchActivationItem(id, true));
      },
      [executePromise, switchActivationItem]
    );

    const onUpdateItem = useCallback(
      (id, fields) => {
        var promise = externalUpdateItem
          ? externalUpdateItem(id, fields)
          : updateItem(id, fields);

        executePromise(promise);
      },
      [executePromise, externalUpdateItem, updateItem]
    );

    const onCreateItem = useCallback(
      (fields) => {
        const promise = externalCreateItem
          ? externalCreateItem(fields)
          : createItem(fields);

        executePromise(promise);
      },
      [createItem, executePromise, externalCreateItem]
    );

    const itemFormFields = (register, unregister, setValue, errors, item) => (
      <ItemFormFields
        register={register}
        unregister={unregister}
        setValue={setValue}
        errors={errors}
        item={item}
      />
    );

    const itemForm = (item) => (
      <ItemForm
        waiting={waiting}
        formFields={itemFormFields}
        schema={schema}
        item={item}
        onCreateItem={onCreateItem}
        onUpdateItem={onUpdateItem}
      />
    );

    const itemCard = (item, selected) => (
      <ItemCard
        onCleanItemId={onCleanItemId}
        onReactivateItem={onReactivateItem}
        onSelectEditedItem={onSelectEditedItem}
        onSelectDeletedItem={onSelectDeletedItem}
        item={item}
        selected={selected}
      />
    );

    const itemToolbar = () => <ItemToolBar updateFiltering={updateFiltering} />;

    return (
      <BodyContainer>
        <MainTitle title={title} seoTitle={seoTitle} />

        <ToolBar
          onOpenNewForm={onOpenNewForm}
          onSorting={updateSorting}
          sortOptions={sortOptions}
          showNewButton={true}
          onOnlyActivated={setOnlyActived}
        >
          {itemToolbar()}
        </ToolBar>

        <AnimatedControl condition={openNewForm} control={itemForm()} />

        {subTitle && <ComponentTitle>{subTitle}</ComponentTitle>}

        {backTo && (
          <BackToLinkButton to={backTo.url}>{backTo.title}</BackToLinkButton>
        )}

        <ListOf
          loading={loading}
          items={items}
          formItem={itemForm}
          itemSelectedId={itemSelectedId}
          cardItem={itemCard}
          itemDeletedId={itemDeletedId}
          onDeleteItem={onDeleteItem}
          onCleanItemId={onCleanItemId}
        />
      </BodyContainer>
    );
  }
);

export default AdminPage;

import { useCallback } from "react";
import { firebaseDB } from "firebase/client";

import { capitalize, sortByProperty } from "helpers";

export default function useFirestore() {
  const getItems = useCallback(
    ({ setLoading, collection, filter, orderBy }) => {
      setLoading && setLoading(true);

      let query = firebaseDB.collection(collection);

      query = query.where("actived", "==", true);

      if (filter) {
        query = query.where(filter.field, filter.operator, filter.value);
      }

      if (orderBy) {
        query = query.orderBy(orderBy);
      }

      let promise = new Promise((resolve, reject) => {
        query
          .get()
          .then(({ docs }) => {
            resolve(
              docs.map((doc) => {
                const data = doc.data();
                const id = doc.id;

                return { ...data, id };
              })
            );
          })
          .catch(function (error) {
            reject(error);
          })
          .finally(() => {
            setLoading && setLoading(false);
          });
      });

      return promise;
    },
    []
  );

  const getAllItems = useCallback(async (collection) => {
    let query = firebaseDB.collection(collection);

    const snapshots = await query.get();

    const docs = snapshots.docs;

    const items = docs.map((doc) => {
      const data = doc.data();
      const id = doc.id;

      return { ...data, id };
    });

    return items;
  }, []);

  const getAwards = useCallback(async (setAwards) => {
    var collection = firebaseDB.collection("topics");

    let query = collection.where("actived", "==", true).orderBy("title");

    const topicSnapshot = await query.get();

    topicSnapshot.docs.forEach(async (topic) => {
      const awardSnapshot = await firebaseDB
        .collection(`topics/${topic.id}/awards`)
        .where("actived", "==", true)
        .orderBy("title")
        .get();

      const items = awardSnapshot.docs.map((award) => {
        return {
          value: award.id,
          label: capitalize(`${award.data().title} (${topic.data().title})`),
        };
      });

      setAwards((prevAwards) =>
        prevAwards.concat(items).sort(sortByProperty("label"))
      );
    });
  }, []);

  const getCEOAwards = useCallback(async () => {
    const topicSnapshot = await firebaseDB
      .collection("topics")
      .where("actived", "==", true)
      .where("title", "==", "INDUSTRIA")
      .get();

    const topicId = topicSnapshot.docs[0].id;

    const awardSnapshot = await firebaseDB
      .collection(`topics/${topicId}/awards`)
      .where("actived", "==", true)
      .where("ceo", "==", true)
      .get();

    const awards = awardSnapshot.docs.map((award) => {
      return {
        value: award.id,
        label: capitalize(`${award.data().title}`),
      };
    });

    return awards;
  }, []);

  const getItem = useCallback((collection, id) => {
    let query = firebaseDB.collection(collection).doc(id);

    let promise = new Promise((resolve, reject) => {
      query
        .get()
        .then((doc) => {
          const data = doc.data();
          const id = doc.id;

          resolve({ exists: doc.exists, item: { ...data, id } });
        })
        .catch(function (error) {
          reject(error);
        });
    });

    return promise;
  }, []);

  const getItemByTitle = useCallback(async (collection, title) => {
    const snapshots = await firebaseDB
      .collection(collection)
      .where("title", "==", title)
      .get();

    if (snapshots.empty || snapshots.docs.length === 0) {
      throw new Error("No matching documents.");
    }

    const doc = snapshots.docs[0];

    const data = doc.data();
    const id = doc.id;

    return { ...data, id };
  }, []);

  return {
    getItems,
    getAllItems,
    getItem,
    getItemByTitle,
    getAwards,
    getCEOAwards,
  };
}

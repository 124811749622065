import React from "react";
import { Document, Font, Page, View, Text, Image } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";

import fontNormal from "fonts/Lato-Regular.ttf";
import fontBold from "fonts/Lato-Bold.ttf";
import SadoskyLogo from "assets/logo-sadosky.webp";
import { States, StaffPositions } from "model/options";
import { getAnswer } from "helpers";

export default function EntryPDF({ entry }) {
  Font.register({
    family: "Lato",
    fonts: [
      { src: fontNormal },
      { src: fontBold, fontStyle: "normal", fontWeight: 700 },
    ],
  });

  return (
    <>
      {entry && (
        <Document>
          <Body size="A4">
            <Block fixed>
              <Logo src={SadoskyLogo} />
              <HeaderBar>
                <Title>Postulación</Title>
                <SubTitle>Nº {entry.entryNumber}</SubTitle>
              </HeaderBar>
            </Block>

            <Content>
              <Section>
                <SectionTitle>Información del proyecto</SectionTitle>

                <Details>
                  <Field>
                    <FieldLabel>Premio: </FieldLabel>
                    <FieldValue>
                      {entry.topic.title} - {entry.award.title}
                    </FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Apellido y Nombre: </FieldLabel>
                    <FieldValue>
                      {entry.lastName}, {entry.firstName}
                    </FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Empresa/Institución: </FieldLabel>
                    <FieldValue>{entry.companyName}</FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Cargo: </FieldLabel>
                    <FieldValue>
                      {entry.staffPosition &&
                        StaffPositions.filter(
                          (sp) => sp.value === entry.staffPosition
                        )[0].label}
                    </FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Email: </FieldLabel>
                    <FieldValue>{entry.email}</FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Telefono: </FieldLabel>
                    <FieldValue>{entry.phone}</FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Provincia: </FieldLabel>
                    <FieldValue>
                      {entry.state &&
                        States.filter((sp) => sp.value === entry.state)[0]
                          .label}
                    </FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Localidad: </FieldLabel>
                    <FieldValue>{entry.city}</FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Sitio web: </FieldLabel>
                    <FieldValue>{entry.website}</FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>LinkedIn: </FieldLabel>
                    <FieldValue>{entry.linkedin}</FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Facebook: </FieldLabel>
                    <FieldValue>{entry.facebook}</FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Instagram: </FieldLabel>
                    <FieldValue>{entry.instagram}</FieldValue>
                  </Field>

                  <Field>
                    <FieldLabel>Twitter: </FieldLabel>
                    <FieldValue>{entry.twitter}</FieldValue>
                  </Field>
                </Details>
              </Section>

              <SectionTitle>Cuestionario</SectionTitle>

              {entry.sections.map((section) => {
                return (
                  <Section key={section.id}>
                    <SectionSubTitle>{section.title}</SectionSubTitle>
                    <Details>
                      {section.questions.map((question) => {
                        return (
                          <Field key={question.id}>
                            <FieldLabel>{question.title}: </FieldLabel>

                            <FieldValue>{getAnswer(question)}</FieldValue>
                          </Field>
                        );
                      })}
                    </Details>
                  </Section>
                );
              })}
            </Content>
          </Body>
        </Document>
      )}
    </>
  );
}

const Body = styled(Page)`
  padding-top: 35px;
  padding-bottom: 65px;
  padding-right: 35px;
  padding-left: 35px;
  font-family: "Lato";
  color: black;
`;

const Content = styled(View)``;

const Block = styled(View)`
  height: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  align-items: end;
  margin-bottom: 35px;
`;

const Title = styled(Text)`
  font-size: 16px;
`;

const SubTitle = styled(Text)`
  font-size: 8;
  font-weight: bold;
`;

const HeaderBar = styled(View)`
  height: 50px;
  width: 40%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const Logo = styled(Image)`
  width: 165px;
  height: 57px;
`;

const Section = styled(View)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-top: 10px;
  padding: 0 10px 0 10px;
`;

const SectionTitle = styled(Text)`
  display: flex;
  text-align: center;
  width: 100%;
  color: #353535;
  font-weight: bold;
`;

const SectionSubTitle = styled(Text)`
  display: flex;
  text-align: left;
  width: 100%;
  color: #006EDF;
  font-size: 12px;
  font-weight: bold;
`;

const Details = styled(View)`
  display: flex;
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
`;

const Field = styled(Text)`
  font-size: 10px;
  margin: 5px 0;
`;

const FieldLabel = styled(Text)`
  font-size: 10px;
  margin: 10px 10px 0 0;
  font-weight: bold;
`;

const FieldValue = styled(Text)`
  font-size: 10px;
  margin: 10px 10px 0 0;
  font-weight: normal;
`;

import React, { Fragment } from "react";

import { VerticalLine, HorizontalLine } from "App/styles";
import { IconsSection } from "basics/cards";

import { devices } from "definitions";
import CloseIcon from "icons/CloseIcon";
import DeleteIcon from "icons/cards/DeleteIcon";
import EditIcon from "icons/cards/EditIcon";
import ActivateIcon from "icons/ActivateIcon";

import useBreakpoints from "hooks/useBreakpoint";

export default function ItemCardToolBar({
  id,
  actived,
  selected,
  onSelectEditedItem,
  onSelectDeletedItem,
  onReactivateItem,
  onCleanItemId,
  children,
}) {
  const { IsLessThan } = useBreakpoints();

  return (
    <Fragment>
      {IsLessThan(devices.phablet) ? <HorizontalLine /> : <VerticalLine />}

      <IconsSection>
        {selected ? (
          <CloseIcon
            onClick={() => {
              onCleanItemId(false);
            }}
          />
        ) : (
          <>
            {actived && (
              <EditIcon
                onClick={() => {
                  onSelectEditedItem(id);
                }}
              />
            )}

            {children}

            {actived && (
              <DeleteIcon
                onClick={() => {
                  onSelectDeletedItem(id);
                }}
              />
            )}

            {!actived && (
              <ActivateIcon
                onClick={() => {
                  onReactivateItem(id);
                }}
              />
            )}
          </>
        )}
      </IconsSection>
    </Fragment>
  );
}

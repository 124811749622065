import React from "react";

import { InputField, CheckBoxField } from "basics";

export default function WinnerForm({ register, setValue, errors, item }) {
  return (
    <>
      <InputField
        label="Año"
        inputmode="numeric"
        type="number"
        value={item && item.year}
        id="year"
        register={register}
        errors={errors}
        mode="dark"
      />

      <InputField
        label="Temática"
        value={item && item.title}
        id="title"
        register={register}
        errors={errors}
        mode="dark"
      />

      <InputField
        label="Empresa/Institución"
        value={item && item.company}
        id="company"
        register={register}
        errors={errors}
        mode="dark"
      />

      <InputField
        label="Iniciativa"
        value={item && item.project}
        id="project"
        register={register}
        errors={errors}
        mode="dark"
      />

      <CheckBoxField
        id="winner"
        label="Ganador?"
        errors={errors}
        register={register}
        checked={item && item.winner}
        mode="dark"
        twoLines={true}
      />
    </>
  );
}

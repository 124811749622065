import React from "react";
import AdminPage from "components/AdminPage";
import GalleryToolbar from "crud/gallery/GalleryToolbar";
import GalleryCard from "crud/gallery/GalleryCard";
import GalleryForm from "crud/gallery/GalleryForm";
import { object, string, boolean, array } from "yup";

export default function Gallery() {
  const schema = object({
    actived: boolean(),
    title: string().required("El año es obligatorio"),
    images: array().min(1, "Debe cargar por lo menos una imagen"),
  });

  const sortOptions = [
    {
      value: "title",
      label: "Año",
    },
  ];

  return (
    <AdminPage
      title="Galeria"
      seoTitle="Administración de galeria de imagenes por competición"
      sortOptions={sortOptions}
      collection="gallery"
      schema={schema}
      ItemFormFields={GalleryForm}
      ItemCard={GalleryCard}
      ItemToolBar={GalleryToolbar}
    />
  );
}

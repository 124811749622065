import React, { useEffect } from "react";
import {
  Container,
  CompletedMessage,
  LogoImage,
  EmailMessage,
  MessageForm,
} from "./styles";
import FormCompletedImage from "assets/form-completed.webp";
import { useLocation } from "wouter";

export function FormCreatedSuccessfully() {
  const [, navigate] = useLocation();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, [navigate]);

  return (
    <Container>
      <MessageForm>
        <CompletedMessage>Felicidades!</CompletedMessage>
        <CompletedMessage>La postulación fue cargada</CompletedMessage>
      </MessageForm>

      <EmailMessage>
        Hemos enviado un email para confirmar la postulación.
      </EmailMessage>
      <EmailMessage>Por favor, revise su casilla de mail.</EmailMessage>

      <LogoImage alt="Formulario Completado" src={FormCompletedImage} />
    </Container>
  );
}

export function FormUpdatedSuccessfully() {
  const [, navigate] = useLocation();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, [navigate]);

  return (
    <Container>
      <MessageForm>
        <CompletedMessage>Felicidades!</CompletedMessage>
        <CompletedMessage>La postulación fue confirmada</CompletedMessage>
      </MessageForm>

      <LogoImage alt="Formulario Completado" src={FormCompletedImage} />
    </Container>
  );
}

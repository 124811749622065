import React, { useState } from "react";
import { Container } from "./styles";
import { RedButton, Button, BackToLinkButton } from "basics/buttons";
import { CheckBoxField } from "basics";
import { FormStatus } from "model/dictionary";

export default function FormToolBar({
  onUpdateForm,
  currentStatus,
  onClose,
  readOnly,
}) {
  const [confirmed, setConfirmed] = useState(false);

  const onHandleConfirmed = (e) => {
    e.preventDefault();

    onUpdateForm(FormStatus.CONFIRMED);
  };

  const onHandleDiscarded = (e) => {
    e.preventDefault();

    onUpdateForm(FormStatus.DISCARDED);
  };

  const ShowButtons = () => {
    switch (currentStatus) {
      case FormStatus.DRAFT: {
        return (
          <>
            <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
              <Button onClick={onHandleConfirmed}>Confirmar</Button>
              <RedButton onClick={onHandleDiscarded}>Descartar</RedButton>
            </div>
            <BackToLinkButton to="/">Volver al inicio</BackToLinkButton>
          </>
        );
      }
      case "view": {
        return onClose ? (
          <Button onClick={onClose}>Cerrar</Button>
        ) : (
          <BackToLinkButton to="/">Volver</BackToLinkButton>
        );
      }
      default: {
        return (
          <>
            <Button disabled={!confirmed}>Finalizar</Button>
            <BackToLinkButton to="/">Volver al inicio</BackToLinkButton>
          </>
        );
      }
    }
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ShowButtons />
      </div>
      {(!currentStatus || currentStatus === FormStatus.PREVIEW) && (
        <CheckBoxField
          id="Confirmation"
          showRow={true}
          secondary="Acepto los términos y condiciones del "
          link="Reglamento de los Premios Sadosky"
          onChange={() => setConfirmed(!confirmed)}
          readOnly={readOnly}
        />
      )}
    </Container>
  );
}

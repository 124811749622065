import { BodyContainer } from "App/styles";
import { MainTitle } from "components/MainTitle";
import useFirestore from "hooks/useFirestore";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { DescriptionContainer, Image } from "./styles";

export default function ViewNew({ params }) {
  const { getItem } = useFirestore();

  const [newsItem, setNewsItem] = useState();

  useEffect(() => {
    getItem("news", params.newId).then((result) => {
      setNewsItem(result.item);
    });
  }, [getItem, params]);

  return (
    <BodyContainer>
      {newsItem && (
        <>
          <MainTitle
            title={`${newsItem.title}`}
            seoTitle={`${newsItem.title}`}
          />
          <DescriptionContainer>
            <ReactMarkdown>{newsItem.body}</ReactMarkdown>

            <Image src={newsItem.image[0].url} alt="Foto de la noticia" />
          </DescriptionContainer>
        </>
      )}
    </BodyContainer>
  );
}

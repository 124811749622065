import * as React from "react"

export default function Cessi(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={91}
      height={31}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="M22.489 9.42a6.262 6.262 0 0 0-2.765-1.13 6.3 6.3 0 0 0-2.276-7.087 6.305 6.305 0 0 0-8.803 1.391l-7.41 10.188a6.292 6.292 0 0 0 4.16 9.928 6.3 6.3 0 0 0 2.277 7.087 6.305 6.305 0 0 0 8.802-1.394l7.407-10.188a6.293 6.293 0 0 0-1.395-8.795h.003ZM11.433 20.186a6.304 6.304 0 0 1-6.038 2.524 6.286 6.286 0 0 1 .882-1.708l7.407-10.188A6.304 6.304 0 0 1 19.72 8.29c-.205.611-.5 1.189-.881 1.708l-7.407 10.188ZM40.082 20.262c-2.608 0-4.477-2-4.477-4.762 0-2.684 1.79-4.787 4.449-4.787 2 0 3.606 1.262 3.977 3.183l2.5-.606c-.763-3.211-3.424-4.998-6.556-4.998-4.11 0-7.056 3.155-7.056 7.208 0 4.024 2.871 7.21 7.084 7.21 3.133 0 5.819-1.79 6.582-4.998l-2.529-.577c-.368 1.92-1.976 3.13-3.977 3.13M54.669 8.29c-3.686 0-6.767 2.894-6.767 7.232 0 4.21 2.71 7.182 6.899 7.185 3 0 5.397-1.604 6.24-4.316l-2.448-.606c-.475 1.711-2.08 2.659-3.792 2.659-2.555 0-4.188-1.579-4.213-4.263h10.716v-.816c0-4.106-2.448-7.078-6.635-7.078m-4.056 5.923a3.997 3.997 0 0 1 4.056-3.63c2.08 0 3.845 1.29 3.977 3.63h-8.033ZM65.7 12.314c0-.998.948-1.71 2.45-1.71 1.501 0 2.658.762 2.896 2.156l2.29-.656c-.42-2.474-2.607-3.814-5.108-3.814-3.003 0-5.03 1.657-5.03 3.999 0 5.5 7.823 2.866 7.823 6.184 0 1.158-.948 1.92-2.711 1.92s-2.897-.815-3.133-2.448l-2.422.553c.343 2.658 2.422 4.21 5.45 4.21 3.293 0 5.452-1.79 5.452-4.446 0-5.603-7.955-2.947-7.955-5.945M78.313 12.314c0-.998.949-1.71 2.45-1.71 1.555 0 2.659.762 2.897 2.156l2.29-.656c-.42-2.474-2.607-3.814-5.108-3.814-3.003 0-5.03 1.657-5.03 3.999 0 5.5 7.823 2.866 7.823 6.184 0 1.158-.949 1.92-2.711 1.92-1.763 0-2.897-.815-3.133-2.448l-2.422.553c.342 2.658 2.422 4.21 5.45 4.21 3.293 0 5.451-1.79 5.451-4.446 0-5.603-7.954-2.947-7.954-5.945M90.865 8.567a2.604 2.604 0 0 0-2.605 2.606v11.262a2.607 2.607 0 0 0 2.608-2.605V8.567h-.003ZM89.56 7.022A1.44 1.44 0 0 0 91 5.583a1.44 1.44 0 0 0-2.88 0 1.44 1.44 0 0 0 1.44 1.44Z"
      />
    </svg>
  )
}

import React from 'react';

import AdminPage from 'components/AdminPage';
import UserToolbar from 'crud/users/UserToolBar';
import UserForm from 'crud/users/UserForm';
import UserCard from 'crud/users/UserCard';
import { object, string, array, boolean } from 'yup';
import useAuth from 'hooks/useAuth';

export default function Users() {
	const { createUser, deleteUser, updateUser } = useAuth();

	const schema = object({
		actived: boolean(),
		roles: array(
			object({
				value: string(),
				label: string(),
				checked: boolean()
			})
		),
		title: string().required('El nombre es obligatorio'),
		email: string().email('Ingrese un mail con el formato correcto').required('El mail es obligatorio')
	});

	const sortOptions = [
		{
			value: 'title',
			label: 'Nombre'
		},
		{
			value: 'email',
			label: 'Mail'
		}
	];

	const createItem = (fields) => {
		return createUser(fields.email, fields.title, fields.roles, fields.awards);
	};

	const updateItem = (uid, fields) => {
		return updateUser(uid, fields.email, fields.title, fields.roles, fields.awards);
	};

	const deleteItem = (uid) => {
		return deleteUser(uid);
	};

	return (
		<AdminPage
			title="Usuarios"
			seoTitle="Administración de usuarios"
			sortOptions={sortOptions}
			collection="users"
			schema={schema}
			externalCreateItem={createItem}
			externalUpdateItem={updateItem}
			externalDeleteItem={deleteItem}
			ItemFormFields={UserForm}
			ItemCard={UserCard}
			ItemToolBar={UserToolbar}
		/>
	);
}

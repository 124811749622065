import React, { memo } from "react";
import { animated, config, useTransition } from "react-spring";
import styled from "styled-components";

const AnimatedControl = memo(({ condition, control }) => {
  const transitions = useTransition(condition, {
    // reverse: condition,
    config: config.gentle,
    from: { y: -100, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -100, opacity: 0 },
  });

  return (
    <>
      {transitions(
        (style, item) =>
          item && <AnimatedContainer style={style}>{control}</AnimatedContainer>
      )}
    </>
  );
});

export default AnimatedControl;

const AnimatedContainer = styled(animated.div)`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100%;
`;

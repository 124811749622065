import styled from 'styled-components';
import { breakpoints } from 'definitions';
import { animated } from 'react-spring';

export const Control = styled(animated.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 25px;

  ${breakpoints.phablet}{
    margin: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  display:flex;
  align-items: center;
  max-width: 124.5rem;

  ${breakpoints.phablet}{
    width: 90%;
  }
`;

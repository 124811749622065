import React from 'react';

import { FormStatus, SelectionMode, EntryListingMode } from 'model/dictionary';

import EntriesPage from 'components/EntriesPage';

export default function Selection() {

	return (
		<EntriesPage
			title="Revisión"
			entryListMode={EntryListingMode.CRITERIA}
			formStatusFiltered={FormStatus.CONFIRMED}
			selectionMode={SelectionMode.SIMPLE}
		/>
	);

}

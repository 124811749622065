import React from 'react';

import { FormStatus, SelectionMode, EntryListingMode } from 'model/dictionary';

import EntriesPage from 'components/EntriesPage';

export default function Finalists() {

	return (
		<EntriesPage
			title="Finalistas"
			entryListMode={EntryListingMode.FINALISTS}
			formStatusFiltered={FormStatus.CONFIRMED}
			formStatusConfirmed={FormStatus.FINALIST}
			selectionMode={SelectionMode.MULTIPLE}
		/>
	);

}

import React, { useEffect, useState, memo } from "react";
import {
  Container,
  EntryItem,
  Category,
  Partners,
  Wrapper,
  SponsorsTitle
} from "./styles";

import SponsorsCarousel from "components/SponsorsCarousel";
import useFirebase from "hooks/useFirestore";

const Sponsors = memo(() => {
  const [categories, setCategories] = useState([]);

  const { getItems } = useFirebase();

  useEffect(() => {
    getItems({ collection: "sponsors", orderBy: "order" }).then(setCategories);
  }, [getItems]);

  return (

    <Container id="sponsors">
      <SponsorsTitle>Nos acompañan</SponsorsTitle>
      {categories.map((category) => {
        return (
          <EntryItem key={category.id}>
            <Category color={category.color}>{category.title}</Category>
            <Partners size={category.size}>
              <Wrapper>
                <SponsorsCarousel
                  items={category.logos}
                  size={category.size}
                />
              </Wrapper>
            </Partners>
          </EntryItem>
        );
      })}
    </Container>

  );
});

export default Sponsors;

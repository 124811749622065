import React, { useCallback, useEffect, useState } from "react";

import useBreakpoint from "hooks/useBreakpoint";
import { devices } from "definitions";
import { YearContainer, YearItem } from "./styles";
import AutoPlayCarousel from "components/AutoPlayCarousel";

const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

export default function YearsCarousel({ yearSelected, onYearSelected }) {
  const { IsLessThan } = useBreakpoint();
  const [years, setYears] = useState([]);

  useEffect(() => {
    setYears(range(2005, new Date().getFullYear() - 1));
  }, []);

  const getNumberOfCards = useCallback(() => {
    let cards;

    cards = 6;

    if (IsLessThan(devices.phablet)) {
      cards = 1;
    } else if (IsLessThan(devices.tabletXL)) {
      cards = 3;
    } else if (IsLessThan(devices.laptop)) {
      cards = 4;
    } else if (IsLessThan(devices.wide)) {
      cards = 5;
    }

    return cards;
  }, [IsLessThan]);

  return (
    <AutoPlayCarousel
      show={getNumberOfCards()}
      initialIndex={new Date().getFullYear() - 2006}
      showControls={true}
      autoplay={true}
    >
      {years.map((year) => {
        return (
          <YearContainer key={year}>
            <YearItem
              selected={yearSelected === year}
              onClick={() => onYearSelected(year)}
            >
              {year}
            </YearItem>
          </YearContainer>
        );
      })}
    </AutoPlayCarousel>
  );
}

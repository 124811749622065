import styled from "styled-components";
import { colors, breakpoints } from "definitions";

import { Button } from "basics/buttons";

export const Container = styled.div`
  background-color: ${colors.background.white};
  color: ${colors.fonts.gray};
  /*box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);*/

  box-shadow: ${({ selected }) =>
    selected ? "4px 8px 8px #353535" : "4px 4px 8px rgba(0, 0, 0, 0.15)"};
  border-radius: 0px 0px 6px 6px;
  border-color: ${({ selected }) =>
    selected ? `${colors.background.darkBlue}` : ""};
  border-width: ${({ selected }) => (selected ? "5px" : "")};
  border-style: ${({ selected }) => (selected ? "solid" : "")};

  width: 100%;
  max-width: 124.5rem;
  height: 8rem;

  line-height: 1.5;
  text-align: center;
  font-weight: 600;
  font-size: 1.8rem;

  margin-bottom: 2rem;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  /*border-radius: 0 0 6px 6px;*/
  border-top: solid 1px ${colors.background.lightGray};

  ${breakpoints.tablet} {
    height: auto;
    padding: 1rem;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${breakpoints.tablet} {
    flex-direction: column;
  }
`;

export const Block = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  :nth-of-type(1) {
    flex-basis: 80%;
    flex-wrap: wrap;
  }

  :nth-of-type(2) {
    flex-basis: 22%;
    justify-content: center;
  }

  ${breakpoints.tablet} {
    :nth-of-type(1) {
      margin-bottom: 2rem;
    }
  }
`;

export const Result = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${breakpoints.tabletXL} {
    margin: 1rem;
  }
`;

export const SelectionButton = styled(Button)`
  min-height: 5rem;

  ${breakpoints.tablet} {
    width: 100%;
  }
`;

export const UnselectionButton = styled(Button)`
  background-color: ${colors.background.gray};
  min-height: 5rem;

  :hover {
    background-color: ${colors.background.red};
  }

  ${breakpoints.tablet} {
    width: 100%;
  }
`;

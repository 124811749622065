import React, { useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { ReactComponent as LeftIcon } from "assets/left-icon.svg";
import { ReactComponent as RightIcon } from "assets/right-icon.svg";

const Slideshow = ({
  children,
  controls = false,
  autoplay = false,
  velocity = "500",
  interval = "5000",
}) => {
  const slideshow = useRef(null);
  const intervalSlideshow = useRef(null);

  const next = useCallback(() => {
    if (slideshow.current.children.length > 0) {
      // Obtenemos el primer elemento del slideshow.
      const firstElement = slideshow.current.children[0];

      // Establecemos la transition para el slideshow.
      slideshow.current.style.transition = `${velocity}ms ease-out all`;

      const slideSize = slideshow.current.children[0].offsetWidth;

      // Movemos el slideshow
      slideshow.current.style.transform = `translateX(-${slideSize}px)`;

      const transition = () => {
        // Reiniciamos la posicion del Slideshow.
        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = `translateX(0)`;

        // Tomamos el primer elemento y lo mandamos al final.
        slideshow.current.appendChild(firstElement);

        slideshow.current.removeEventListener("transitionend", transition);
      };

      // Eventlistener para cuando termina la animacion.
      slideshow.current.addEventListener("transitionend", transition);
    }
  }, [velocity]);

  const previous = () => {
    if (slideshow.current.children.length > 0) {
      // Obtenemos el ultimo elemento del slideshow.
      const index = slideshow.current.children.length - 1;
      const latestElement = slideshow.current.children[index];

      slideshow.current.insertBefore(
        latestElement,
        slideshow.current.firstChild
      );

      slideshow.current.style.transition = "none";
      const slideSize = slideshow.current.children[0].offsetWidth;
      slideshow.current.style.transform = `translateX(-${slideSize}px)`;

      setTimeout(() => {
        slideshow.current.style.transition = `${velocity}ms ease-out all`;
        slideshow.current.style.transform = `translateX(0)`;
      }, 30);
    }
  };

  useEffect(() => {
    if (autoplay) {
      intervalSlideshow.current = setInterval(() => {
        next();
      }, interval);

      // Eliminamos los intervals
      slideshow.current.addEventListener("mouseenter", () => {
        clearInterval(intervalSlideshow.current);
      });

      // Volvemos a poner el interval cuando saquen el cursor del slideshow
      slideshow.current.addEventListener("mouseleave", () => {
        intervalSlideshow.current = setInterval(() => {
          next();
        }, interval);
      });
    }
  }, [autoplay, interval, next]);

  return (
    <MainContainer>
      <SlideShowContainer ref={slideshow}>{children}</SlideShowContainer>
      {controls && (
        <Controls>
          <Button onClick={previous}>
            <LeftIcon />
          </Button>
          <Button right onClick={next}>
            <RightIcon />
          </Button>
        </Controls>
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  position: relative;
`;

const SlideShowContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Slide = styled.div`
  min-width: 100%;
  overflow: hidden;
  transition: 0.3s ease all;
  z-index: 10;
  position: relative;
  img {
    width: 100%;
    vertical-align: top;
  }
`;

const Controls = styled.div`
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Button = styled.button`
  pointer-events: all;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  width: 50px;
  height: 100%;
  text-align: center;
  position: absolute;
  transition: 0.3s ease all;
  path {
    filter: ${(props) =>
      props.right
        ? "drop-shadow(-2px 0px 0px #fff)"
        : "drop-shadow(2px 0px 0px #fff)"};
  }
  ${(props) => (props.right ? "right: 0" : "left: 0")}
`;

export { Slideshow, Slide };

import React from "react";
import AdminPage from "components/AdminPage";
import CriteriaToolbar from "crud/criteria/CriteriaToolbar";
import CriteriaCard from "crud/criteria/CriteriaCard";
import CriteriaForm from "crud/criteria/CriteriaForm";

import { object, string, boolean, number } from "yup";

export default function Criteria() {
  const schema = object({
    actived: boolean(),
    title: string().required("El título es obligatorio"),
    ponderation: number().positive().required("El valor es obligatorio"),
  });

  const sortOptions = [
    {
      value: "title",
      label: "Título",
    },
  ];

  return (
    <AdminPage
      title="Criterios"
      seoTitle="Administración de criterios de evaluación"
      sortOptions={sortOptions}
      collection="criteria"
      schema={schema}
      ItemFormFields={CriteriaForm}
      ItemCard={CriteriaCard}
      ItemToolBar={CriteriaToolbar}
    />
  );
}

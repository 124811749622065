import React from "react";

import { InputField, TextAreaField } from "basics";
import FileUploader from "components/FileUploader";

export default function NewsForm({ register, setValue, errors, item }) {
  return (
    <>
      <InputField
        label="Título"
        value={item && item.title}
        id="title"
        register={register}
        errors={errors}
        mode="dark"
      />

      <InputField
        label="Breve descripción"
        value={item && item.description}
        id="description"
        register={register}
        errors={errors}
        mode="dark"
      />

      <TextAreaField
        label="Cuerpo"
        type="text"
        value={item && item.body}
        id="body"
        register={register}
        error={errors && errors.body}
        mode="dark"
      />

      <InputField
        label="Fecha"
        type="date"
        value={item && item.date}
        id="date"
        register={register}
        errors={errors}
        mode="dark"
      />

      <FileUploader
        label="Imagen"
        id="image"
        value={item && item.image}
        folder={`news`}
        register={register}
        error={errors.image}
        setValue={setValue}
        accepted="image/*"
        mode="dark"
        multiple={false}
      />
    </>
  );
}

import React, { useEffect, useState, useContext, useCallback } from "react";

import { FormStatus } from "model/dictionary";
import { BodyContainer } from "App/styles";

import { Container, Control } from "./styles";

import Form from "components/Form";
import {
  FormUpdatedSuccessfully,
  FormCreatedSuccessfully,
} from "components/FormFinalMessage";
import { useTransition } from "react-spring";
import { MainTitle } from "components/MainTitle";
import { useLocation } from "wouter";
import useEntryForm from "hooks/useEntryForm";

import { AlertContext } from "contexts/AlertContext";
import { MessageType } from "model/dictionary";

export default function UpdateEntry({ params }) {
  const [stepNumber, setStepNumber] = useState(0);
  const { loadingForm, getEntryForm, initialForm } = useEntryForm();
  const [, navigate] = useLocation();
  const [currentStatus, setCurrentStatus] = useState();
  const { showAlert } = useContext(AlertContext);
  const [form, setForm] = useState(initialForm);
  const [readOnly, setReadonly] = useState(true);
  useEffect(() => {
    getEntryForm(params.entryId).then((data) => {
      if (!data.exists) {
        showAlert(MessageType.DANGER, "El formulario no existe", 3000, () => {
          navigate("/");
        });
      } else {
        if (
          data.currentStatus === FormStatus.CONFIRMED ||
          data.currentStatus === FormStatus.DISCARDED
        ) {
          showAlert(
            MessageType.WARNING,
            `El formulario ya fue ${
              data.currentStatus === FormStatus.CONFIRMED
                ? "confirmado"
                : "descartado"
            }. Gracias`,
            3000,
            () => {
              navigate("/");
            }
          );
        } else {
          setForm(data.form);

          setReadonly(data.currentStatus !== FormStatus.PREVIEW);

          setCurrentStatus(data.currentStatus);
        }
      }
    });
  }, [getEntryForm, navigate, params.entryId, showAlert]);

  const formCompleted = useCallback(
    (status) => {
      if (status === FormStatus.DISCARDED) {
        navigate("/");
      } else {
        setStepNumber(1);
      }
    },
    [navigate]
  );

  const transitions = useTransition(stepNumber, {
    from: { position: "absolute", opacity: 0 },
    enter: { position: "relative", opacity: 1 },
    leave: { position: "absolute", opacity: 0 },
  });

  const GetFormComponent = useCallback(() => {
    switch (stepNumber) {
      case 0: {
        return (
          <Form
            onCompleted={formCompleted}
            currentStatus={currentStatus}
            readOnly={readOnly}
            form={form}
            loading={loadingForm}
          />
        );
      }
      case 1: {
        return currentStatus === FormStatus.PREVIEW ? (
          <FormCreatedSuccessfully />
        ) : (
          <FormUpdatedSuccessfully />
        );
      }
      default: {
        return <h1>ERROR</h1>;
      }
    }
  }, [currentStatus, form, formCompleted, loadingForm, readOnly, stepNumber]);

  return (
    <React.Fragment>
      <BodyContainer>
        <MainTitle title={"Formulario"} seoTitle={"Formulario"} />

        <Container>
          {transitions((style, item) => (
            <Control style={style}>{GetFormComponent()}</Control>
          ))}
        </Container>
      </BodyContainer>
    </React.Fragment>
  );
}

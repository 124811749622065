import React, { useState, useCallback } from "react";

import styled from "styled-components";

import cross from "icons/cross";
import warning from "icons/warning";
import done from "icons/done";

import { MessageType } from "model/dictionary";

//import Spinner from "components/Spinner";
import Modal from "components/Modal";

export const AlertContext = React.createContext();

const GetIcon = (type) => {
  switch (type) {
    case MessageType.WAITING:
      return <Spinner size="medium" position="left" />;
    case MessageType.DANGER:
      return <IconDanger />;
    case MessageType.WARNING:
      return <IconWarning />;
    case MessageType.SUCESSFULL:
      return <IconSucessfull />;
    default:
      break;
  }
};

export const AlertProvider = ({ children }) => {
  const [show, setShowAlert] = useState(false);
  const [type, setType] = useState("gray");
  const [content, setContent] = useState("Procesando solicitud");

  const showAlert = useCallback((type, content, duration, callback) => {
    setShowAlert(true);
    setType(type);

    setContent(content);

    duration &&
      setTimeout(() => {
        setShowAlert(false);
        callback && callback();
      }, duration);
  }, []);

  const hideWaiting = useCallback(() => {
    setShowAlert(false);
  }, []);

  const showWaiting = useCallback((content) => {
    setShowAlert(true);
    setType(MessageType.WAITING);
    setContent(content);
  }, []);

  return (
    <AlertContext.Provider
      value={{
        showAlert,
        hideWaiting,
        showWaiting,
      }}
    >
      <Modal
        locked
        onClose={() => {
          setShowAlert(false);
        }}
        open={show}
      >
        <Container>
          <div className="icon">{GetIcon(type)}</div>
          <p>{content}</p>
        </Container>
      </Modal>
      {children}
    </AlertContext.Provider>
  );
};

const Container = styled.div`
  width: 30rem;
  height: 18rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  p {
    font-weight: 600;
    text-align: center;
  }
`;

const IconDanger = styled(cross)`
  width: 6rem;
  height: 6rem;
  color: #d8000c;
  position: absolute;
  left: 2.5rem;
`;

const IconWarning = styled(warning)`
  width: 5rem;
  height: 5rem;
  color: #bdd53b;
  position: absolute;
  left: 2.5rem;
`;

const IconSucessfull = styled(done)`
  width: 5rem;
  height: 5rem;
  color: #006EDF;
  position: absolute;
  left: 2.5rem;
`;

const Spinner = () => {
  return (
    <StyledSpinner>
      <div className="cssload-container">
        <div className="cssload-whirlpool"></div>
      </div>
    </StyledSpinner>
  );
};

const StyledSpinner = styled.div`
  width: 65%;

  .cssload-container {
  }

  .cssload-whirlpool,
  .cssload-whirlpool::before,
  .cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 3px solid rgb(190, 213, 59);
    border-left-color: rgb(8, 37, 90);
    border-radius: 2622px;
  }

  .cssload-whirlpool {
    height: 110px;
    width: 110px;

    margin: auto 0;
    top: 0;
    left: 20px;
    right: auto;
    bottom: 0;

    animation: cssload-rotate 1150ms linear infinite;
  }

  .cssload-whirlpool::before {
    content: "";
    margin: -45px;

    height: 90px;
    width: 90px;

    animation: cssload-rotate 1150ms linear infinite;
  }

  .cssload-whirlpool::after {
    content: "";
    margin: -33px;

    height: 65px;
    width: 65px;

    animation: cssload-rotate 2300ms linear infinite;
  }

  @keyframes cssload-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes cssload-rotate {
    100% {
      -o-transform: rotate(360deg);
    }
  }

  @-ms-keyframes cssload-rotate {
    100% {
      -ms-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes cssload-rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes cssload-rotate {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
`;

import { breakpoints, colors } from "definitions";
import styled from "styled-components";
import { Link } from "wouter";

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  
  padding: 2rem;
  gap: 5rem;
  height: 240px;
  place-items: center;
  background-image: url('/backgrounds/first_background.svg');
  background-repeat: no-repeat;
  background-size: cover; /* Ajusta el SVG para que cubra todo el div */
  background-position: center center; 

   ${breakpoints.tabletXL} {
    height: 100%;
  }
`;

export const Content = styled.div`
  max-width: 83rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  gap: 2rem;

  ${breakpoints.tabletXL} {
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const Card = styled.article`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${colors.background.white};
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  border: 1px solid ${colors.background.lightGray};
  max-height: 160px;
  
  transition: all 400ms ease-in-out;

  text-decoration: none;

  color: ${colors.fonts.darkGray};

  padding: 2rem;
  text-align: center;

  ${breakpoints.laptop} {
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    max-width: 300px;
  }
    
  ${breakpoints.tabletXL} {
    max-width: none;
  }

`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Title = styled.h3`
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0.75rem;
  margin-top: 0;
  line-height: 25px;
`;

export const SubTitle = styled.h4`
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  line-height: 25px;
`;

export const LinkStyled = styled(Link)`
  color: ${colors.fonts.darkGray};
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  width: 100%;
  border
  transition: all 400ms ease-in-out;
  border: 2px solid #006EDF;
  border-radius: 8px;
  &:hover {
    background-color: #006EDF;
    color: white;
  }
`;

export const LinkSubscribe = styled(Link)`
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  width: 100%;
  background-color: #006EDF;
  border: 2px solid #006EDF;
  border-radius: 8px;
  &:hover {
    background-color: #0056b3;
  }
`;

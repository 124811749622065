import styled from "styled-components";
import { OverHeroContainer } from "App/styles";
import { colors, breakpoints } from "definitions";

export const Container = styled(OverHeroContainer)`
  background-color: ${colors.background.white};
  align-items: center;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  flex-direction: column;
  min-height: 200px;
  width: 95%;
  padding: 25px;

  .field-input-group {
    width: 100%;
  }
`;

export const Main = styled.div`
  display: flex;

  width: 100%;
  border-radius: 6px 6px 0px 0px;

  margin-bottom: 15px;

  label {
    padding-top: 0px;
    margin: 0;
  }
`;
export const Footer = styled.div`
  display: flex;

  width: 100%;
  border-radius: 0px 0px 6px 6px;

  align-items: flex-end;
  justify-content: space-between;

  .field-input-group {
    width: 25%;
  }

  ${breakpoints.tablet} {
    flex-direction: column;
  }

  ${breakpoints.phablet} {
    .field-input-group {
      width: 50%;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .field-input-group {
    width: 45%;
  }

  ${breakpoints.phablet} {
    flex-direction: column;

    .field-input-group {
      width: 100%;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 10px;

  ${breakpoints.tablet} {
    margin-top: 2rem;
  }
`;

export const ContainerField = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;

  .checkbox-field {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  ${breakpoints.tablet} {
    justify-content: space-between;

    .checkbox-field {
      flex-basis: 70%;
      margin-left: 2rem;
    }
  }

  ${breakpoints.mobile} {
    flex-direction: column;
    align-items: flex-start;

    .checkbox-field {
      margin-top: 2rem;
      margin-left: 0;
    }
  }
`;

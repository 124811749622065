import React from 'react';

import { Container, MainSection, CardMainText, CardSecondaryText, Details } from 'basics/cards';
import { FieldSeparator } from 'App/styles';
import ItemCardToolBar from 'components/ItemCardToolBar';

import UserIcon from 'icons/entities/UserIcon';

export default function CeoCard({ item, selected, onSelectEditedItem, onSelectDeletedItem, onCleanItemId }) {

	return (
		<Container selected={selected} style={{height: 'auto', padding: '3rem'}}>
			<MainSection style={{marginLeft: '0'}}>

				<UserIcon />

				<Details>
					<CardMainText>{item.lastName}, {item.title}</CardMainText>
					<FieldSeparator />
					<CardSecondaryText weight="bold">{item.email}</CardSecondaryText>
				</Details>

			</MainSection>

			<ItemCardToolBar id={item.id} actived={item.actived} selected={selected} onCleanItemId={onCleanItemId} onSelectEditedItem={onSelectEditedItem} onSelectDeletedItem={onSelectDeletedItem} />
		</Container>
	);
}

import React from 'react';

import { FieldSeparator } from 'App/styles';

import { Container, MainSection, CardMainText, CardSecondaryText, Details } from 'basics/cards';

import {ColorField} from 'basics';

import ItemCardToolBar from 'components/ItemCardToolBar';

import LogoIcon from 'icons/entities/LogoIcon';


export default function SponsorCard({ item, selected, onSelectEditedItem, onSelectDeletedItem, onCleanItemId }) {

	return (
		<Container selected={selected}>
			<MainSection>
				<LogoIcon />
				<Details>
					<CardMainText>{item.title}</CardMainText>
					<FieldSeparator />
					<ColorField value={item.color} />
					<FieldSeparator />
					<CardSecondaryText>Posee {item.logos.length} logos</CardSecondaryText>
				</Details>
			</MainSection>

			<ItemCardToolBar id={item.id} actived={item.actived} selected={selected} onCleanItemId={onCleanItemId} onSelectEditedItem={onSelectEditedItem} onSelectDeletedItem={onSelectDeletedItem} />
		</Container>
	);
}

import { OverHeroContainer } from "App/styles";
import { breakpoints } from "definitions";
import styled from "styled-components";

export const DescriptionContainer = styled(OverHeroContainer)`
  max-width: 124.5rem;
  width: 90%;
  justify-content: space-between;
  margin: 4.6rem 0;

  display: flex;
  flex-direction: column;

  font-size: 18px;
  line-height: 3rem;

  ${breakpoints.tablet} {
    font-size: 16px;
    line-height: 3rem;
  }

  ${breakpoints.mobile} {
    font-size: 14px;
    line-height: 2rem;
  }
`;

export const Image = styled.img`
  border-radius: 50px;
`;

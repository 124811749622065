import React from "react";
import styled from "styled-components";
import { colors, breakpoints } from "definitions";
import { Link } from "wouter";
import CessiHero from "icons/logos/CessiHero";
import heroImage from "assets/hero-image.webp";
import heroImageMobile from "assets/hero-image-mobile.webp";

export const HeroContainer = styled.div`
  height: 60rem;
  display: flex;
  z-index: 0;
  position: relative;
  background-color: ${colors.background.gray};
  flex-direction: column;
  align-items: center;
  display: flex;

  justify-content: center;

  height: ${(props) => (props.isHome ? "75vh" : "20rem")};

  background-position: center;

  ${breakpoints.phablet} {
    height: ${(props) => (props.isHome ? "75vh" : "15rem")};
  }
`;

export const HeroLogo = styled.img`
  width: ${(props) => props.width};
  ${breakpoints.tablet} {
    width: ${(props) => props.mobileWidth};
  }
`;

export const HeroToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 3rem;
  z-index: 1000;
  margin: 5rem 0;
  ${breakpoints.phablet} {
    width: 100%;

    flex-direction: column;
  }
`;
export const HeroContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  height: 75%;

  gap: 2rem;

  ${breakpoints.phablet} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    height: auto;
    width: 100%;
  }
`;

export const LogosContent = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 4rem;
  ${breakpoints.phablet} {
    grid-template-columns: 1fr;
  }
`;

/*
export const LogosContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  width: 100%;
  gap: 10rem;

  ${breakpoints.phablet} {
    flex-direction: column;
    gap: 4rem;
  }
`;

*/
export const LogosCessiContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  gap: 2rem;

  ${breakpoints.phablet} {
    flex-direction: column;
  }
`;

export const LogosOrganizationContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  gap: 2rem;

  ${breakpoints.phablet} {
    flex-direction: column;
  }
`;

export const HeroContentSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  align-items: center;
  justify-items: center;
  height: 100%;
  max-width: 124.4rem;
  gap: 2rem;
  margin: 45px;
  z-index: 1000;
  ${breakpoints.laptop} {
    grid-template-columns: 1fr;
    height: auto;
    width: 100%;
  }
`;

export const HeroBackground = styled((props) => (
  <img alt="Fondo de banner" {...props} />
))`
  position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;
  opacity: 0.1;
`;

export const HeroTitle = styled.h1`
  font-size: 4rem;
  color: ${colors.fonts.white};
  line-height: 5.1rem;
  font-weight: bold;
  font-style: normal;
  margin-top: 1.582rem;
  margin-bottom: 00.7rem;
  text-align: center;
  z-index: 1000;
  width: 100%;

  ${breakpoints.laptop} {
    font-size: 2rem;
    color: ${colors.fonts.white};
    line-height: 2.7rem;
    font-weight: bold;
    font-style: normal;
    margin-top: 1.582rem;
    margin-bottom: 00.7rem;
    height: auto;
    min-height: 7.4rem;
    text-align: center;
  }
`;

export const HeroSubTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${colors.fonts.white};
  margin: 3rem 0px;
  font-weight: medium;
  font-size: 3rem;
  line-height: 3.4rem;

  ${breakpoints.phablet} {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }
`;

export const SubtitleLogo = styled.h3`
  color: ${colors.fonts.white};
  margin: 3rem 0px;
  font-weight: medium;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-top: 0px;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
`;
export const CessiLogo = styled(CessiHero)`
  width: 500px;
  height: 229px;

  ${breakpoints.phablet} {
    width: 300px;
  }

  ${breakpoints.mobile} {
    width: 100px;
  }
`;

export const HeroExternalButton = styled.a`
  width: 250px;
  height: 40px;
  min-height: 63px;

  border: none;
  border-radius: 50px;

  cursor: pointer;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  background-color: ${colors.icons.white};
  color: ${colors.fonts.darkGray};

  text-decoration: none;

  padding: 2rem 4rem;

  background-image: -webkit-linear-gradient(
    45deg,
    #ffffff 50%,
    transparent 50%
  );
  background-image: linear-gradient(
    45deg,
    ${colors.hover.yellow} 50%,
    transparent 50%
  );
  background-position: 100%;
  background-size: 400%;

  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;

  z-index: 100;

  :hover {
    background-color: white;
    background-position: 0;
  }

  svg {
    margin-right: 10px;
  }

  :disabled {
    background-color: ${colors.background.gray};
    cursor: not-allowed;
  }
`;

export const HeroEntryButton = styled(({ hide, ...props }) => (
  <Link {...props} />
))`
  width: auto;
  height: 40px;
  min-height: 63px;

  border: none;
  border-radius: 50px;

  cursor: pointer;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.icons.white};
  color: ${colors.fonts.darkGray};

  text-decoration: none;

  padding: 2rem 8rem;

  background-image: -webkit-linear-gradient(
    45deg,
    #ffffff 50%,
    transparent 50%
  );
  background-image: linear-gradient(
    45deg,
    ${colors.hover.yellow} 50%,
    transparent 50%
  );
  background-position: 100%;
  background-size: 400%;

  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;

  z-index: 100;

  :hover {
    background-color: white;
    background-position: 0;
  }

  svg {
    margin-right: 10px;
  }

  :disabled {
    background-color: ${colors.background.gray};
    cursor: not-allowed;
  }
`;

import React from "react";
import { Link } from "wouter";
import { ComponentTitle } from "App/styles";

export default function History() {
  return (
    <>
      <Link to="/ediciones-anteriores">
        <ComponentTitle style={{ cursor: "pointer" }}>
          Ver Ediciones Anteriores
        </ComponentTitle>
      </Link>
    </>
  );
}

import * as React from "react"
import { icons } from 'definitions';

export default function LogoIcon(props) {
  return (
    <svg height={icons.entities.height} width={icons.entities.width} fill={icons.entities.color} viewBox="0 0 512 512" {...props}>
      <path d="M0 61v390h512V61H0zm482 360H51.213L155 317.213l61 61 180-180 86 86V421zm0-179.213l-86-86-180 180-61-61-125 125V91h452v150.787z" />
      <path d="M155 121c-33.084 0-60 26.916-60 60s26.916 60 60 60 60-26.916 60-60-26.916-60-60-60zm0 90c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30z" />
    </svg>
  )
}

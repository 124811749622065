import { FieldGroup, FieldLabel, ErrorMessage } from "basics/styles";
import React, { useState } from "react";
import { Faces } from "./styles";
import SadFaceIcon from "icons/SadFaceIcon";
import FaceIcon from "icons/FaceIcon";
import HappyFaceIcon from "icons/HappyFaceIcon";
import { colors } from "definitions";

const faces = [
  {
    id: 1,
    Icon: SadFaceIcon,
  },
  {
    id: 2,
    Icon: FaceIcon,
  },
  {
    id: 3,
    Icon: HappyFaceIcon,
  },
];
export default function EvaluationRate({ error, register, id }) {
  const [faceValue, setFaceValue] = useState();

  return (
    <FieldGroup>
      <FieldLabel style={{ textAlign: "center" }}>
        Cómo te resultó realizar esta autoevaluación?
      </FieldLabel>

      <Faces>
        <input
          type="hidden"
          ref={register}
          id={id}
          name={id}
          value={faceValue}
        />

        {faces.map(({ id, Icon }) => (
          <Icon
            onClick={() => setFaceValue(id)}
            fill={
              id === faceValue
                ? colors.background.yellowGreen
                : colors.background.lightBlue
            }
            key={id}
          />
        ))}
      </Faces>
      {error && (
        <ErrorMessage style={{ textAlign: "center", marginTop: "0.5rem" }}>
          {error.message}
        </ErrorMessage>
      )}
    </FieldGroup>
  );
}

import { colors } from "definitions";

export const Roles = {
  administrator: "Administrador",
  judge: "Juez",
  selector: "Seleccionador",
};

export const Sizes = {
  BIG: "big",
  MEDIUM: "medium",
  SMALL: "small",
};

export const FormStatus = {
  PREVIEW: "preview",
  DRAFT: "draft",
  CONFIRMED: "confirmed",
  DISCARDED: "discarded",
  FINALIST: "finalist",
  WINNER: "winner",
};

export const MessageType = {
  DANGER: `${colors.background.red}`,
  WARNING: `${colors.background.yellow}`,
  SUCESSFULL: `${colors.background.green}`,
  WAITING: `${colors.background.white}`,
};

export const UserRoles = {
  administrator: false,
  judge: false,
  selector: false,
  ceo: false,
  press: false,
};

export const SelectionMode = {
  SIMPLE: "simple",
  MULTIPLE: "multiple",
};

export const EntryListingMode = {
  CRITERIA: "criteria",
  VOTING: "voting",
  FINALISTS: "finalists",
  WINNERS: "winners",
  ADMINISTRATION: "administration",
};

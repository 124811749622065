import React from "react";
import { BodyContainer, HeaderSectionEntriesContainer } from "App/styles";
import { MainTitle } from "components/MainTitle";
import { ComponentTitle } from "App/styles";
import {
  Container,
  GalleryContainer,
  SectionContainer,
  SectionItem,
  SectionTitle,
  FinalistItemHeader,
  FinalistItem,
  FinalistItemBody,
  FinalistItemTitle,
  FinalistItemContent,
  MentionBody,
} from "./styles";
import { ComponentContainer } from "App/styles";
import YearsCarousel from "components/YearsCarousel";
import useEditions from "hooks/useEditions";
import { Slideshow, Slide } from "components/Slideshow";
import Logo from "assets/short-logo.png";
import ReactMarkdown from "react-markdown";

export default function History() {
  const { yearSelected, setYearSelected, winners, mentions, images } =
    useEditions();

  return (
    <BodyContainer>
      <MainTitle title="Ediciones" seoTitle="Ediciones" />

      <HeaderSectionEntriesContainer>
        <YearsCarousel
          yearSelected={yearSelected}
          onYearSelected={setYearSelected}
        />
      </HeaderSectionEntriesContainer>

      <Container>
        <Winners items={winners} />
        <Mentions items={mentions} />
        <Gallery images={images} />
      </Container>
    </BodyContainer>
  );
}

const Winners = ({ items }) => {
  if (items.length === 0) {
    return <></>;
  }
  return (
    <SectionContainer>
      <ComponentTitle>Finalistas</ComponentTitle>
      {(items || []).map((item) => (
        <SectionItem key={item.name}>
          <SectionTitle>
            <p>{item.name}</p>
          </SectionTitle>
          <ComponentContainer>
            {item.value.map((value) => (
              <FinalistItem key={value.id}>
                <FinalistItemHeader winner={value.winner} />
                <FinalistItemBody>
                  <FinalistItemTitle>EMPRESA / INSTITUCIÓN</FinalistItemTitle>
                  <FinalistItemContent>{value.company}</FinalistItemContent>
                  <FinalistItemTitle>INICIATIVA</FinalistItemTitle>
                  <FinalistItemContent>{value.project}</FinalistItemContent>
                </FinalistItemBody>
              </FinalistItem>
            ))}
          </ComponentContainer>
        </SectionItem>
      ))
      }
    </SectionContainer >
  );
};

const Mentions = ({ items }) => {
  if (items.length === 0) {
    return <></>;
  }

  return (
    <SectionContainer>
      <ComponentTitle>Menciones</ComponentTitle>
      {(items || []).map((item) => (
        <SectionItem key={item.id}>
          <SectionTitle>
            <p>{item.title}</p>
          </SectionTitle>
          <MentionBody>
            <ReactMarkdown>{item.details}</ReactMarkdown>
          </MentionBody>
        </SectionItem>
      ))}
    </SectionContainer>
  );
};

const Gallery = ({ images }) => {
  if ((images || []).length === 0) {
    return <></>;
  }

  return (
    <>
      <ComponentTitle>Galeria</ComponentTitle>
      <GalleryContainer>
        <Slideshow
          controls={true}
          autoplay={false}
          velocity="2000"
          interval="5000"
        >
          {(images || []).map((image) => (
            <Slide key={image.name}>
              <img src={image.url} alt={image.name} />
            </Slide>
          ))}
        </Slideshow>
      </GalleryContainer>
    </>
  );
};

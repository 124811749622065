import React, { useState, useContext } from "react";
import { LoginContainer } from "./styles";
import { useLocation } from "wouter";

import CloseIcon from "icons/CloseIcon";

import { AuthContext } from "contexts/AuthContext";
import useAuth from "hooks/useAuth";

import LoginForm from "components/LoginForm";

import LoginIcon from "icons/LoginIcon";
import LoginoutIcon from "icons/LoginoutIcon";

export default function Login() {
  const [showLogin, setShowLogin] = useState(false);

  const { userLogged } = useContext(AuthContext);
  const { signOut } = useAuth();
  const [, navigate] = useLocation();

  const HandleLogin = () => {
    setShowLogin(true);
  };

  const HandleLogout = () => {
    signOut()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const HandleCloseForm = () => {
    setShowLogin(false);
  };

  return (
    <LoginContainer>
      {showLogin ? (
        <CloseIcon
          width={24}
          height={24}
          style={{ paddingRight: "10px" }}
          onClick={HandleCloseForm}
        />
      ) : !userLogged ? (
        <LoginIcon onClick={HandleLogin} />
      ) : (
        <LoginoutIcon onClick={HandleLogout} />
      )}
      <LoginForm onClose={HandleCloseForm} show={showLogin} />
    </LoginContainer>
  );
}

import styled from "styled-components";

import { colors, breakpoints } from "definitions";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  ${breakpoints.phablet} {
    flex-direction: Column;
  }
`;

export const DropZone = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  height: 15rem;
  padding: 25px;

  ${breakpoints.phablet} {
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${breakpoints.phablet} {
    justify-content: space-between;
  }
`;

export const Legend = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  margin: 0 auto;

  color: ${({ mode }) => (mode === "dark" ? "white" : colors.fonts.darkGray)};

  ${breakpoints.tabletXL} {
    text-align: center;
  }

  ${breakpoints.phablet} {
    font-size: 1.8rem;
  }
`;

export const Files = styled.ul`
  list-style: none;
  padding-left: 0;
`;

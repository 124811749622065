import styled from "styled-components";
import { breakpoints, colors } from "definitions";

export const Container = styled.section`
  max-width: 124.5rem;
  height: auto;
  width: 100%;
  border-radius: 50px;
  padding: 0px 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
`;

export const SponsorsTitle = styled.h2`
font-family: Lato;
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
align-self: stretch;
color: #000000;
`;

export const EntryItem = styled.div`
position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  
  gap: 2.5rem;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 12px 22px -4px rgba(12, 12, 13, 0.10);

  
  ${breakpoints.phablet} {
    
    margin: 0 auto;
  }
`;

export const Category = styled.div`
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  display: flex;
  width: 260px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #E5E5EA;
  background: #FFF;
  position: absolute;
  left: 40px;

  top: -17px;
    ${breakpoints.phablet} {
     left: 20px;
    }
`;

export const Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;


  width: 100%;
  z-index: 1;
  padding-top: ${(props) =>
    props.size === "big"
      ? "4.10rem"
      : props.size === "medium"
        ? "3.10rem"
        : "2.10rem"};
  padding-bottom: ${(props) =>
    props.size === "big"
      ? "4.10rem"
      : props.size === "medium"
        ? "3.10rem"
        : "2.10rem"};
  padding-left: auto;
  padding-right: auto;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

import styled from "styled-components";

import { colors, breakpoints } from "definitions";

import DeleteIcon from "icons/cards/DeleteIcon";
export const Container = styled.div`
  background-color: #d8d8d8;
  position: relative;
  margin: 15px 0;
  height: 60px;
  width: 100%;

  ${breakpoints.phablet} {
    margin: 0;
  }
`;

export const ProgressDone = styled.div`
  background: ${(props) =>
    props.percent === 100
      ? `${colors.icons.yellowGreen}`
      : "linear-gradient(to left, #666666, #1F4996)"};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
`;

export const Link = styled.a`
  margin-left: 2rem;
  width: auto;
  position: absolute;
  text-decoration: none;

  color: ${({ mode }) =>
    mode === "dark" ? `${colors.icons.gray}` : `${colors.icons.white}`};

  :hover {
    color: ${({ mode }) =>
      mode === "dark" ? `${colors.icons.gray}` : `${colors.icons.white}`};
  }

  :visited {
    color: ${({ mode }) =>
      mode === "dark" ? `${colors.icons.gray}` : `${colors.icons.white}`};
  }
`;

export const DeleteButton = styled(DeleteIcon)`
  width: 32px;
  height: 32px;
  position: absolute;
  fill: ${colors.fonts.white};
  right: 20px;
`;

import styled from 'styled-components';
import { breakpoints } from 'definitions';

export const Container = styled.div `
	flex-basis: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

	a {
		margin-top: 10px;
	}
	
	${breakpoints.tablet}{
		flex-direction: column-reverse;
    	align-items: center;

		button {
			width: 100%;
		}
	}
`

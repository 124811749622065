import React, { useState, useEffect } from "react";
import { firebaseDB } from "firebase/client";

export const CompetitionContext = React.createContext({});

export function CompetitionProvider({ children }) {
  const [currentCompetition, setCurrentCompetition] = useState();

  useEffect(() => {
    firebaseDB
      .collection("competitions")
      .where("title", "==", `${new Date().getFullYear()}`)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          setCurrentCompetition({ ...doc.data(), id: doc.id });
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const currentCompetitionOpened =
    currentCompetition &&
    Date.parse(currentCompetition.entryStage) <= Date.now() &&
    Date.parse(currentCompetition.selectionStage) >= Date.now();

  const currentCompetitionHasBeenEnded =
    currentCompetition &&
    Date.parse(currentCompetition.selectionStage) >= Date.now();

  return (
    <CompetitionContext.Provider
      value={{
        currentCompetition,
        currentCompetitionOpened,
        currentCompetitionHasBeenEnded,
      }}
    >
      {children}
    </CompetitionContext.Provider>
  );
}

import React from 'react';

import { FieldSeparator } from 'App/styles';

import { Container, MainSection, CardMainText, CardSecondaryText, Details } from 'basics/cards';

import ItemCardToolBar from 'components/ItemCardToolBar';

import TopicIcon from 'icons/entities/TopicIcon';

export default function CriteriaCard({ item, selected, onSelectEditedItem, onSelectDeletedItem, onCleanItemId, onReactivateItem }) {

	return (
		<Container selected={selected}>
			<MainSection>
				<TopicIcon />
				<Details>
					<CardMainText>{item.title}</CardMainText>
					<FieldSeparator />
					<CardSecondaryText weight="bold">{item.ponderation}</CardSecondaryText>
				</Details>
			</MainSection>

			<ItemCardToolBar id={item.id} actived={item.actived} selected={selected} onCleanItemId={onCleanItemId} onSelectEditedItem={onSelectEditedItem} onSelectDeletedItem={onSelectDeletedItem} onReactivateItem={onReactivateItem}/>

		</Container>
	);
}

import React from "react";

import {
  Container,
  MainSection,
  CardMainText,
  Details,
  CardSecondaryText,
} from "basics/cards";

import { FieldSeparator } from "App/styles";

import ItemCardToolBar from "components/ItemCardToolBar";

import LogoIcon from "icons/entities/LogoIcon";

export default function MentionCard({
  item,
  selected,
  onSelectEditedItem,
  onSelectDeletedItem,
  onCleanItemId,
  onReactivateItem,
}) {
  return (
    <Container selected={selected}>
      <MainSection>
        <LogoIcon />
        <Details>
          <CardMainText>{item.title}</CardMainText>
          <FieldSeparator />
          <CardSecondaryText weight="bold">{item.year}</CardSecondaryText>
        </Details>
      </MainSection>

      <ItemCardToolBar
        id={item.id}
        actived={item.actived}
        selected={selected}
        onCleanItemId={onCleanItemId}
        onSelectEditedItem={onSelectEditedItem}
        onSelectDeletedItem={onSelectDeletedItem}
        onReactivateItem={onReactivateItem}
      />
    </Container>
  );
}

import { useState, useCallback, useEffect, useContext } from "react";

import { firebaseDB, getDateTimeNow } from "firebase/client";

import { capitalize, sortByProperty, sortDescByProperty, sleep } from "helpers";

import useFirestore from "hooks/useFirestore";

import { CompetitionContext } from "contexts/CompetitionContext";

import { AuthContext } from "contexts/AuthContext";

import { AlertContext } from "contexts/AlertContext";

import {
  SelectionMode,
  EntryListingMode,
  MessageType,
  FormStatus,
} from "model/dictionary";

import useEntryForm from "hooks/useEntryForm";

export default function useEntries(
  { mode, formStatus, selectionMode } = {
    mode: "",
    formStatus: "",
    selectionMode: SelectionMode.Simple,
  }
) {
  const { currentUser } = useContext(AuthContext);

  const { showWaiting, showAlert, hideWaiting } = useContext(AlertContext);

  const { currentCompetition } = useContext(CompetitionContext);

  const { getItem, getAllItems } = useFirestore();

  const [loading, setLoading] = useState(false);

  const [awards, setAwards] = useState([]);

  const [awardSelected, setAwardSelected] = useState();

  const [entries, setEntries] = useState([]);

  const [filter, setFilter] = useState([]);

  const [emptyMessage, setEmptyMessage] = useState(
    "Debe seleccionar un premio para poder visualizar postulaciones"
  );

  const [entriesSelected, setEntriesSelected] = useState([]);

  const { UpdateForm } = useEntryForm();

  const resetFilter = useCallback(() => {
    setFilter([]);

    setEntriesSelected([]);

    setAwardSelected("");

    setEntries([]);
  }, []);

  const rollbackStatus = useCallback(
    async (path, entryId, newStatus) => {
      const result = await getItem("entries", entryId);

      if (result.exists) {
        await UpdateForm(result.item, newStatus);

        await firebaseDB.collection(path).doc(entryId).delete();
      }
    },
    [UpdateForm, getItem]
  );

  const restoreStatus = useCallback(
    async (newStatus) => {
      if (!(awardSelected && currentCompetition)) {
        return { exists: false };
      }

      showWaiting("Actualizando postulaciones seleccionadas...");

      const collection =
        newStatus === FormStatus.FINALIST ? "winners" : "finalists";

      const path = `competitions/${currentCompetition.id}/${collection}/${awardSelected}/entries`;

      const snapshot = await firebaseDB.collection(path).get();

      var promises = [];

      promises = snapshot.docs.map(async (doc) => {
        await rollbackStatus(path, doc.id, newStatus);
      });

      Promise.all(promises)
        .then(() => {
          showAlert(
            MessageType.SUCESSFULL,
            "Actualización satisfactoria",
            2000,
            resetFilter()
          );
        })
        .catch((error) => {
          showAlert(MessageType.DANGER, `Se produjo un error ${error}`, 2000);
        });
    },
    [
      awardSelected,
      currentCompetition,
      showWaiting,
      rollbackStatus,
      showAlert,
      resetFilter,
    ]
  );

  const updateCompetition = useCallback(
    async (status, entryId) => {
      const collection =
        status === FormStatus.FINALIST ? "finalists" : "winners";

      await firebaseDB
        .collection(
          `competitions/${currentCompetition.id}/${collection}/${awardSelected}/entries`
        )
        .doc(entryId)
        .set({ createdAt: getDateTimeNow() });
    },
    [awardSelected, currentCompetition]
  );

  const confirmChangeStatus = useCallback(
    async (newStatus) => {
      showWaiting("Confirmando postulaciones seleccionadas...");

      await sleep(2000);

      var promises = [];

      entriesSelected.forEach((entryId) => {
        promises.push(
          getItem("entries", entryId).then(async (result) => {
            if (result.exists) {
              UpdateForm(result.item, newStatus);
            }

            await updateCompetition(newStatus, entryId);
          })
        );
      });

      Promise.all(promises)
        .then(() => {
          showAlert(
            MessageType.SUCESSFULL,
            "Actualización satisfactoria",
            2000,
            resetFilter()
          );
        })
        .catch((error) => {
          showAlert(MessageType.DANGER, `Se produjo un error ${error}`, 2000);
        });
    },
    [
      showWaiting,
      entriesSelected,
      getItem,
      updateCompetition,
      UpdateForm,
      showAlert,
      resetFilter,
    ]
  );

  const onSelected = (entryId) => {
    if (selectionMode === SelectionMode.Simple) {
      setEntriesSelected([entryId]);
    } else {
      setEntriesSelected((prevIds) => [...prevIds, entryId]);
    }
  };

  const onCleanSelected = (entryId, reset = false) => {
    if (selectionMode === SelectionMode.Simple) {
      setEntriesSelected([]);
    } else {
      setEntriesSelected((prevIds) => prevIds.filter((id) => id !== entryId));
    }

    if (reset) {
      resetEntries();
    }
  };

  const isSelected = (entryId) => {
    return entriesSelected.filter((id) => id === entryId).length > 0;
  };

  const areEntriesSelected = () => {
    return entriesSelected.length > 0;
  };

  const updateFilter = (field, operator, value) => {
    let newFilter = filter.filter((item) => {
      return item.field !== field;
    });

    if (value !== "") {
      newFilter = [
        ...newFilter,
        { field: field, operator: operator, value: value },
      ];
    }

    setAwardSelected(value);

    setFilter(newFilter);

    setEntries([]);
  };

  const loadAwards = useCallback(async () => {
    setLoading(false);

    var collection = firebaseDB.collection("topics");

    let query = collection.where("actived", "==", true).orderBy("title");

    const topicSnapshot = await query.get();

    let userAwards = [];

    if (mode === EntryListingMode.VOTING) {
      const user = await getItem("users", currentUser.uid);

      userAwards = user.item.awards ?? [];
    }

    topicSnapshot.docs.forEach(async (topic) => {
      const awardSnapshot = await firebaseDB
        .collection(`topics/${topic.id}/awards`)
        .where("actived", "==", true)
        .orderBy("title")
        .get();

      const items = awardSnapshot.docs.map((award) => {
        return {
          value: award.id,
          label: capitalize(`${award.data().title} (${topic.data().title})`),
        };
      });

      setAwards((prevAwards) =>
        prevAwards
          .concat(
            items.filter(
              (item) =>
                userAwards.length === 0 ||
                userAwards.filter((ua) => ua === item.value).length > 0
            )
          )
          .sort(sortByProperty("label"))
      );
    });
  }, [currentUser, getItem, mode]);

  const updateCriteria = useCallback(
    (fields, entryId) => {
      setLoading(true);

      let ponderation = 0;

      fields.criteria.forEach((item) => {
        ponderation += item.ponderation * item.value;
      });

      let promise = new Promise((resolve, reject) => {
        firebaseDB
          .collection(`entries/${entryId}/criteria`)
          .doc(currentUser.uid)
          .set({
            ...fields,
            modifiedAt: getDateTimeNow(),
            ponderation: ponderation,
          })
          .then(function (doc) {
            resolve(doc);
          })
          .catch(function (error) {
            reject(error);
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
            showAlert(
              MessageType.SUCESSFULL,
              "Actualización satisfactoria",
              2000
            );
          });
      });

      return promise;
    },
    [currentUser, showAlert]
  );

  const getUserVote = useCallback(
    async (awardId) => {
      const vote = await firebaseDB
        .collection(
          `users/${currentUser.uid}/votes/${currentCompetition.id}/awards`
        )
        .doc(awardId)
        .get();

      return [vote.exists, { ...vote.data(), id: vote.id }];
    },
    [currentCompetition, currentUser]
  );

  const updateVote = useCallback(
    async (entryId, awardId) => {
      setLoading(true);

      await firebaseDB
        .collection(`entries/${entryId}/votes`)
        .doc(currentUser.uid)
        .set({
          modifiedAt: getDateTimeNow(),
          awardId,
          userId: currentUser.uid,
          year: currentCompetition.title,
        });

      await firebaseDB
        .collection(
          `users/${currentUser.uid}/votes/${currentCompetition.id}/awards`
        )
        .doc(awardId)
        .set({
          modifiedAt: getDateTimeNow(),
          entryId,
          year: currentCompetition.title,
        });

      setLoading(false);

      showAlert(MessageType.SUCESSFULL, "Voto registrado", 2000);
    },
    [currentCompetition, currentUser, showAlert]
  );

  const getCriteria = useCallback(async () => {
    return await getAllItems("criteria");
  }, [getAllItems]);

  const updateEntryWithCriteria = useCallback(
    async (entry) => {
      const criteria = { users: 0, total: 0 };

      const criteriaItems = await getAllItems(`entries/${entry.id}/criteria`);

      criteriaItems.forEach((item) => {
        criteria.users += 1;
        criteria.total += item.ponderation;
      });

      return {
        ...entry.data(),
        id: entry.id,
        users: criteria.users,
        ponderation: criteria.total,
      };
    },
    [getAllItems]
  );

  const updateEntryWithVotes = useCallback(
    async (entry) => {
      const criteria = { users: 0, total: 0 };

      const voteItems = await getAllItems(`entries/${entry.id}/votes`);

      voteItems.forEach((item) => {
        criteria.users += 1;
        criteria.total += item.ponderation;
      });

      return { ...entry.data(), id: entry.id, votes: voteItems.length };
    },
    [getAllItems]
  );

  const getEntries = useCallback(async () => {
    showWaiting("Cargando las postulaciones...");

    let query = firebaseDB
      .collection("entries")
      .orderBy("createdAt", "desc")
      .where("actived", "==", true);

    query = query.where("competition.id", "==", currentCompetition.id);

    if (formStatus !== "") {
      query = query.where("currentStatus.type", "==", formStatus);
    }

    filter.forEach((item) => {
      query = query.where(item.field, item.operator, item.value);
    });

    const snapshots = await query.get();

    const docs = snapshots.docs;

    let items = [];

    if (mode === EntryListingMode.VOTING) {
      const [voteExists, entryVoted] = await getUserVote(filter[0].value);

      items = docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
          isVoted: voteExists && doc.id === entryVoted.entryId,
        };
      });

      if (voteExists) {
        items = items.sort(sortDescByProperty("isVoted"));
      }
    } else if (mode === EntryListingMode.FINALISTS) {
      items = await Promise.all(
        docs.map((doc) => updateEntryWithCriteria(doc))
      );

      items = items.sort(sortDescByProperty("ponderation"));
    } else if (mode === EntryListingMode.WINNERS) {
      items = await Promise.all(docs.map((doc) => updateEntryWithVotes(doc)));

      items = items.sort(sortDescByProperty("votes"));
    } else {
      items = docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    }

    setEntries(items);

    if (items.length === 0) {
      setEmptyMessage("No hay resultados que concuerden con la búsqueda.");
    }

    hideWaiting();
  }, [
    currentCompetition,
    filter,
    formStatus,
    getUserVote,
    hideWaiting,
    mode,
    showWaiting,
    updateEntryWithCriteria,
    updateEntryWithVotes,
  ]);

  const handleFilter = (field, value) => {
    updateFilter(field, "==", value);
  };

  useEffect(() => {
    loadAwards();
  }, [loadAwards]);

  const entriesInStatus = useCallback(
    async (entryMode) => {
      if (!(awardSelected && currentCompetition)) {
        return { exists: false };
      }

      const collection =
        entryMode === EntryListingMode.FINALISTS ||
        entryMode === EntryListingMode.CRITERIA
          ? "finalists"
          : "winners";

      const message =
        collection === "finalists"
          ? "Hay finalistas para el premio seleccionado"
          : "Ya hay un ganador para el premio seleccionado";

      const snapshot = await firebaseDB
        .collection(
          `competitions/${currentCompetition.id}/${collection}/${awardSelected}/entries`
        )
        .get();

      return { exists: snapshot.docs.length > 0, message };
    },
    [awardSelected, currentCompetition]
  );

  useEffect(() => {
    if (currentCompetition && filter.length > 0) {
      if (mode !== EntryListingMode.ADMINISTRATION) {
        entriesInStatus(mode).then((result) => {
          if (result.exists) {
            setEmptyMessage(result.message);
          } else {
            getEntries();
          }
        });
      }
    } else {
      setEmptyMessage(
        "Debe seleccionar un premio para poder visualizar postulaciones"
      );
    }
  }, [filter, currentCompetition, getEntries, entriesInStatus, mode]);

  const resetEntries = async () => {
    await getEntries();
  };

  return {
    loading,
    entries,
    awards,
    awardSelected,
    loadAwards,
    handleFilter,
    updateCriteria,
    updateVote,
    getCriteria,
    resetFilter,
    emptyMessage,
    resetEntries,
    onSelected,
    onCleanSelected,
    isSelected,
    confirmChangeStatus,
    areEntriesSelected,
    entriesInStatus,
    restoreStatus,
  };
}

import React from "react";

export default function EmailIcon(props) {
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={800}
      height={800}
      fill="#006EDF"
      viewBox="0 0 485.638 485.638"
      {...props}
    >
      <path d="M472.689 156.996 328.642 12.949c-17.266-17.266-45.353-17.266-62.616 0L12.948 266.024c-17.264 17.266-17.264 45.353 0 62.617L156.995 472.69c17.266 17.264 45.354 17.265 62.617 0l253.076-253.076c17.266-17.266 17.264-45.354.001-62.618zM183.546 455.21c-2.064-.707-4.031-1.745-5.68-3.393L33.819 307.771c-.039-.041-.048-.092-.09-.133l149.816-.001V455.21zM42.601 278.118 278.122 42.596l.002 229.085c-.002 3.444-2.998 6.442-6.443 6.442l-229.08-.005zm170.459 159.38v-129.86h58.623c9.609 0 18.64-3.741 25.427-10.528 6.788-6.788 10.527-15.817 10.528-25.428V213.06l129.854.005L213.06 437.498zm94.577-253.953V33.729c.04.041.093.05.134.091l144.047 144.047c1.646 1.647 2.685 3.613 3.388 5.683l-147.569-.005zM14.382 62.57h151.154c7.941 0 14.381-6.438 14.381-14.38 0-7.942-6.439-14.381-14.381-14.381H14.382C6.439 33.809.001 40.247.001 48.19c0 7.942 6.438 14.38 14.381 14.38zM14.382 119.065h94.784c7.942 0 14.38-6.438 14.38-14.381 0-7.941-6.438-14.38-14.38-14.38H14.382c-7.943 0-14.381 6.438-14.381 14.38 0 7.943 6.438 14.381 14.381 14.381zM14.382 173.849h61.197c7.941 0 14.381-6.438 14.381-14.381s-6.439-14.381-14.381-14.381H14.382c-7.943 0-14.381 6.439-14.381 14.381s6.438 14.381 14.381 14.381zM75.579 438.278H14.382c-7.943 0-14.381 6.439-14.381 14.381s6.438 14.381 14.381 14.381h61.197c7.941 0 14.381-6.438 14.381-14.381 0-7.942-6.439-14.381-14.381-14.381z" />
    </svg>
  );
}

EmailIcon.defaultProps = {
  height: "4rem",
  width: "3rem",
  cursor: "pointer",
};

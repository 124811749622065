import { breakpoints } from "definitions";
import styled from "styled-components";
import { Button } from "basics/buttons";
import { HeaderSectionContainer } from "App/styles";

export const ContainerAnimated = styled.div`
  width: 95%;
  max-width: 124.5rem;

  ${breakpoints.tablet} {
    width: 80%;
  }
`;

export const SectionContainer = styled(HeaderSectionContainer)`
  flex-direction: column;
  align-items: center;
  height: auto;

  .field-input-group {
    flex-basis: 100%;
    width: 100%;
  }
`;

export const Btn = styled(Button)`
  margin: 0 1.5rem;
  width: 100%;

  :nth-of-type(2) {
    margin: 0;

    ${breakpoints.laptop} {
      margin-top: 25px;
    }
  }

  ${breakpoints.laptop} {
    margin: 0;
  }
`;

import React, { useState, useEffect } from "react";
import { Container, ProgressDone, Link, DeleteButton } from "./styles";
import { colors } from "definitions";
export default function ProgressBar({
  percent,
  name,
  url,
  onRemoveFile,
  readOnly,
  mode,
}) {
  const [style, setStyle] = useState({});

  useEffect(() => {
    setStyle({
      opacity: 1,
      width: `${percent}%`,
    });
  }, [percent]);

  return (
    <Container>
      <ProgressDone style={style} percent={percent} name={name}>
        <Link mode={mode} target="_blank" href={url}>
          {name}
        </Link>
        {!readOnly && (
          <DeleteButton
            onClick={() => {
              onRemoveFile(name);
            }}
            style={{
              fill: `${
                mode === "dark" ? colors.icons.gray : colors.icons.white
              }`,
            }}
          />
        )}
      </ProgressDone>
    </Container>
  );
}

import React, { useEffect } from 'react';
import styled from 'styled-components';


export default function Blur({ showModal }) {
    useEffect(
        () => {
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = 'unset';
            };
        },
        []
    );


    return (
        <BlurContainer showModal={showModal} />
    );
}

const BlurContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
	z-index: ${({ showModal }) => showModal ? "1002" : "-1002"};
    backdrop-filter: blur(4px);
`;

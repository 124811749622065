import React, { useEffect, useState } from 'react';

import { InputField, CheckBoxesField, SelectField } from 'basics';

import FileUploader from 'components/FileUploader';

import { Roles } from 'model/options';

import useFirestore from 'hooks/useFirestore';

export default function AwardForm({ register, setValue, errors, item }) {
	const [awardRoles, setAwardRoles] = useState([]);
	const [criteriaItems, setCriteriaItems] = useState([]);
	const {getItems} =  useFirestore();

	useEffect(() => {

		let roles = Roles;

		if (item && item.roles) {
			
			roles = [];

			Roles.forEach(i => {

				const rol = item.roles.filter(rol => rol.value === i.value);

				roles.push(rol.length > 0 ? rol[0] : i);
			});
		}

		setAwardRoles(roles);

		getItems({collection: 'criteria'}).then((result) => {
			setCriteriaItems(result.map((item) => {
				return {value: item.id, label: item.title}
			}));
		})

	}, [getItems, item]);

	return (
		<>
			<InputField
				label="Título"
				type="text"
				value={item && item.title}
				id="title"
				register={register}
				errors={errors}
				mode="dark"
				required={true}
			/>
			<InputField
				label="Descripción"
				type="text"
				value={item && item.description}
				id="description"
				register={register}
				errors={errors}
				mode="dark"
				required={true}
			/>

			<CheckBoxesField
				options={awardRoles}
				register={register}
				error={errors.roles}
				id="roles"
				label="Roles Asociados"
				mode="dark"
				twoLines={true}
				required={true}
			/>

			<SelectField
				options={criteriaItems}
				id="criteria"
				label="Criterios"
				value={item && item.criteria}
				placeholder="Elija los criterios"
				required={true}
				register={register}
				error={errors.criteria}
				mode="dark"
				multiple={true}
			/>

			<FileUploader
				label="PDF"
				id="pdf"
				value={item && item.pdf}
				folder={`awards`}
				register={register}
				error={errors.pdf}
				setValue={setValue}
				accepted="application/pdf"
				mode="dark"
				required={true}
			/>
		</>
	)
}
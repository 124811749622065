import React, { useState, useEffect, Fragment } from "react";

import { InputField, SelectField } from "basics";

import { QuestionTypes, Ponderation } from "model/options";
import { Caption } from "basics/styles";

export default function QuestForm({
  register,
  unregister,
  errors,
  setValue,
  item,
}) {
  const [questionType, setQuestionType] = useState(
    (item && item.questionType) || QuestionTypes[0].value
  );

  const HandleQuestionType = (field, value) => {
    setQuestionType(value);
    if (questionType === "ponderation") {
      register({ name: "options" });

      setValue(
        "options",
        Ponderation.map((option) => ({ ...option, comment: "" }))
      );
    } else {
      unregister("options");
    }
  };

  return (
    <Fragment>
      <InputField
        label="Pregunta"
        type="text"
        value={item && item.title}
        id="title"
        register={register}
        error={errors.title}
        mode="dark"
      />

      <InputField
        label="Ayuda"
        type="text"
        value={item && item.helpText}
        id="helpText"
        register={register}
        error={errors.helpText}
        mode="dark"
      />

      <InputField
        label="Orden"
        type="number"
        value={item && item.order}
        id="order"
        register={register}
        error={errors.order}
        mode="dark"
      />

      <SelectField
        options={QuestionTypes}
        id="questionType"
        label="Tipo de Pregunta"
        value={item && item.questionType}
        placeholder="Elija un tipo"
        onChange={HandleQuestionType}
        register={register}
        error={errors.questionType}
        mode="dark"
      />

      {questionType === "text" && (
        <InputField
          label="Cantidad de palabras"
          type="number"
          value={item && item.wordCounter}
          id="wordCounter"
          register={register}
          error={errors.wordCounter}
          mode="dark"
        />
      )}

      {questionType === "ponderation" && (
        <>
          <Caption mode="dark">Ayuda para las opciones</Caption>
          {Ponderation.map((option, index) => (
            <Fragment key={option.value}>
              <input
                type="hidden"
                name={`options[${index}].value`}
                value={option.value}
                ref={register}
              />
              <input
                type="hidden"
                name={`options[${index}].label`}
                value={option.label}
                ref={register}
              />
              <InputField
                label={option.label}
                type="text"
                id={`options[${index}].comment`}
                value={item && item.options && item.options[index].comment}
                register={register}
                mode="dark"
              />
            </Fragment>
          ))}
        </>
      )}
    </Fragment>
  );
}

import React from 'react';

import { BodyContainer } from 'App/styles';

import {MainTitle} from 'components/MainTitle';
import { BackToLinkButton } from 'basics/buttons';
import {Description} from './styles'

export default function Page404() {
    
    return (
		<React.Fragment>
			<BodyContainer>
				<MainTitle title="404" seoTitle="404"  />
				<Description>La página que estas buscando pudo haber sido removida o no existe.</Description>
				<BackToLinkButton to="/">Home</BackToLinkButton>
			</BodyContainer>

		</React.Fragment>
	);
}

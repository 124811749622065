import React from "react";
import AdminPage from "components/AdminPage";
import MentionToolbar from "crud/mentions/MentionToolbar";
import MentionCard from "crud/mentions/MentionCard";
import MentionForm from "crud/mentions/MentionForm";
import { object, string, boolean } from "yup";

export default function Mentions() {
  const schema = object({
    actived: boolean(),
    year: string().required("El año es obligatorio"),
    title: string().required("La mención es obligatoria"),
    details: string().required("El detalle es obligatorio"),
  });

  const sortOptions = [
    {
      value: "title",
      label: "Mención",
    },
  ];

  return (
    <AdminPage
      title="Menciones"
      seoTitle="Administración de las menciones"
      sortOptions={sortOptions}
      collection="mentions"
      schema={schema}
      ItemFormFields={MentionForm}
      ItemCard={MentionCard}
      ItemToolBar={MentionToolbar}
    />
  );
}

import * as React from "react";
import { icons, colors } from "definitions";

export default function LoginIcon(props) {
  return (
    <svg
      id="prefix__Icons"
      x={0}
      y={0}
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
      style={{
        ...props.style,
        cursor: "pointer",
        width: "28px",
        height: "28px",
        marginRight: "10px",
      }}
    >
      <title>Ingrese al sistema</title>
      <style>
        {`.prefix__st5{fill:none;stroke:${props.color};stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}`}
      </style>
      <circle className="prefix__st5" cx={16} cy={16} r={14} />
      <circle className="prefix__st5" cx={16} cy={13} r={5} />
      <path
        className="prefix__st5"
        d="M5.4 25.1c1.8-4.1 5.8-7 10.6-7s8.9 2.9 10.6 7"
      />
    </svg>
  );
}

LoginIcon.defaultProps = {
  height: `${icons.cards.height}`,
  width: `${icons.cards.width}`,
  color: `${colors.icons.gray}`,
};

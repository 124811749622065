import React, { useState, useEffect } from 'react';
import { Camera, Circle, Picture } from './styles';
import { useDropzone } from 'react-dropzone';
import useFiles from 'hooks/useStorage';

export function ImageUploader({ id, register, setValue, error, value, readOnly }) {
	
	const [ url, setUrl ] = useState(value || "");

	const { uploadFile } = useFiles();

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		disabled: readOnly,
		onDrop: (acceptedFiles) => {
			const task = uploadFile('avatars', acceptedFiles[0], acceptedFiles[0].name);

			const onProgress = () => {}
				
			const onError = () => {};

			const onComplete = () => {
				
				task.snapshot.ref.getDownloadURL().then((url) => {
					setUrl(url);
					setValue('avatar', url);
				});
			};

			task.on('state_changed', onProgress, onError, onComplete);
		}
	});

	useEffect(
		() => {
			register({ name: id });

			setValue(id, value || []);

			if (value){
				setUrl(value);
			}
			
		},
		[ register, setValue, id, value ]
	);

	return (
		<Circle {...getRootProps({ className: 'dropzone' })}>
			<input type="file" name="attachments" {...getInputProps()} />
			{url ? <Picture alt="" src={url} /> : <Camera /> }
		</Circle>
	);
}

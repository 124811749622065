import styled from "styled-components";
import { colors, breakpoints } from "definitions";

export const Caption = styled.h3`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: ${(props) =>
    props.mode === "dark" ? `${colors.fonts.white}` : `${colors.fonts.black}`};
  padding-top: 1rem;
  width: 100%;

  margin-bottom: 5px;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width === "100%" ? "100%" : "auto")};
  select {
    height: ${({ multiple }) => (multiple ? "12rem !important" : "3.8rem")};

    option {
      padding: ${({ multiple }) => (multiple ? "10px" : "auto")};
      margin: ${({ multiple }) => (multiple ? "10px 0" : "auto")};
    }
  }
`;

export const FieldLabel = styled.label`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.5rem;
  color: ${(props) =>
    props.mode === "dark" ? `${colors.fonts.white}` : `${colors.fonts.black}`};
  padding-top: 1rem;
  width: 100%;
  margin-bottom: 5px;

  :after {
    content: "${(props) => (props.required === true ? " *" : "")}";
    color: ${colors.background.red};
  }
`;

export const ErrorMessage = styled.small`
  font-size: 13px;
  padding-left: 5px;
  font-weight: 500;
  color: ${(props) =>
    props.mode === "dark" ? `${colors.fonts.white}` : `${colors.fonts.red}`};
`;

export const InputContainer = styled.div`
  overflow: hidden;
  background: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.gray}`
      : `${colors.background.white}`};

  border-color: ${(props) =>
    props.mode === "dark"
      ? `${colors.icons.white}`
      : `${colors.icons.lightGray}`};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-left: 10px;
    margin-right: 0px;
  }
`;

export const LinkContainer = styled.div`
  border-radius: 4px;
  min-height: 4rem;
  height: 4rem;
  padding: 0.8rem;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LinkInput = styled.a`
  font-weight: 300;
  border: none;
  color: black;
  width: 90%;
`;

export const FieldInput = styled.input`
  border:none;
  border-bottom: 1px solid;

  color: ${(props) =>
    props.mode === "dark" ? `#000000` : `${colors.fonts.gray}`};

  box-sizing: border-box;
  border-radius: 4px;
  height: 3.8rem;
  min-height: 3.8rem;
  padding: ${(props) => (props.at ? "0.8rem 0rem" : "0.8rem")};
  width: ${(props) => (props.icon ? "90%" : "100%")};

  ${breakpoints.phablet} {
    margin-right: 0px;
  }
`;

export const FieldSelect = styled.select`
  border: 1px solid;

  border: 1px solid ${colors.fonts.white};

  background: ${(props) =>
    props.mode === "dark" ? `#451CB8` : `${colors.background.white}`};

  border-color: ${(props) =>
    props.mode === "dark"
      ? `${colors.icons.white}`
      : `${colors.icons.lightGray}`};

  color: ${(props) =>
    props.mode === "dark" ? `${colors.fonts.white}` : `${colors.fonts.gray}`};

  box-sizing: border-box;
  border-radius: 4px;
  height: 3.8rem;
  padding: 0 1rem;
  width: 100%;

  ${breakpoints.phablet} {
    margin-right: 0px;
  }
`;

export const CheckContainerInput = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const CheckContainer = styled.label`
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: Lato;
  font-style: normal;
  font-size: 1.35em;
  line-height: 29px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: ${({ showRow }) => (showRow === true ? "flex-start" : "center")};
  height: auto;

  user-select: none;

  margin: 2rem 0;
  justify-content: center;

  transition: color 0.25s linear;

  padding: ${({ showRow }) => (showRow === true ? "0 0 0 69px" : "0px")};

  color: ${({ checked, mode }) =>
    mode === "dark" ? `${colors.fonts.white}` : `${colors.fonts.black}`};

  a:visited,
  a:link {
    color: ${({ checked, mode }) =>
    checked
      ? mode === "dark"
        ? `${colors.background.white}`
        : `${colors.background.gray}`
      : `${colors.background.gray}`};
  }

  a {
    font-weight: 500;
  }

  ${breakpoints.tablet} {
    flex-basis: 100%;
    padding: 0px 0px 0px 35px;
  }
`;

export const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  label {
    color: ${({ checked, mode }) =>
    mode === "dark"
      ? `${colors.background.white}`
      : `${colors.background.black}`};
  }

  :checked ~ span {
    background-color: ${({ mode }) =>
    mode === "dark"
      ? `${colors.background.white}`
      : `${colors.background.black}`};
  }

  :checked ~ span:after {
    display: block;
  }
`;

export const CheckSpan = styled.span`
  position: absolute;
  top: 0px;
  height: 25px;
  width: 25px;
  background-color: #aaaaaa;
  border-radius: 6px;
  left: ${({ showRow }) => (showRow === true ? "29px" : "")};

  ${breakpoints.tablet} {
    left: 0;
  }

  :after {
    content: "";
    position: absolute;
    display: none;
  }

  :after {
    left: 9px;
    top: 4px;
    width: 7px;
    height: 13px;
    border: solid;
    border-color: ${({ mode }) =>
    mode === "dark"
      ? `${colors.background.gray}`
      : `${colors.background.white}`};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const Paragraph = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
`;

export const TextArea = styled.textarea`
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.white}`
      : `${colors.fonts.gray}`};
  background: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.gray}`
      : `${colors.background.white}`};
  padding: 0.8rem;
  resize: none;
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Break = styled.br`
  display: ${({ show }) => (show ? "block" : "none")};

  ${breakpoints.tablet} {
    display: none;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  align-items: center;
  text-align: center;

  ${breakpoints.tablet} {
    flex-basis: 100%;
    align-items: flex-start;
  }
`;

export const RadioGroupOptions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${breakpoints.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }

  li {
    color: ${colors.background.gray};
    display: inline-block;
    position: relative;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${breakpoints.tablet} {
      flex-direction: row;
    }
  }

  li input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }

  li label {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;
    font-size: 1.35em;
    height: 30px;
    z-index: 9;
    cursor: pointer;
    transition: all 0.25s linear;
    line-height: 29px;
    height: auto;
    margin-top: 4px;
    width: 100%;

    ${breakpoints.tablet} {
      margin-top: 0;
      flex-direction: row;
    }
  }

  /*Readonly no*/
  li:hover label {
    color: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.white}`
      : `${colors.background.gray}`};
  }

  /*Readonly no*/
  li:hover label .check {
    border: 5px solid;
    border-color: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.white}`
      : `${colors.background.yellow}`};
  }

  li label span {
    ${breakpoints.tablet} {
      margin-left: 10px;
    }
  }

  li label .check {
    display: block;
    border: 3px solid ${colors.background.gray};
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 5px;
    z-index: 5;
    transition: border 0.25s linear;
  }

  li label .check::before {
    display: block;
    content: "";
    border-radius: 100%;
    height: 13px;
    width: 13px;

    margin-left: 1px;
    margin-top: 1px;
    transition: background 0.25s linear;

    ${breakpoints.tablet} {
      left: 2px;
    }
  }

  input[type="radio"]:checked ~ label .check {
    border: 5px solid;
    border-color: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.white}`
      : `${colors.background.gray}`};
  }

  input[type="radio"]:checked ~ label .check::before {
    background: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.white}`
      : `${colors.background.gray}`};
  }

  input[type="radio"]:checked ~ label {
    color: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.white}`
      : `${colors.background.gray}`};
  }
`;

export const FieldAutoCompleteGroup = styled(FieldGroup)`
  position: relative;
  z-index: 5;
`;
export const AutoCompleteOptionContainer = styled.div`
  position: absolute;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  top: 78px;
  width: 100%;
  border: 1px solid ${colors.fonts.white};
  background: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.gray}`
      : `${colors.background.white}`};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
`;
export const AutoCompleteOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  line-height: 24px;
  text-transform: capitalize;
  color: #353535;
  cursor: pointer;

  :hover {
    background: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.white}`
      : `${colors.background.gray}`};
    background: ${(props) =>
    props.mode === "dark"
      ? `${colors.background.gray}`
      : `${colors.background.white}`};
  }
`;

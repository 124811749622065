import styled from "styled-components";
import { ComponentItemDescription } from "App/styles";
import { colors, breakpoints } from "definitions";
import { ContainerItem, OverHeroContainer } from "App/styles";

export const BreadcrumbContainer = styled(OverHeroContainer)`
  justify-content: center;
  width: 100%;
  margin: 4.6rem 0px;

  ${breakpoints.tabletXL} {
    width: 60rem;
  }

`;

export const BreadcrumbItem = styled(ContainerItem)`
  max-width: 41.5rem;
  min-height: 91px;
  justify-content: space-around;
  padding: 2rem;
  align-items: center;
  border-radius: 6px 0px 0px 6px;
  flex-direction: row;
  width: 30%;

  margin-left: ${({ type }) => (type !== "topic" ? "-30px" : "0px")};

  border-radius: ${({ type }) => (type !== "form" ? "6px 0px 0px 6px" : "6px")};

  background: ${colors.background.gray};

  z-index: ${({ type }) =>
    type === "topic" ? "22" : type === "award" ? "21" : "20"};

  ${breakpoints.wide} {
    justify-content: center;
  }

  ${breakpoints.tabletXL} {
    width: 15rem;
  }
`;

export const BreadcrumbItemNumber = styled.h3`
  font-family: Lato;
  font-style: normal;
  font-size: 40px;
  margin: 0px;

  margin-left: ${({ type }) => (type !== "topic" ? "30px" : "0px")};

  color: ${colors.fonts.white};
`;

export const BreadcrumbItemDescription = styled(ComponentItemDescription)`
  height: 34px;
  margin: 0px;
  margin-left: 1.5rem;

  color: ${colors.fonts.white};

  ${breakpoints.laptop} {
    padding-left: 15px;
    height: max-content;
  }

  ${breakpoints.tabletXL} {
    display: none;
  }
`;

import React, { useEffect } from "react";
//import Calendar from "components/Calendar";
import { HomeBodyContainer } from "App/styles";
import Sponsors from "components/Sponsors";
import History from "components/History";
import ExplanatoryCards from "components/ExplanatoryCards";
//import { NewsCarousel } from "components/Carousel";
import ActionCards from "components/ActionCards";

export default function Home() {
  useEffect(() => {
    document.title = `Inicio | Premios Sadosky`;
  }, []);

  return (
    <HomeBodyContainer>
      <ExplanatoryCards />
      <ActionCards />
      {/*<NewsCarousel />*/}

      <Sponsors />
      <History />
    </HomeBodyContainer>
  );
}

import styled from "styled-components";
import { colors } from "definitions";

export const YearContainer = styled.div`
  display: grid;
  justify-items: center;
`;

export const YearItem = styled.button`
  font-size: ${(props) => (props.selected ? "5rem" : "4rem")};
  line-height: ${(props) => (props.selected ? "6rem" : "5rem")};
  color: ${(props) =>
    props.selected ? colors.fonts.yellowGreen : colors.fonts.gray};
  border: none;
  margin-top: 0 !important;
  background-color: white;
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? "bolder" : "300")};
`;

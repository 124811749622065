import React, { useEffect, useState, useContext, useCallback } from "react";

import { ModalBodyContainer, ModalButtonClose } from "App/styles";

import Form from "components/Form";

import { MainTitle } from "components/MainTitle";
import { useLocation } from "wouter";
import useEntryForm from "hooks/useEntryForm";

import { AlertContext } from "contexts/AlertContext";
import { MessageType } from "model/dictionary";
import CloseIcon from "icons/CloseIcon";

export default function ViewEntry({ params }) {
  const { loadingForm, getEntryForm, initialForm } = useEntryForm();
  const [, navigate] = useLocation();
  const { showAlert } = useContext(AlertContext);
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    getEntryForm(params.entryId).then((data) => {
      if (!data.exists) {
        showAlert(MessageType.DANGER, "El formulario no existe", 3000, () => {
          navigate("/");
        });
      } else {
        setForm(data.form);
      }
    });
  }, [getEntryForm, navigate, params.entryId, showAlert]);

  const formCompleted = useCallback(
    (status) => {
      navigate("/");
    },
    [navigate]
  );

  return (
    <>
      <ModalBodyContainer>
        <MainTitle title={"Formulario"} seoTitle={"Formulario"} />

        <ModalButtonClose onClick={params.onClose}>
          <CloseIcon />
        </ModalButtonClose>
        <Form
          onCompleted={formCompleted}
          currentStatus="view"
          readOnly={true}
          form={form}
          onClose={params.onClose}
          loading={loadingForm}
        />
      </ModalBodyContainer>
    </>
  );
}

import * as React from "react";

const FacebookLineIcon = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.917 12.5c0-5.753-4.664-10.417-10.417-10.417S2.083 6.747 2.083 12.5c0 5.2 3.81 9.509 8.79 10.29v-7.279H8.227V12.5h2.644v-2.295c0-2.61 1.556-4.053 3.935-4.053 1.14 0 2.332.204 2.332.204v2.563h-1.314c-1.294 0-1.697.803-1.697 1.627V12.5h2.889l-.462 3.011h-2.427v7.28c4.98-.782 8.789-5.092 8.789-10.291Z"
      stroke="#006EDF"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);

export default FacebookLineIcon;

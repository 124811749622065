import React from "react";
import { icons, colors } from "definitions";

export default function LoginoutIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height={props.height}
      width={props.width}
      aria-hidden="true"
      focusable="false"
      fill={props.color}
      style={{
        ...props.style,
        cursor: "pointer",
        width: "28px",
        height: "28px",
        marginRight: "10px",
      }}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 12l5 4v-3h9v-2H7V8z"></path>
      <path d="M13.001 2.999a8.938 8.938 0 00-6.364 2.637L8.051 7.05c1.322-1.322 3.08-2.051 4.95-2.051s3.628.729 4.95 2.051 2.051 3.08 2.051 4.95-.729 3.628-2.051 4.95-3.08 2.051-4.95 2.051-3.628-.729-4.95-2.051l-1.414 1.414c1.699 1.7 3.959 2.637 6.364 2.637s4.665-.937 6.364-2.637c1.7-1.699 2.637-3.959 2.637-6.364s-.937-4.665-2.637-6.364a8.938 8.938 0 00-6.364-2.637z"></path>
    </svg>
  );
}

LoginoutIcon.defaultProps = {
  height: `${icons.cards.height}`,
  width: `${icons.cards.width}`,
  color: `${colors.icons.gray}`,
};

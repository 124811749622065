import React from "react";
import { colors } from "definitions";

export default function FacebookIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height={props.height}
      width={props.width}
      fill={props.fill}
      aria-hidden="true"
      focusable="false"
      style={{ ...props.style, cursor: "pointer" }}
    >
      <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0014.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
    </svg>
  );
}

FacebookIcon.defaultProps = {
  height: `16px`,
  width: `16px`,
  fill: `${colors.icons.white}`,
};

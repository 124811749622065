import React, { useEffect, memo } from 'react';
import { Title, TitleContainer } from './styles';

export function InternalMainTitle({ title, seoTitle }) {
	
	useEffect(
		() => {
			document.title = `${seoTitle} | Premios Sadosky`;
		},
		[ seoTitle ]
	);

	return (
		<TitleContainer>
			<Title>{title}</Title>
		</TitleContainer>
	);
}

export const MainTitle = memo(InternalMainTitle);
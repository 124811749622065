import React from 'react';

import { Container, MainSection, CardLargeText, Details } from 'basics/cards';

import SectionIcon from 'icons/entities/SectionIcon';
import QuestIcon from 'icons/entities/QuestIcon';

import { Link } from "wouter"
import ItemCardToolBar from 'components/ItemCardToolBar';



export default function SectionCard({ item, selected, topicId, awardId, onSelectEditedItem, onSelectDeletedItem, onCleanItemId }) {

	return (
		<Container selected={selected}>
			<MainSection>
				<SectionIcon />
				<Details>
					<CardLargeText> <strong>{item.order}</strong> - {item.title}</CardLargeText>
				</Details>
			</MainSection>

			<ItemCardToolBar id={item.id} actived={item.actived} selected={selected} onCleanItemId={onCleanItemId} onSelectEditedItem={onSelectEditedItem} onSelectDeletedItem={onSelectDeletedItem}>
				<Link to={`/administracion/cuestionario/${topicId}/${awardId}/${item.id}`}>
					<QuestIcon />
				</Link>
			</ItemCardToolBar>
		</Container>
	
	)
}

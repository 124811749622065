import React, { useCallback, useState } from "react";
import { BodyContainer, HeaderSectionEntriesContainer } from "App/styles";
import ListOfEntries from "components/ListOfEntries";
import { MainTitle } from "components/MainTitle";
import { SelectField } from "basics";
import { Button, CleanButton } from "basics/buttons";
import useEntries from "hooks/useEntries";
import Confirmation from "components/Confirmation";
import { ContainerAnimated } from "./styles";

import AnimatedControl from "components/AnimatedControl";

export default function EntriesPage({
  title,
  entryListMode,
  formStatusFiltered,
  formStatusConfirmed,
  selectionMode,
}) {
  const {
    entries,
    awards,
    awardSelected,
    handleFilter,
    resetFilter,
    loading,
    emptyMessage,
    onSelected,
    onCleanSelected,
    isSelected,
    confirmChangeStatus,
    areEntriesSelected,
  } = useEntries({
    mode: entryListMode,
    formStatus: formStatusFiltered,
    selectionMode: selectionMode,
  });

  const [formOpened, setFormOpened] = useState(false);

  const onConfirmChangeStatus = (newStatus) => {
    setFormOpened(false);

    confirmChangeStatus(newStatus);
  };

  const onOpenForm = () => {
    setFormOpened(true);
  };

  const onCloseForm = useCallback(async () => {
    if (!areEntriesSelected()) {
      setFormOpened(false);
    }
  }, [areEntriesSelected]);

  const onCleanOpenForm = () => {
    setFormOpened(false);
  };

  return (
    <BodyContainer>
      <MainTitle title={title} seoTitle={title} />

      <HeaderSectionEntriesContainer>
        <SelectField
          id="award.id"
          value={awardSelected}
          options={awards}
          onChange={handleFilter}
          label="Premios"
          defaultOption="Elija un premio"
        />

        <CleanButton onClick={() => resetFilter()}>Limpiar</CleanButton>
        {formStatusConfirmed && (
          <Button
            disabled={!areEntriesSelected()}
            onClick={() => {
              onOpenForm();
            }}
          >
            Confirmar
          </Button>
        )}
      </HeaderSectionEntriesContainer>

      <ContainerAnimated>
        <AnimatedControl
          condition={formOpened && onCloseForm()}
          control={
            <Confirmation
              type="confirm"
              text="¿Confirma la selección?"
              onClick={onConfirmChangeStatus}
              paramOk={formStatusConfirmed}
              cancel={onCleanOpenForm}
            />
          }
        />
      </ContainerAnimated>

      <ListOfEntries
        entries={entries}
        mode={entryListMode}
        loading={loading}
        emptyMessage={emptyMessage}
        onSelected={onSelected}
        onCleanSelected={onCleanSelected}
        isSelected={isSelected}
      />
    </BodyContainer>
  );
}

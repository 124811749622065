import React, { useState, useCallback, Fragment } from "react";
import {
  Container,
  EntryItemContainer,
  ImageItem,
  ItemDescription,
  ProjectDate,
  Divider,
  Name,
  Title,
  ProjectDescription,
  Section,
  SectionOne,
  Row,
  State,
  Icons,
} from "./styles";

import Ponderation from "components/Ponderation";

import Confirmation from "components/Confirmation";

import Rating from "components/Rating";
import { Votes } from "components/Ponderation";

import EntryPostulationLoader from "loader/EntryPostulationLoader";

import EyeIcon from "icons/EyeIcon";
import StarIcon from "icons/StarIcon";
import VoteIcon from "icons/VoteIcon";
import EmailIcon from "icons/EmailIcon";

import CloseIcon from "icons/CloseIcon";
import DowloadIcon from "icons/DownloadIcon";
import { colors } from "definitions";
import { getLabel, capitalize } from "helpers";

import { FormStatus } from "model/options";

import AnimatedControl from "components/AnimatedControl";

import useTimeAgo from "hooks/useTimeAgo";
import useEntries from "hooks/useEntries";
import useEntryForm from "hooks/useEntryForm";
import Modal from "components/Modal";
import ViewEntry from "pages/ViewEntry";

export default function ListOfEntries({
  entries,
  mode,
  loading,
  emptyMessage,
  onSelected,
  onCleanSelected,
  isSelected,
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const [entryId, setEntryId] = useState();

  const showEntryForm = (id) => {
    setEntryId(id);
    setModalOpened(true);
  };

  const closeEntryForm = () => {
    setModalOpened(false);
  };

  return (
    <Container>
      <Modal
        locked
        onClose={() => {
          setModalOpened();
        }}
        open={modalOpened}
      >
        <ViewEntry params={{ entryId, onClose: () => closeEntryForm() }} />
      </Modal>

      {entries.map((entry) => {
        return (
          <EntryItem
            key={entry.id}
            entry={entry}
            showEntryForm={showEntryForm}
            onSelected={onSelected}
            selected={
              (isSelected && isSelected(entry.id)) ||
              (mode === "voting" && entry.isVoted)
            }
            onCleanSelected={onCleanSelected}
            mode={mode}
            loading={loading}
          />
        );
      })}

      {entries.length === 0 && !loading && (
        <EntryItemContainer style={{ padding: "2rem" }}>
          <Section
            style={{
              flexBasis: "100%",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <Name style={{ textAlign: "center", alignSelf: "center" }}>
              {emptyMessage}
            </Name>
          </Section>
        </EntryItemContainer>
      )}

      {entries.length === 0 && loading && (
        <EntryItemContainer style={{ padding: "2rem" }}>
          <EntryPostulationLoader />
        </EntryItemContainer>
      )}
    </Container>
  );
}

const EntryItem = ({
  entry,
  onSelected,
  showEntryForm,
  selected,
  onCleanSelected,
  mode,
  loading,
}) => {
  const { sendConfirmEmail } = useEntryForm();

  const timeago = useTimeAgo(+entry.createdAt.toDate());

  const [formOpened, setFormOpened] = useState(false);

  const { updateVote } = useEntries();

  const getStatus = useCallback(() => {
    return getLabel(FormStatus, entry.currentStatus.type);
  }, [entry]);

  const onOpenForm = () => {
    onSelected(entry.id);

    setFormOpened(true);
  };

  const onCloseForm = async (reset = false) => {
    onCleanSelected(entry.id, reset);

    setFormOpened(false);
  };

  const onSubmit = () => {
    updateVote(entry.id, entry.award.id).then(() => {
      onCloseForm(true);
    });
  };

  const onResendEmail = () => {
    sendConfirmEmail(entry);
  };

  const showBorder = selected && !formOpened;

  return (
    <Fragment>
      <EntryItemContainer
        mode={mode}
        selected={formOpened}
        showBorder={showBorder}
      >
        <SectionOne showBorder={showBorder}>
          <State
            color={`${colors.background.gray}`}
            showBorder={showBorder}
            mode={mode}
            selected={selected}
          >
            <span
              style={{
                color: "white",
                fontWeight: "500",
                textTransform: "uppercase",
                writingMode: "vertical-rl",
                transform: "rotate(180deg)",
              }}
            >
              {getStatus()}
            </span>
          </State>

          <ImageItem image={entry.avatar} showBorder={showBorder} />
        </SectionOne>

        <Section>
          <ItemDescription>
            <Row>
              <Name>
                {capitalize(entry.projectName || "Empresario del Año")} (
                {capitalize(entry.companyName)})
              </Name>
            </Row>
            <Title>
              {entry.lastName}, {entry.firstName}. ({entry.email})
            </Title>
            <ProjectDescription>{entry.award.title} </ProjectDescription>
            <ProjectDate>Creado {timeago}</ProjectDate>
          </ItemDescription>
        </Section>

        <Divider />

        <Icons selected={selected}>
          {formOpened ? (
            <CloseIcon
              onClick={() => {
                onCloseForm();
              }}
            />
          ) : (
            <Fragment>
              <button
                style={{ backgroundColor: "white", border: "none" }}
                onClick={() => {
                  showEntryForm(entry.id);
                }}
              >
                <EyeIcon />
              </button>

              <a target="_blank" rel="noopener noreferrer" href={entry.pdf}>
                <DowloadIcon color={`${colors.icons.gray}`} width="3rem" />
              </a>

              {mode === "criteria" && (
                <StarIcon
                  onClick={() => {
                    onOpenForm();
                  }}
                />
              )}
              {mode === "voting" && !entry.isVoted && (
                <VoteIcon
                  onClick={() => {
                    onOpenForm();
                  }}
                />
              )}
              {mode === "historical" && entry.currentStatus.type === "draft" && (
                <EmailIcon
                  onClick={() => {
                    onResendEmail();
                  }}
                />
              )}
            </Fragment>
          )}
        </Icons>
      </EntryItemContainer>

      {mode === "finalists" && (
        <Ponderation
          criteria={{ users: entry.users, ponderation: entry.ponderation }}
          entryId={entry.id}
          onSelected={onSelected}
          selected={selected}
          onCleanSelected={onCleanSelected}
        />
      )}

      {mode === "winners" && (
        <Votes
          votes={entry.votes}
          entryId={entry.id}
          onSelected={onSelected}
          selected={selected}
          onCleanSelected={onCleanSelected}
        />
      )}

      <AnimatedControl
        condition={mode === "criteria" && selected && !loading}
        control={
          <Rating
            formOpened={formOpened}
            onSelected={onSelected}
            mode={mode}
            entry={entry}
            onClose={onCloseForm}
          />
        }
      />

      <AnimatedControl
        condition={mode === "voting" && selected && !entry.isVoted}
        control={
          <Confirmation
            type="confirm"
            paramOk={entry.id}
            text={`Confirma el voto a ${entry.projectName} de ${entry.companyName}?`}
            onClick={onSubmit}
            cancel={onCloseForm}
          />
        }
      />
    </Fragment>
  );
};

import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { HorizontalLine } from "App/styles";

import { Container } from "./styles";

import { WhiteLargeButton } from "basics/buttons";
import SpinnerLinear from "components/SpinnerLinear";

export default function ItemForm({
  formFields,
  waiting,
  schema,
  item,
  onUpdateItem,
  onCreateItem,
}) {
  const { register, unregister, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    delete data["id"];

    if (item) {
      onUpdateItem(item.id, data);
    } else {
      onCreateItem(data);
    }
  };
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          ref={register}
          type="hidden"
          name="actived"
          id="actived"
          value={item ? item.actived : true}
        />

        {formFields(register, unregister, setValue, errors, item)}

        <HorizontalLine />
        <WhiteLargeButton type="submit">
          {waiting ? <SpinnerLinear /> : "Guardar"}
        </WhiteLargeButton>
      </form>
    </Container>
  );
}

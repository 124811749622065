import styled from "styled-components";

export const Image = styled.div`
  height: ${({ height }) => `${height}`};
  border-radius: 6px 0px 0px 6px;
  background: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
`;

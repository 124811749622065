import React from 'react';
import AdminPage from 'components/AdminPage';
import TopicToolbar from 'crud/topics/TopicToolbar'
import TopicCard from 'crud/topics/TopicCard'
import TopicForm from 'crud/topics/TopicForm'
import { object, string, boolean, array } from 'yup';

export default function Topics() {

	const schema = object({
		actived: boolean(),
		roles: array(
			object({
				value: string(),
				label: string(),
				checked: boolean()
			})
		),
		title: string().required('El título es obligatorio'),
		description: string().required('La descripcion es obligatoria')
	});

	const sortOptions = [
		{
			value: 'title',
			label: 'Título'
		},
		{
			value: 'description',
			label: 'Descripción'
		}
	];

	return (
		<AdminPage
			title="Temáticas"
			seoTitle="Administración de temáticas"
			sortOptions={sortOptions}
			collection="topics"
			schema={schema}
			ItemFormFields={TopicForm}
			ItemCard={TopicCard}
			ItemToolBar={TopicToolbar}
		/>
	);
}

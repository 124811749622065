import React, { useEffect, useState } from "react";

import { InputField, TextAreaField, SelectField } from "basics";

import useFirestore from "hooks/useFirestore";

export default function CeoForm({ register, setValue, errors, item }) {
  const [awards, setAwards] = useState([]);

  const { getCEOAwards } = useFirestore();

  useEffect(() => {
    getCEOAwards().then(setAwards);
  }, [getCEOAwards, item]);

  return (
    <>
      <InputField
        label="Nombre"
        type="text"
        value={item && item.title}
        id="title"
        register={register}
        error={errors && errors.title}
        mode="dark"
      />

      <InputField
        label="Apellido"
        type="text"
        value={item && item.lastName}
        id="lastName"
        register={register}
        error={errors && errors.lastName}
        mode="dark"
      />

      <InputField
        label="Email"
        type="text"
        value={item && item.email}
        id="email"
        register={register}
        error={errors && errors.email}
        mode="dark"
      />

      <InputField
        label="Empresa"
        type="text"
        value={item && item.company}
        id="company"
        register={register}
        error={errors && errors.company}
        mode="dark"
      />

      <SelectField
        options={awards}
        id="award"
        label="Premio"
        value={item && item.award}
        placeholder="Elija un premio"
        required={true}
        register={register}
        error={errors.award}
        mode="dark"
      />

      <TextAreaField
        label="Motivos"
        type="text"
        value={item && item.reason}
        id="reason"
        register={register}
        error={errors && errors.reason}
        mode="dark"
      />
    </>
  );
}

import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader';

import { ContainerEntryItem } from 'App/styles';

export default function SquareLoader({ counter }) {

   let numbers = Array.from(Array(counter).keys());

	return (
		<Fragment>
			{numbers.map((number) => (
				<ContainerEntryItem key={number}>
					<ContentLoader 
						speed={2}
						width={280}
						height={271}
						viewBox="0 0 280 271"
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect x="0" y="0" rx="0" ry="0" width="28" height="271" /> 
						<rect x="100" y="27" rx="0" ry="0" width="109" height="20" /> 
						<rect x="51" y="126" rx="0" ry="0" width="212" height="13" /> 
						<rect x="73" y="155" rx="0" ry="0" width="172" height="13" /> 
						<rect x="102" y="223" rx="0" ry="0" width="114" height="28" />
					</ContentLoader>
				</ContainerEntryItem>
			))}
		</Fragment>
	);
}

import React, { useState, useEffect } from 'react';

import { InputField, CheckBoxesField, SelectField } from 'basics';

import { Roles } from 'model/options';

import useFirestore from 'hooks/useFirestore';

export default function UserForm({ register, setValue, errors, item }) {
	const [userRoles, setUserRoles] = useState([]);

	const [awards, setAwards] = useState([]);

	const [showAwards, setShowAwards] = useState(false);

	const { getAwards } = useFirestore();

	const handleRoles = (label, value) => {

		if (label === "judge")
		{
			setShowAwards(value)
		}
	};

	useEffect(() => {

		let roles = Roles;

		if (item) {

			if (item.roles) {
				roles = [];

				Roles.forEach(i => {

					const rol = item.roles.filter(rol => rol.value === i.value);

					roles.push(rol.length > 0 ? rol[0] : i);
				});
			}

			if (item.awards)
			{
				setShowAwards(roles.filter(role=> role.value === "judge").length > 0)
			}
		}

		setUserRoles(roles);

		getAwards(setAwards);

	}, [getAwards, item]);

	return (
		<>
			<InputField
				label="Nombre Completo"
				type="text"
				value={item && item.title}
				id="title"
				register={register}
				errors={errors}
				mode="dark"
				required={true}
			/>

			<InputField
				label="Email"
				type="text"
				value={item && item.email}
				id="email"
				register={register}
				errors={errors}
				mode="dark"
				required={true}
			/>

			<CheckBoxesField
				options={userRoles}
				register={register}
				error={errors.roles}
				id="roles"
				label="Roles"
				mode="dark"
				twoLines={true}
				onChange={handleRoles}
				required={true}
			/>

			{showAwards && <SelectField
				options={awards}
				id="awards"
				label="Premios"
				value={item && item.awards}
				placeholder="Elija los premios"
				required={true}
				register={register}
				error={errors.criteria}
				mode="dark"
				multiple={true}
			/>}
		</>
	)
}
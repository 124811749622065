import React from 'react';
import AdminPage from 'components/AdminPage';
import CompetitionToolbar from 'crud/competitions/CompetitionToolbar';
import CompetitionCard from 'crud/competitions/CompetitionCard';
import CompetitionForm from 'crud/competitions/CompetitionForm';
import { object, string, boolean } from 'yup';

export default function Competitions() {
	const schema = object({
		actived: boolean(),
		title: string().required('El título es obligatorio'),
		entryStage: string().required('La fecha de postulación es obligatoria'),
		judgingStage: string().required('La fecha de votación es obligatoria'),
		selectionStage: string().required('La fecha de selección es obligatoria')
	});

	const sortOptions = [
		{
			value: 'title',
			label: 'Título'
		}
	];

	return (
		<AdminPage
			title="Competiciones"
			seoTitle="Administración de la competición"
			sortOptions={sortOptions}
			collection="competitions"
			schema={schema}
			ItemFormFields={CompetitionForm}
			ItemCard={CompetitionCard}
			ItemToolBar={CompetitionToolbar}
		/>
	);
}

import { useState, useCallback, useEffect, useContext } from "react";

import { firebaseDB } from "firebase/client";

import { capitalize, sortByProperty } from "helpers";

import { AlertContext } from "contexts/AlertContext";

import exportFromJSON from "export-from-json";

import { getAnswer } from "helpers";

export default function useHistoricalEntries() {
  const { showWaiting, hideWaiting } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);

  const [lastEntry, setLastEntry] = useState();

  const [moreItems, setMoreItems] = useState(true);
  const [moreItemsAvailable, setMoreItemsAvailable] = useState(true);

  const [awards, setAwards] = useState([]);

  const [award, setAward] = useState();
  const [status, setStatus] = useState();
  const [competition, setCompetition] = useState();

  const [entries, setEntries] = useState([]);

  const [competitions, setCompetitions] = useState([]);

  const [filter, setFilter] = useState([]);

  const [emptyMessage, setEmptyMessage] = useState("");

  const amountOfItems = 3;

  const resetFilter = () => {
    setFilter([]);

    setAward("");
    setCompetition("");
    setStatus("");

    setLastEntry();

    setEntries([]);

    setMoreItems(true);
  };

  const updateFilter = (field, operator, value) => {
    if (field === "award.id") {
      setAward(value);
    }

    if (field === "currentStatus.type") {
      setStatus(value);
    }

    if (field === "competition.id") {
      setCompetition(value);
    }

    let newFilter = filter.filter((item) => {
      return item.field !== field;
    });

    if (value !== "") {
      newFilter = [
        ...newFilter,
        { field: field, operator: operator, value: value },
      ];
    }

    setFilter(newFilter);

    setLastEntry();

    setEntries([]);

    setMoreItems(true);
  };

  const loadAwards = useCallback(async () => {
    var collection = firebaseDB.collection("topics");

    let query = collection.where("actived", "==", true).orderBy("title");

    const topicSnapshot = await query.get();

    topicSnapshot.docs.forEach(async (topic) => {
      const awardSnapshot = await firebaseDB
        .collection(`topics/${topic.id}/awards`)
        .where("actived", "==", true)
        .orderBy("title")
        .get();

      const items = awardSnapshot.docs.map((award) => {
        return {
          value: award.id,
          label: capitalize(`${award.data().title} (${topic.data().title})`),
        };
      });

      setAwards((prevAwards) =>
        prevAwards.concat(items).sort(sortByProperty("label"))
      );
    });
  }, []);

  const loadCompetitions = useCallback(() => {
    setLoading(true);

    let query = firebaseDB.collection("competitions");

    query
      .get()
      .then(({ docs }) => {
        const competition = docs.map((doc) => {
          return { value: doc.id, label: doc.data().title };
        });

        setCompetitions(competition);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getEntries = useCallback(async () => {
    showWaiting("Cargando las postulaciones...");

    let query = firebaseDB
      .collection("entries")
      .orderBy("createdAt", "desc")
      .where("actived", "==", true)
      .limit(amountOfItems);

    if (lastEntry) {
      query = query.startAfter(lastEntry);
    }

    filter.forEach((item) => {
      query = query.where(item.field, item.operator, item.value);
    });

    query
      .get()
      .then((snapshots) => {
        const docs = snapshots.docs;

        setMoreItemsAvailable(docs.length === amountOfItems);

        const items = docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        const last = docs[docs.length - 1];

        setLastEntry(last);

        if (items.length === 0 && entries.length === 0) {
          setEmptyMessage("No hay resultados para el filtro seleccionado");
        }

        setEntries((prev) => prev.concat(items));
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        hideWaiting();
        setMoreItems(false);
      });
  }, [entries, filter, hideWaiting, lastEntry, showWaiting]);

  const getAllEntries = useCallback(async () => {
    let query = firebaseDB
      .collection("entries")
      .orderBy("createdAt", "desc")
      .where("actived", "==", true);

    filter.forEach((item) => {
      query = query.where(item.field, item.operator, item.value);
    });

    const snapshots = await query.get();

    const docs = snapshots.docs;

    const items = docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    return items;
  }, [filter]);

  const handleFilter = (field, value) => {
    updateFilter(field, "==", value);
  };

  const exportEntries = async () => {
    showWaiting("Exportando las postulaciones...");

    const entries = await getAllEntries();

    const firstEntry = entries[0];

    const fileName = `${firstEntry.award.title
      .trim()
      .replaceAll(" ", "_")
      .toLowerCase()}_${firstEntry.competition.title
      .trim()
      .replaceAll(" ", "_")
      .toLowerCase()}`;

    const data = entries.map((entry) => {
      const row = {
        "Nombre y Apellido": `${entry.firstName} ${entry.lastName}`,
        "Institución o Empresa": entry.companyName,
        Teléfono: entry.phone,
        Correo: entry.email,
        Proyecto: entry.projectName,
        Temática: entry.topic.title,
        Premio: entry.award.title,
      };

      entry.sections.forEach((section) => {
        section.questions.forEach((question) => {
          row[`${question.title}`.replaceAll(",", "")] = getAnswer(question);
        });
      });

      return row;
    });

    exportFromJSON({ data, fileName, exportType: "csv" });

    hideWaiting();
  };

  useEffect(() => {
    if (moreItems) {
      getEntries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreItems, filter]);

  useEffect(() => {
    loadAwards();
  }, [loadAwards]);

  useEffect(() => {
    loadCompetitions();
  }, [loadCompetitions]);

  return {
    loading,
    entries,
    awards,
    handleFilter,
    competitions,
    setMoreItems,
    resetFilter,
    moreItemsAvailable,
    award,
    status,
    competition,
    emptyMessage,
    exportEntries,
  };
}

import React from "react";
import AdminPage from "components/AdminPage";
import WinnerToolbar from "crud/winners/WinnerToolbar";
import WinnerCard from "crud/winners/WinnerCard";
import WinnerForm from "crud/winners/WinnerForm";
import { object, string, boolean } from "yup";

export default function Winners() {
  const schema = object({
    actived: boolean(),
    winner: boolean(),
    year: string().required("El año es obligatorio"),
    title: string().required("La temática es obligatoria"),
    company: string().required("La empresa es obligatoria"),
    project: string().required("La iniciativa es obligatoria"),
  });

  const sortOptions = [
    {
      value: "title",
      label: "Temática",
    },
  ];

  return (
    <AdminPage
      title="Ganadores"
      seoTitle="Administración de los ganadores"
      sortOptions={sortOptions}
      collection="winners"
      schema={schema}
      ItemFormFields={WinnerForm}
      ItemCard={WinnerCard}
      ItemToolBar={WinnerToolbar}
    />
  );
}

import React from "react";

import { InputField } from "basics";
import FileUploader from "components/FileUploader";
import { uuidv4 } from "helpers";

export default function GalleryForm({ register, setValue, errors, item }) {
  const folderImages = (item && item.folder) ?? `gallery/${uuidv4()}`;
  return (
    <>
      <input
        ref={register}
        type="hidden"
        name="folder"
        id="folder"
        value={folderImages}
      />

      <InputField
        label="Año"
        inputmode="numeric"
        type="number"
        value={item && item.title}
        id="title"
        register={register}
        errors={errors}
        mode="dark"
        required={true}
      />

      <FileUploader
        label="Imágenes"
        id="images"
        value={item && item.images}
        folder={folderImages}
        register={register}
        error={errors.logos}
        setValue={setValue}
        accepted="image/*"
        mode="dark"
        multiple={true}
      />
    </>
  );
}

import styled from "styled-components";
import { Link } from "wouter";
import { breakpoints, colors } from "definitions";
import ChevronDown from "icons/Chevron";

export const MenuContainer = styled.div`
  display: flex;
  padding-right: 25px;
`;

export const SubMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  transition: all 200ms;

  background-color: ${colors.background.white};

  ${breakpoints.laptop} {
    background-color: #172354;
  }
`;

export const MenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;

  background-color: ${colors.background.white};

  ${breakpoints.laptop} {
    flex-flow: column nowrap;

    position: ${({ open }) => (open ? "absolute" : "fixed")};
    transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-150%)")};
    top: 0;
    right: 0;
    height: min-content;
    width: 100%;
    justify-content: flex-start;

    margin-top: 6.5rem;
    padding-top: 25px;
    transition: transform 0.3s linear;

    color: #006EDF;
    z-index: -29;
  }
`;

export const MenuItem = styled.li`
  padding: 23px;

  ${breakpoints.laptop} {
    text-align: center;
    width: 100%;
    padding: 15px 0px 15px 0px;
  }

  :hover {
    color: ${colors.navigation.brightBlue};
  }
`;

export const MenuItemTitle = styled.span`
  
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  white-space: pre;
  font-weight: 500;

  display: flex;
  align-items: center;

  padding-left: 5px;
  color: ${colors.fonts.darkGray};

  z-index: 50000000;

  

  [disabled] {
    color: ${colors.navigation.veryLightGray};
  }

  ${breakpoints.laptop} {
    color: #006EDF;
    justify-content: center;
  }
`;

export const Arrow = styled(ChevronDown)`
  transform: ${(props) => (props.selected ? "rotate(180deg)" : "none")};
  width: 2rem;
  height: 2rem;
  stroke: ${colors.fonts.darkGray};

  ${breakpoints.laptop} {
    stroke: white;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const MenuItemLink = styled(Link)`
  padding: 10px;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  white-space: pre;
  font-weight: 500;

  color: ${colors.fonts.darkGray};
`;

export const MainMenuItemLink = styled(MenuItemLink)`
  margin-bottom: 4px;
`;

export const ExternalMenuItemLink = styled.a`
  padding: 10px;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  white-space: pre;
  font-weight: 500;

  color: ${colors.fonts.darkGray};
`;

export const SubMenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 0px 0.6rem 0.6rem;
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  transition: all 400ms;

  background-color: ${colors.background.white};

  visibility: ${(props) => (!props.selected ? "hidden" : "visible")};
  opacity: ${(props) => (!props.selected ? "0" : "1")};
  position: ${(props) => (!props.selected ? "fixed" : "absolute")};
  transform: ${(props) =>
    !props.selected ? "translateY(-10px)" : "translateY(0)"};

  ${breakpoints.laptop} {
    margin: 0;
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    position: ${(props) => (!props.selected ? "fixed" : "inherit")};
    padding: 0.5rem;
  }
`;

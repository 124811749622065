import styled from "styled-components";

import { colors } from "definitions";

export const Container = styled.div`
  background-color: ${colors.background.darkBlue};
  min-height: 15rem;
  height: auto;
  position: relative;

  width: 100%;
  max-width: 124.5rem;

  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  border-top: solid 1px ${colors.background.lightGray};

  padding: 1rem;

  border-radius: 0 0 6px 6px;
  margin-bottom: 2rem;

  display: flex;
  justify-content: space-around;

  flex-flow: wrap;

  form {
    padding: 25px;
  }

  .field-input-group {
    text-align: center;
  }
`;

export const Stars = styled.div`
  width: auto;
  height: auto;
  white-space: nowrap;
  display: flex;
  justify-content: center;
`;

import { BodyContainer } from "App/styles";
import { MainTitle } from "components/MainTitle";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { DescriptionContainer } from "./styles";

export default function AwardStory() {
  const file_name = "story.md";
  const [text, setText] = useState("");

  useEffect(() => {
    import(`../../data/${file_name}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setText(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });

  return (
    <BodyContainer>
      <MainTitle title="Historia" seoTitle="Historia" />
      <DescriptionContainer>
        <ReactMarkdown>{text}</ReactMarkdown>
      </DescriptionContainer>
    </BodyContainer>
  );
}

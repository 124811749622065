import React, { useContext, memo } from "react";
import { useTransition } from "react-spring";
import { useLocation, Route, Switch } from "wouter";

import Home from "pages/Home";
import NewEntry from "pages/NewEntry";
import UpdateEntry from "pages/UpdateEntry";
import ViewEntry from "pages/ViewEntry";
import Page404 from "pages/Page404";

import Voting from "pages/judges/Voting";
import Selection from "pages/judges/Selection";
import Winners from "pages/judges/Winners";
import Finalists from "pages/judges/Finalists";

import Users from "pages/administration/Users";
import Awards from "pages/administration/Awards";
import Topics from "pages/administration/Topics";
import Sections from "pages/administration/Sections";
import Questionnaire from "pages/administration/Questionnaire";
import Sponsors from "pages/administration/Sponsors";
import Competitions from "pages/administration/Competitions";
import Criteria from "pages/administration/Criteria";
import Ceo from "pages/administration/Ceo";

import Mentions from "pages/administration/Mentions";
import WinnersAdm from "pages/administration/Winners";
import Gallery from "pages/administration/Gallery";
import News from "pages/administration/News";

import Historical from "pages/entries/Historical";
import Restauration from "pages/entries/Restauration";

import History from "pages/History";

import { AnimatedContainer } from "./styles";

import { AuthContext } from "contexts/AuthContext";

import PrivateRoute from "components/PrivateRoute";
import AwardStory from "pages/AwardStory";
import Rules from "pages/Rules";
import ExplanationTopicsAwards from "pages/ExplanationTopicsAwards";
import ViewNew from "pages/ViewNewsItem";
import JudgeStatus from "pages/judges/Status";

const Routes = memo(() => {
  const [location] = useLocation();
  const { currentUserRoles } = useContext(AuthContext);

  const transitions = useTransition(location, {
    initial: { transform: "translate3d(0, 0%,0)" },
    from: { transform: "translate3d(0, 90%,0)" },
    enter: { transform: "translate3d(0, 0%,0)" },
    leave: { transform: "translate3d(0, 90%,0)", display: "none" },
  });

  return (
    <>
      {transitions((style, item) => (
        <AnimatedContainer style={style}>
          <Switch location={item}>
            <Route component={Home} path="/" />
            <Route component={NewEntry} path="/postularse" />
            <Route
              component={UpdateEntry}
              path="/editar-postulacion/:entryId"
            />
            <Route
              component={ViewEntry}
              path="/visualizar-postulacion/:entryId"
            />
            <Route component={History} path="/ediciones-anteriores" />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Users}
              path="/administracion/usuarios"
            />

            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Mentions}
              path="/administracion/menciones"
            />

            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={WinnersAdm}
              path="/administracion/ganadores"
            />

            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Gallery}
              path="/administracion/galeria"
            />

            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Awards}
              path="/administracion/premios/:topicId"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Topics}
              path="/administracion/tematicas"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Questionnaire}
              path="/administracion/cuestionario/:topicId/:awardId/:sectionId"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Sections}
              path="/administracion/secciones/:topicId/:awardId"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Competitions}
              path="/administracion/competiciones"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Sponsors}
              path="/administracion/auspiciantes"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Criteria}
              path="/administracion/criterios"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Ceo}
              path="/administracion/Ceo"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={News}
              path="/administracion/noticias"
            />
            <PrivateRoute
              authed={currentUserRoles.judge}
              component={Voting}
              path="/jurado/votacion"
            />
            <PrivateRoute
              authed={currentUserRoles.selector}
              component={Selection}
              path="/jurado/preseleccion"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Winners}
              path="/jurado/ganadores"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Finalists}
              path="/jurado/finalistas"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={JudgeStatus}
              path="/jurado/evaluadores"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Historical}
              path="/postulaciones/historico"
            />
            <PrivateRoute
              authed={currentUserRoles.administrator}
              component={Restauration}
              path="/postulaciones/restauracion"
            />

            <Route
              authed={currentUserRoles.administrator}
              component={ViewNew}
              path="/noticia/:newId"
            />
            <Route component={AwardStory} path="/premios" />
            <Route component={Rules} path="/reglamento" />
            <Route component={ExplanationTopicsAwards} path="/tematicas" />
            <Route component={Page404} path="/:rest*" />
          </Switch>
        </AnimatedContainer>
      ))}
    </>
  );
});

export default Routes;

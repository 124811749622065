import * as React from "react";
import { icons } from "definitions";

export default function NewsItemIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      fill={icons.entities.color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M17 14a1 1 0 0 1-1 1H8a1 1 0 1 1 0-2h8a1 1 0 0 1 1 1Zm-4 3H8a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2Zm9-6.515V19a5.006 5.006 0 0 1-5 5H7a5.006 5.006 0 0 1-5-5V5a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486A6.952 6.952 0 0 1 22 10.485Zm-6.949-7.021a5.013 5.013 0 0 0-1.05-.78V7a1 1 0 0 0 1 1h4.315a4.987 4.987 0 0 0-.78-1.05L15.05 3.464Zm4.95 7.021c0-.165-.033-.323-.048-.485H15a3 3 0 0 1-3-3V2.047c-.162-.015-.32-.047-.485-.047H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-8.515Z"
          fill={icons.entities.color}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={icons.entities.color} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { useState, useEffect, useContext } from "react";
import { firebaseDB } from "firebase/client";
import { AlertContext } from "contexts/AlertContext";
import { groupBy } from "helpers";

export default function useEditions() {
  const { showWaiting, hideWaiting } = useContext(AlertContext);

  const [yearSelected, setYearSelected] = useState(
    new Date().getFullYear() - 1
  );

  const [winners, setWinners] = useState([]);

  const [mentions, setMentions] = useState([]);

  const [images, setImages] = useState([]);

  useEffect(() => {
    const editionInformation = async () => {
      setYearSelected(yearSelected);

      showWaiting("Cargando información. Aguarde unos momentos");

      try {
        const winnersSnaphot = await firebaseDB
          .collection(`winners`)
          .where("year", "==", yearSelected.toString())
          .get();

        setWinners(
          groupBy(
            winnersSnaphot.docs.map((doc) => {
              const data = doc.data();
              const id = doc.id;

              return { ...data, id };
            }),
            (winner) => winner.title
          )
        );

        const mentionsSnaphot = await firebaseDB
          .collection(`mentions`)
          .where("year", "==", yearSelected.toString())
          .get();

        setMentions(
          mentionsSnaphot.docs.map((doc) => {
            const data = doc.data();
            const id = doc.id;

            return { ...data, id };
          })
        );

        const gallerySnaphot = await firebaseDB
          .collection("gallery")
          .where("title", "==", yearSelected.toString())
          .get();

        setImages(
          gallerySnaphot.docs.map((doc) => {
            const data = doc.data();

            return data.images;
          })[0]
        );
      } catch (error) {
        console.log(error);
      } finally {
        hideWaiting();
      }
    };

    editionInformation();
  }, [hideWaiting, showWaiting, yearSelected]);

  return { yearSelected, setYearSelected, winners, mentions, images };
}

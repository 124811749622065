import React, { useEffect } from "react";
import debounce from "lodash/debounce";
import styled from 'styled-components';
import {colors} from 'definitions';

export default function TooltipPopover({ children, coords, updateTooltipCoords }) {
    const updateCoords = debounce(updateTooltipCoords, 100);

    useEffect(() => {
        window.addEventListener("resize", updateCoords);
        return () => window.removeEventListener("resize", updateCoords);
    }, [updateCoords]);

    return (
        <Container style={{ ...coords }}>
            <TooltipContent>
                <TooltipArrow />
                <TooltipInner role="tooltip">
                    <div>
                        <TooltipInnerContent>{children}</TooltipInnerContent>
                    </div>
                </TooltipInner>
            </TooltipContent>
        </Container>
    );
};

const Container = styled.div`
        position: absolute;
        width: 200px;
        transform: translate(-100px, -100%);

        :after {
            position: absolute;
            background: rgba(255, 255, 255, 0.01);
            content: '';
        }
`
const TooltipArrow = styled.div`
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    transform: rotate(45deg);
    bottom: 6.2px;
    left: 96px;
    border-top-color: transparent;
    border-right-color: ${colors.background.gray};
    border-bottom-color: ${colors.background.gray};
    border-left-color: transparent;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
`
const TooltipContent = styled.div`
    background-color: ${colors.background.gray};
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
`

const TooltipInner = styled.div`
    color: white
`
const TooltipInnerContent = styled.div`
`
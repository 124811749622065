import React, { useEffect, useState, useCallback } from "react";
import { firebaseApp, cloudFunctions } from "firebase/client";
import { UserRoles } from "model/dictionary";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLogged, setUserLogged] = useState(false);
  const [currentUserRoles, setCurrentUserRoles] = useState(UserRoles);
  const [loading, setLoading] = useState(false);

  const signInAnonymous = useCallback(() => {
    if (!userLogged) {
      firebaseApp
        .auth()
        .signInAnonymously()
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userLogged]);

  useEffect(() => {
    if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");

      if (email !== null) {
        firebaseApp
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(() => {
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch(() => { });
      }
    }

    let unsubscribe = firebaseApp.auth().onAuthStateChanged((user) => {

      setUserLogged(user && !user.isAnonymous);

      setCurrentUser(user);
      setLoading(true);

      if (!user) {
        signInAnonymous();
      } else if (!user.isAnonymous) {
        var getUserCustomClaims = cloudFunctions.httpsCallable(
          "getUserCustomClaims"
        );

        getUserCustomClaims({
          uid: user.uid,
        })
          .then((result) => {
            const roles = { ...UserRoles, ...result.data };

            setCurrentUserRoles(roles);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setCurrentUser(null);
        setCurrentUserRoles(UserRoles);
      }

      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [setCurrentUserRoles, signInAnonymous]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserRoles,
        userLogged,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React, { useState, useContext } from "react";
import { MenuContainer } from "./styles";
import Menu from "components/Menu";
import { Burger } from "./styles";
import Login from "components/Login";
import Blur from "components/Blur";

import { AuthContext } from "contexts/AuthContext";

export default function NavBar() {
  const [open, setOpen] = useState(false);
  const { userLogged, currentUserRoles, loading } = useContext(AuthContext);

  return (
    <>
      <MenuContainer role="full-horizontal">
        {userLogged && (
          <Burger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </Burger>
        )}

        {open && <Blur />}


        <Menu
          open={open}
          loading={loading}
          roles={currentUserRoles}
          onClose={() => setOpen(false)}
        />


        <Login />
      </MenuContainer>
    </>
  );
}

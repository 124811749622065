import React from "react";
import {
  Card,
  Container,
  Content,
  Title,
  SubTitle,
  LinkStyled,
  Header,
  LinkSubscribe
} from "./styles";

export default function ExplanatoryCards() {
  return (
    <Container>
      <Content>
        <Card>
          <Header>
            <Title>Temáticas y Premios</Title>
            <SubTitle>Conocé las temáticas y premios de la competencia</SubTitle>
          </Header>
          <LinkStyled to="/tematicas">Ver más</LinkStyled>
        </Card>
        <Card>
          <Header>
            <Title>Reglamento</Title>
            <SubTitle>Conocé las bases y condiciones de la competencia</SubTitle>
          </Header>
          <LinkStyled to="/reglamento">Ver más</LinkStyled>
        </Card>
        {/*<Card>
          <Header>
            <Title>Postularse</Title>
            <SubTitle>Formulario de inscripción</SubTitle>
          </Header>
          <LinkSubscribe to="/postularse">Inscribirse</LinkSubscribe>

        </Card>*/}
      </Content>
    </Container>
  );
}
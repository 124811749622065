import React, { useState, Fragment, memo } from "react";
import { Container, Header, TitleContainer } from "./styles";
import { SectionTitle } from "App/styles";

import WordCounter from "components/WordCounter";
import { TextAreaField, RadioGroupField, CheckBoxesField } from "basics";
import Help from "components/Help";

const QuestionHeader = ({
  id,
  title,
  order,
  register,
  helpText,
  wordCounter,
  currentWordCounter,
  showWordCounter = true,
  required,
  readOnly,
}) => {
  return (
    <Header>
      <TitleContainer>
        <SectionTitle
          weight={500}
          required={required}
          withHelp={helpText !== ""}
        >
          <div>
            <strong>{order} - </strong>
            <span>{title}</span>{" "}
            {helpText !== "" && <Help helpText={helpText} />}
          </div>
          <input
            type="hidden"
            name={`${id}.helpText`}
            value={helpText}
            ref={register}
          />
          <input
            type="hidden"
            name={`${id}.order`}
            value={order}
            ref={register}
          />
          <input
            type="hidden"
            name={`${id}.title`}
            value={title}
            ref={register}
          />
        </SectionTitle>
      </TitleContainer>

      {!readOnly && showWordCounter && (
        <>
          <input
            type="hidden"
            value={wordCounter}
            name={`${id}.wordCounter`}
            ref={register}
          />

          <WordCounter total={wordCounter} current={currentWordCounter} />
        </>
      )}
    </Header>
  );
};

const QuestionWithText = ({
  value,
  id,
  register,
  error,
  handleCounter,
  readOnly,
  required,
}) => {
  return (
    <TextAreaField
      register={register}
      error={error}
      value={value}
      id={`${id}.value`}
      //onCounter={handleCounter}
      readOnly={readOnly}
      required={required}
    />
  );
};

const QuestionWithOptions = memo(
  ({ value, id, register, error, options, readOnly, required }) => {
    return (
      <RadioGroupField
        register={register}
        error={error}
        value={value}
        id={`${id}`}
        options={options}
        readOnly={readOnly}
        required={required}
      />
    );
  }
);

const QuestionWithMultiOptions = memo(
  ({ id, register, errors, options, readOnly, required }) => {
    return (
      <CheckBoxesField
        options={options}
        register={register}
        errors={errors}
        id={`${id}.options`}
        twoLines={true}
        readOnly={readOnly}
        required={required}
      />
    );
  }
);

const QuestionSelection = ({ id, error, question, register, readOnly }) => {
  switch (question.questionType) {
    case "text": {
      return (
        <QuestionWithText
          id={id}
          value={question.value}
          register={register}
          error={error && error.value}
          readOnly={readOnly}
          required={true}
          //handleCounter={handleCounter}
        />
      );
    }
    case "comments": {
      return (
        <QuestionWithText
          id={id}
          //value={question.value}
          register={register}
          error={error && error.value}
          required={false}
          readOnly={readOnly}
        />
      );
    }
    case "ponderation": {
      return (
        <QuestionWithOptions
          id={id}
          value={question.value}
          register={register}
          readOnly={readOnly}
          required={true}
          error={error && error.value}
          options={question.options}
        />
      );
    }
    case "multiple": {
      return (
        <QuestionWithMultiOptions
          id={id}
          register={register}
          readOnly={readOnly}
          required={true}
          error={error && error.option}
          options={question.options}
        />
      );
    }
    default:
      return <span>ERROR</span>;
  }
};

export default function Question({
  prefix,
  question,
  index,
  register,
  errors,
  readOnly,
}) {
  //const [counter, SetCounter] = useState(0);

  let id = `${prefix}.questions[${index}]`;

  let error = errors && errors["questions"] && errors["questions"][index];

  /*const handleCounter = (counter) => {
    SetCounter(counter);
  };*/

  return (
    <Container>
      <input
        type="hidden"
        name={`${id}.id`}
        value={question.id}
        ref={register}
      />

      <input
        type="hidden"
        name={`${id}.questionType`}
        value={question.questionType}
        ref={register}
      />

      <QuestionHeader
        id={id}
        title={question.title}
        order={question.order}
        helpText={question.helpText}
        wordCounter={question.wordCounter}
        register={register}
        //currentWordCounter={counter}
        // showWordCounter={question.questionType === "text"}
        showWordCounter={false}
        required={true}
        readOnly={readOnly}
      />

      <QuestionSelection
        id={id}
        error={error}
        question={question}
        readOnly={readOnly}
        register={register}
      />
    </Container>
  );
}

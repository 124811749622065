import React, { memo } from "react";

import { Container, ContainerItem } from "./styles";

import CrudLoader from "loader/CrudLoader";
import AnimatedControl from "components/AnimatedControl";
import Confirmation from "components/Confirmation";

const ListOf = memo(
  ({
    loading,
    items,
    formItem,
    cardItem,
    itemSelectedId,
    itemDeletedId,
    onDeleteItem,
    onCleanItemId,
  }) => {
    return (
      <Container>
        {loading ? (
          <CrudLoader counter={2} />
        ) : (
          <>
            {items.map((item) => {
              return (
                <ContainerItem key={item.id}>
                  {cardItem(
                    item,
                    itemSelectedId === item.id || item.id === itemDeletedId
                  )}

                  <AnimatedControl
                    condition={item.id === itemSelectedId}
                    control={formItem(item)}
                  />

                  <AnimatedControl
                    condition={item.id === itemDeletedId}
                    control={
                      <Confirmation
                        type="cancel"
                        paramOk={item.id}
                        text={`Desea eliminar ${item.title}?`}
                        onClick={onDeleteItem}
                        cancel={onCleanItemId}
                        paramCancel={false}
                      />
                    }
                  />
                </ContainerItem>
              );
            })}
          </>
        )}
      </Container>
    );
  }
);

export default ListOf;

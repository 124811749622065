import { FieldGroup, FieldLabel, ErrorMessage } from "basics/styles";
import { colors } from "definitions";
import React, { useState } from "react";
import StarIcon from "icons/StarIcon";
import { Stars } from "./styles";

export default function Rate({ criteriaItem, error, mode, register, prefix }) {
  const [hoverStarValue, setHoverStarValue] = useState(0);
  const [starValue, setStarValue] = useState(criteriaItem.value ?? 0);

  const stars = [1, 2, 3, 4, 5];

  const setColor = (newStarValue) => {
    if (hoverStarValue >= newStarValue || starValue >= newStarValue) {
      return "white";
    }

    return `${colors.background.darkBlue}`;
  };

  return (
    <FieldGroup
      className={`field-input-group`}
      style={{ margin: "2rem 1.5rem 10px 1.5rem" }}
    >
      <FieldLabel mode={mode} style={{ fontWeight: "400", fontSize: "1.5rem" }}>
        {criteriaItem.title}
      </FieldLabel>

      <Stars>
        <input
          type="hidden"
          ref={register}
          id={`${prefix}.title`}
          name={`${prefix}.title`}
          value={criteriaItem.title}
        />

        <input
          type="hidden"
          ref={register}
          id={`${prefix}.id`}
          name={`${prefix}.id`}
          value={criteriaItem.id}
        />

        <input
          type="hidden"
          ref={register}
          id={`${prefix}.ponderation`}
          name={`${prefix}.ponderation`}
          value={criteriaItem.ponderation}
        />

        <input
          type="hidden"
          ref={register}
          id={`${prefix}.value`}
          name={`${prefix}.value`}
          value={starValue}
        />

        {stars.map((star) => (
          <StarIcon
            stroke="white"
            strokeWidth="2.5rem"
            onMouseEnter={() => setHoverStarValue(star)}
            onMouseLeave={() => setHoverStarValue(0)}
            onClick={() => setStarValue(star)}
            fill={setColor(star)}
            key={star}
            style={{ margin: "0 5px" }}
          />
        ))}
      </Stars>
      {error && <ErrorMessage mode={mode}>{error.message}</ErrorMessage>}
    </FieldGroup>
  );
}

import styled from 'styled-components';
import { colors, breakpoints } from 'definitions'

export const HeaderContainer = styled.header`
    display: flex;
    background-color: ${colors.background.white};
    justify-content: center;
    align-items: center;
    z-index: 1000;

    ${breakpoints.desktop}
    {
        display:flex;
        justify-content: space-between;
        width:100%;
        width:100%;
    }
`;

export const HeaderContentContainer = styled.div`
    width: 124.4rem;
    
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    height: 8rem;

    ${breakpoints.desktop}
    {
        width: 100%;
        justify-content: space-between;
        max-width: 992px;
    }

    ${breakpoints.tablet}
    {
        margin: 0 1rem;
        width: 100%;
        justify-content: space-between;
        max-width: 768px;
    }
`;

export const White = styled.div`
    width: 100%;
    height: 8rem;
    background-color: white;
    position: absolute;
    z-index: -20;
    left:0;
`;

import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { object, string } from "yup";

import { ErrorMessage } from "basics/styles";

import { InputField } from "basics";

import { Button, Container, Form, Message } from "./styles";

import SpinnerLinear from "components/SpinnerLinear";

import { useTransition } from "react-spring";

import useBreakpoints from "hooks/useBreakpoint";

import { devices } from "definitions";

import useOutsideClose from "hooks/useOutsideClose";

import useAuth from "hooks/useAuth";

import { useLocation } from "wouter";

const LoginForm = ({ onClose, show }) => {
  const { IsGreaterThan } = useBreakpoints();

  const { node } = useOutsideClose(onClose);
  const { loading, waiting, sendLinkMail } = useAuth();
  const [, navigate] = useLocation();
  const [messageError, setMessageError] = useState();

  const validations = {
    userName: string()
      .email("Ingrese un mail con el formato correcto")
      .required("El mail es obligatorio"),
  };

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(object(validations)),
  });

  const transitions = useTransition(show, {
    from: { y: -250, opacity: 0 },
    enter: { y: IsGreaterThan(devices.tabletXL) ? 45 : 80, opacity: 1 },
    leave: { y: -250, opacity: 0 },
  });

  const onSubmit = (data) => {
    setMessageError();

    sendLinkMail(data.userName)
      .then(() => {
        onClose();
        navigate("/");
      })
      .catch((error) => {
        setMessageError(error);
      });
  };

  return (
    <>
      {transitions(
        (style, item) =>
          item && (
            <Container style={style} ref={node}>
              {loading ? (
                <SpinnerLinear location="login" />
              ) : waiting ? (
                <>
                  <Message>Hemos enviado un mail. </Message>
                  <Message>Para ingresar, revise su casilla de correo.</Message>
                </>
              ) : (
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <InputField
                    label="Ingrese su email"
                    type="text"
                    id="userName"
                    register={register}
                    errors={errors}
                  />
                  {messageError !== "" && (
                    <ErrorMessage mode="dark">{messageError}</ErrorMessage>
                  )}
                  <Button>Ingresar</Button>
                </Form>
              )}
            </Container>
          )
      )}
    </>
  );
};

export default LoginForm;

import React from 'react';

import { FormStatus, SelectionMode, EntryListingMode } from 'model/dictionary';

import EntriesPage from 'components/EntriesPage';

export default function Voting() {

	return (
		<EntriesPage
			title="Votación"
			entryListMode={EntryListingMode.VOTING}
			formStatusFiltered={FormStatus.FINALIST}
			selectionMode={SelectionMode.SIMPLE}
		/>
	);

}

import { colors } from "definitions";

export const Roles = [
  {
    value: "administrator",
    label: "Administrador",
    checked: false,
  },
  {
    value: "judge",
    label: "Juez",
    checked: false,
  },
  {
    value: "selector",
    label: "Seleccionador",
    checked: false,
  },
  {
    value: "ceo",
    label: "CEO",
    checked: false,
  },
  {
    value: "press",
    label: "Prensa",
    checked: false,
  },
];

export const Sizes = [
  {
    value: "big",
    label: "Grande",
  },
  {
    value: "medium",
    label: "Mediano",
  },
  {
    value: "small",
    label: "Chico",
  },
];

export const QuestionTypes = [
  {
    value: "text",
    label: "Texto",
  },
  {
    value: "comments",
    label: "Texto Opcional",
  },
  {
    value: "ponderation",
    label: "Ponderacion",
  },
];

export const Ponderation = [
  {
    value: "S",
    label: "Siempre",
  },
  {
    value: "CS",
    label: "Casi Siempre",
  },
  {
    value: "AV",
    label: "Algunas Veces",
  },
  {
    value: "CN",
    label: "Casi Nunca",
  },
  {
    value: "N",
    label: "Nunca",
  },
];

export const FormStatus = [
  { value: "draft", label: "Borrador" },
  { value: "confirmed", label: "Confirmado" },
  { value: "discarded", label: "Descartado" },
  { value: "preview", label: "Autogenerado" },
  { value: "finalist", label: "Finalista" },
  { value: "winner", label: "Ganador" },
];

export const FormStatusColor = [
  { value: "draft", label: `${colors.background.darkBlue}` },
  { value: "confirmed", label: `${colors.background.yellowGreen}` },
  { value: "discarded", label: `${colors.background.lightGray}` },
];

export const StaffPositions = [
  { value: "ceo", label: "CEO" },
  { value: "cio", label: "CIO" },
  { value: "dean", label: "Decano" },
  { value: "director", label: "Director" },
  { value: "official", label: "Funcionario" },
  { value: "investigator", label: "Investigador" },
  { value: "manager", label: "Gerente" },
  { value: "owner", label: "Socio/Owner" },
  { value: "president", label: "Presidente" },
  { value: "it", label: "Profesional IT" },
  { value: "rector", label: "Rector" },
  { value: "other", label: "Otro" },
];

export const States = [
  { value: "06", label: "Buenos Aires" },
  { value: "10", label: "Catamarca" },
  { value: "22", label: "Chaco" },
  { value: "26", label: "Chubut" },
  { value: "02", label: "Ciudad Autónoma de Buenos Aires" },
  { value: "18", label: "Corrientes" },
  { value: "14", label: "Córdoba" },
  { value: "30", label: "Entre Ríos" },
  { value: "34", label: "Formosa" },
  { value: "38", label: "Jujuy" },
  { value: "42", label: "La Pampa" },
  { value: "46", label: "La Rioja" },
  { value: "50", label: "Mendoza" },
  { value: "54", label: "Misiones" },
  { value: "58", label: "Neuquén" },
  { value: "62", label: "Río Negro" },
  { value: "66", label: "Salta" },
  { value: "70", label: "San Juan" },
  { value: "74", label: "San Luis" },
  { value: "78", label: "Santa Cruz" },
  { value: "82", label: "Santa Fe" },
  { value: "86", label: "Santiago del Estero" },
  {
    value: "94",
    label: "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
  },
  { value: "90", label: "Tucumán" },
];

import styled from "styled-components";
import { breakpoints } from "definitions";

export const FooterBar = styled.div`
  display: flex;
  height: 20.4rem;
  background-color: #006EDF;
  justify-content: center;
  padding: 0 2rem;
  ${breakpoints.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  max-width: 124.5rem;
  flex-direction: column;
  align-items: center;
  width: 90%;
  justify-content: center;
  height: 100%;
`;

export const CessiContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: white;
  font-size: 16px;
  font-weight: 700;
`;

export const Divider = styled.hr`
  border: 1px solid white;
  width: 100%;
  margin: 2rem 0;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 124.5rem;

  ${breakpoints.tablet} {
    flex-direction: column;
    margin: 0 auto;
    height: 65%;
  }
`;

export const LegalFooter = styled.span`
  text-align: left;
  color: white;
  font-size: 12px;
  width: 100%;
  ${breakpoints.tablet} {
   text-align: center;
  }
`;

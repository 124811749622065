import styled from 'styled-components';

import { breakpoints } from 'definitions'

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
gap: 1rem;
    ${breakpoints.phablet} {
        svg{
            width: 20px;
            height: 20px;
        }
    }

`;
import React from 'react';

import { InputField } from 'basics';

export default function CriteriaForm({register, errors, item})
{
	return (
		<>
			<InputField
				label="Título"
				type="text"
				value={item && item.title}
				id="title"
				register={register}
				errors={errors}
				mode="dark"
			/>
			<InputField
				label="Ponderación"
				type="number"
				value={item && item.ponderation}
				id="ponderation"
				register={register}
				errors={errors}
				mode="dark"
			/>
		
		</>
	)
}
import React, { useState, useEffect } from "react";
import { MainTitle } from "components/MainTitle";
import AnimatedControl from "components/AnimatedControl";
import { BodyContainer } from "App/styles";
import { SelectField } from "basics";
import useEntries from "hooks/useEntries";
import Confirmation from "components/Confirmation";
import { ContainerAnimated, SectionContainer, Btn } from "./styles";

import { EntryListingMode, FormStatus } from "model/dictionary";

export default function Restauration() {
  const {
    awards,
    awardSelected,
    handleFilter,
    entriesInStatus,
    restoreStatus,
  } = useEntries({ mode: EntryListingMode.ADMINISTRATION });

  const [formOpened, setFormOpened] = useState(false);

  const [restaurationStatus, setRestaurationStatus] = useState();

  const onRestoreStatus = () => {
    onCloseForm();

    restoreStatus(restaurationStatus);
  };

  const onOpenForm = () => {
    setFormOpened(true);
  };

  const onCloseForm = () => {
    setFormOpened(false);
  };

  useEffect(() => {
    setRestaurationStatus();

    entriesInStatus(EntryListingMode.WINNERS).then((result) => {
      if (result.exists) {
        setRestaurationStatus(FormStatus.FINALIST);
      } else {
        entriesInStatus(EntryListingMode.FINALISTS).then((result) => {
          if (result.exists) {
            setRestaurationStatus(FormStatus.CONFIRMED);
          }
        });
      }
    });
  }, [entriesInStatus, awardSelected]);

  return (
    <BodyContainer>
      <MainTitle title="Restauración" seoTitle="Restauración" />

      <SectionContainer>
        <SelectField
          id="award.id"
          value={awardSelected}
          options={awards}
          onChange={handleFilter}
          label="Premios"
          defaultOption="Elija un premio"
        />

        {restaurationStatus && (
          <Btn
            onClick={() => {
              onOpenForm();
            }}
          >
            Restaurar
          </Btn>
        )}
      </SectionContainer>

      <ContainerAnimated>
        <AnimatedControl
          condition={formOpened && awardSelected}
          control={
            <Confirmation
              type="confirm"
              text="¿Desea restaurar las postulaciones al estado anterior?"
              onClick={onRestoreStatus}
              cancel={onCloseForm}
            />
          }
        />
      </ContainerAnimated>
    </BodyContainer>
  );
}

import React from "react";
import { Route, Redirect } from "wouter";

const PrivateRoute = ({ authed, ...rest }) => {
  if (!authed) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
};

export default PrivateRoute;

import React from "react";
import { colors } from "definitions";

export default function YoutubeIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height={props.height}
      width={props.width}
      fill={props.fill}
      aria-hidden="true"
      focusable="false"
      style={{ ...props.style, cursor: "pointer" }}
    >
      <path d="M21.593 7.203a2.506 2.506 0 00-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 00-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 001.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"></path>
    </svg>
  );
}

YoutubeIcon.defaultProps = {
  height: `16px`,
  width: `16px`,
  fill: `${colors.icons.white}`,
};

import React, { Fragment } from "react";

import { Container, Title } from "./styles";

import Question from "components/Question";

import { HorizontalLine } from "App/styles";

export default function Questionnaire({
  sections,
  register,
  errors,
  readOnly,
  tabIndex,
}) {
  return (
    <Container>
      {sections.map((section, sectionIndex) => {
        let prefix = `sections[${sectionIndex}]`;

        const questionErrors =
          errors && errors["sections"] && errors["sections"][sectionIndex];

        return (
          <section key={section.id}>
            <input
              type="hidden"
              value={section.id}
              name={`${prefix}.id`}
              ref={register}
            />

            <input
              type="hidden"
              value={section.title}
              name={`${prefix}.title`}
              ref={register}
            />

            <input
              type="hidden"
              value={section.order}
              name={`${prefix}.order`}
              ref={register}
            />

            {sections.length > 1 && (
              <>
                <Title>
                  <strong>{section.order}</strong> - {section.title}
                </Title>

                <HorizontalLine />
              </>
            )}

            {section.questions.map((question, questionIndex) => {
              return (
                <Question
                  prefix={prefix}
                  key={question.id}
                  question={question}
                  register={register}
                  index={questionIndex}
                  errors={questionErrors}
                  readOnly={readOnly}
                />
              );
            })}
          </section>
        );
      })}
    </Container>
  );
}

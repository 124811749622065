import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';

const firebaseConfig = {
	apiKey: 'AIzaSyA6_9hAqkQEkIsZ1cuzRbGXyj5e1HCYAYY',
	authDomain: 'premios-sadosky.firebaseapp.com',
	databaseURL: 'https://premios-sadosky.firebaseio.com',
	projectId: 'premios-sadosky',
	storageBucket: 'premios-sadosky.appspot.com',
	messagingSenderId: '283236588611',
	appId: '1:283236588611:web:2f5a6702db2cf753bdc8f6',
	measurementId: 'G-0H9QCE0S9M'
};

export const firebaseApp = firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);

export const firebaseDB = firebase.firestore();

export const firebaseStorage = firebase.storage();

export const cloudFunctions = firebase.functions();

export const getDateTimeNow = () => {
	return firebase.firestore.Timestamp.fromDate(new Date());
}

firebase.analytics();
import React, { Fragment } from 'react';

import { InputField } from 'basics';

export default function SectionForm({ register, errors, item }) {
	return (
		<Fragment>
			<InputField
				label="Título"
				type="text"
				value={item && item.title}
				id="title"
				register={register}
				error={errors.title}
				mode="dark"
			/>

			<InputField
				label="Orden"
				type="number"
				value={item && item.order}
				id="order"
				register={register}
				error={errors.order}
				mode="dark"
			/>
		</Fragment>
	);
}

import React from "react";
import { colors } from "definitions";

export default function TwitterIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height={props.height}
      width={props.width}
      fill={props.fill}
      aria-hidden="true"
      focusable="false"
      style={{ ...props.style, cursor: "pointer" }}
    >
      <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 005.001-1.721 4.036 4.036 0 01-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 01-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 01-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 008.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 014.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 002.556-.973 4.02 4.02 0 01-1.771 2.22 8.073 8.073 0 002.319-.624 8.645 8.645 0 01-2.019 2.083z"></path>
    </svg>
  );
}

TwitterIcon.defaultProps = {
  height: `16px`,
  width: `16px`,
  fill: `${colors.icons.white}`,
};

import React from "react";

export default function FaceIcon(props) {
  return (
    <svg
      {...props}
      height={props.height}
      width={props.width}
      style={{ ...props.style, cursor: "pointer" }}
      viewBox="0 0 32 32"
    >
      <path d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2ZM9 13.5a2.5 2.5 0 1 1 2.5 2.5A2.5 2.5 0 0 1 9 13.5ZM22 22H10v-2h12Zm-1.5-6a2.5 2.5 0 1 1 2.5-2.5 2.5 2.5 0 0 1-2.5 2.5Z" />
      <path
        d="M0 0h32v32H0z"
        style={{
          fill: "none",
        }}
      />
    </svg>
  );
}

FaceIcon.defaultProps = {
  height: "6.5rem",
  width: "6.5rem",
  fill: "black",
};

import styled from "styled-components";
import { breakpoints } from "definitions";
import { animated } from "react-spring";
import { colors } from "definitions";
import { YellowButton } from "basics/buttons";

export const Container = styled(animated.div)`
  width: 36rem;
  height: 20rem;
  position: absolute;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  :before {
    content: "▲";
    color: ${colors.fonts.lightGray};
    margin-top: -1.3rem;
    margin-left: 32.5rem;
  }

  ${breakpoints.wide} {
    :before {
      margin-left: 33.5rem;
    }
  }

  ${breakpoints.tabletXL} {
    width: 100%;
    margin-left: 0;
    border-radius: 0px;
    top: 0;
    right: 0;

    z-index: -55550;

    :before {
      content: "";
    }
  }
`;

export const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 15px;

  label {
    color: ${colors.fonts.darkGray};
  }
`;

export const Message = styled.span`
  color: #006EDF;
  display: flex;
  align-items: center;
  padding: 2rem;
  height: 100%;
  line-height: 17px;

  &:nth-of-type(1) {
    font-weight: 600;
    margin-bottom: -15rem;
    font-size: 20px;
  }
`;

export const Button = styled(YellowButton)`
  width: 100%;

  :hover {
    background-color: ${colors.background.white};
    color: ${colors.fonts.darkGray};
  }
`;

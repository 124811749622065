import React, { useEffect, useState, useCallback, Fragment, memo } from "react";
import {
  ComponentItemDescription,
  ComponentItemTitle,
  ComponentContainer,
  ContainerEntryItem,
  Content,
  Line,
  TopicText,
} from "App/styles";
import { BackToLinkButton, Button } from "basics/buttons";
import SquareLoader from "loader/SquareLoader";
import useEntryForm from "hooks/useEntryForm";
import useScroll from "hooks/useScroll";

export function InternalTopics({ onSelected }) {
  const [topics, setTopics] = useState([]);
  const { scrollRef, scrollToRef } = useScroll();

  const { loadingTopics, getTopics } = useEntryForm();

  const handleTopics = useCallback(() => {
    getTopics().then((topics) => {
      setTopics(topics);
      scrollToRef();
    });
  }, [getTopics, scrollToRef]);

  useEffect(() => {
    handleTopics();
  }, [getTopics, handleTopics]);

  return (
    <>
      <ComponentContainer ref={scrollRef}>
        {loadingTopics ? (
          <SquareLoader counter={5} />
        ) : (
          <TopicsList onSelected={onSelected} topics={topics} />
        )}
      </ComponentContainer>

      <BackToLinkButton to="/">Volver al inicio</BackToLinkButton>
    </>
  );
}

const TopicsList = memo(({ topics, onSelected }) => {
  return topics.map((topic) => (
    <TopicItem onSelected={onSelected} key={topic.id} topic={topic} />
  ));
});

const TopicItem = memo(({ topic, onSelected }) => {
  return (
    <ContainerEntryItem key={topic.id} location="topics">
      <Line>
        <TopicText>Temática</TopicText>
      </Line>
      <Content>
        <ComponentItemTitle>{topic.title}</ComponentItemTitle>
        <ComponentItemDescription>{topic.description}</ComponentItemDescription>

        <Button
          onClick={() =>
            onSelected({
              id: topic.id,
              title: topic.title,
            })
          }
        >
          Seleccionar
        </Button>
      </Content>
    </ContainerEntryItem>
  );
});

export const Topics = memo(InternalTopics);

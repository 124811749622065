import React from 'react';

import { InputField } from 'basics';

export default function CompetitionForm({register, setValue, errors, item})
{
	return (
		<>
			<InputField
				label="Año"
				type="number"
				value={item && item.title}
				id="title"
				register={register}
				errors={errors}
				mode="dark"
			/>

			<InputField
				label="Postulación"
				type="date"
				value={item && item.entryStage}
				id="entryStage"
				register={register}
				errors={errors}
				mode="dark"
			/>

			<InputField
				label="Comite de Selección"
				type="date"
				value={item && item.selectionStage}
				id="selectionStage"
				register={register}
				errors={errors}
				mode="dark"
			/>

			<InputField
				label="Votación"
				type="date"
				value={item && item.judgingStage}
				id="judgingStage"
				register={register}
				errors={errors}
				mode="dark"
			/>
		</>
	)
}
import styled from "styled-components";
import { colors, breakpoints } from "definitions";
import { Link } from "wouter";

export const Button = styled.button`
  padding: 8px 16px;
  background-color: #006EDF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
`;

export const YellowButton = styled(Button)`
  background-color: ${colors.background.yellow};
  color: white;

  :hover {
    background-color: ${colors.background.lightGray};
    color: ${colors.fonts.darkGray};
  }

  :disabled {
    background-color: ${colors.background.gray};
    color: ${colors.fonts.white};
    cursor: not-allowed;
  }
`;

export const WhiteButton = styled(Button)`
  background-color: #ffffff;
  color: ${colors.fonts.darkGray};

  :hover {
    background-color: ${colors.background.lightGray};
    color: ${colors.fonts.darkGray};
  }

  :disabled {
    background-color: ${colors.background.gray};
    color: ${colors.fonts.white};
    cursor: not-allowed;
  }
`;

export const MoreButton = styled(YellowButton)`
  height: 65px;
  font-weight: 700;
  font-size: 2.4rem;
  text-transform: capitalize;

  width: 95%;

  max-width: 124.5rem;

  ${breakpoints.tabletXL} {
    width: 80%;
  }
`;

export const RedButton = styled(Button)`
  background-color: ${colors.background.red};

  width: ${({ width }) => (width ? `${width}` : "12.5rem")};

  :hover {
    background-color: ${colors.hover.red};
  }
`;

export const WhiteLargeButton = styled.button`
  background: #ffffff;
  border-radius: 6px;
  height: 4.8rem;
  color: ${colors.fonts.darkGray};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border: none;
  width: 100%;
  cursor: pointer;
`;

export const YellowAnchorButton = styled.a`
  padding: 8px 16px;
  background-color: #006EDF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
`;

export const BackToAnchorButton = styled.a`
  padding: 8px 16px;
  background-color: #006EDF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
  
`;

export const BackToLinkButton = styled(Link)`
 padding: 8px 16px;
  background-color: #006EDF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
  
`;

export const CancelButton = styled(Button)`
  background-color: #f6f7f9;
  color: ${colors.background.red};
  border: 2px solid ${colors.background.red};
  width: ${(props) => (props.width === "40%" ? "40%" : "12.5rem")};
  font-weight: 600;

  :hover {
    background-color: ${colors.background.veryLightGray};
  }
`;

export const DownloadLink = styled(Button)`
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background.yellowGreen};

  :hover {
    background-color: ${colors.background.moderateYellow};
  }

  ${breakpoints.tabletXL} {
    align-self: center;
  }
`;

export const ConfirmButton = styled(Button)`
  background-color: ${({ type }) =>
    type === "cancel"
      ? `${colors.background.red}`
      : `${colors.background.darkBlue}`};

  :hover {
    background-color: ${({ type }) =>
    type === "cancel" ? `${colors.hover.red}` : `${colors.hover.darkBlue}`};
  }
`;

export const ExcelButton = styled(Button)`
  background-color: #1d6f42;

  :hover {
    background-color: #5b9e48;
  }

  :disabled {
    background-color: #5b9e48;
  }
`;

export const CleanButton = styled(Button)`
  margin: 0 1.5rem;

  ${breakpoints.laptop} {
    margin: 0;
  }
`;

import React, { useState } from "react";
import { Container, Legend, TextContent } from "./styles";
import { SectionTitle } from "App/styles";
import { InputField } from "basics";
import { YellowAnchorButton } from "basics/buttons";
import urlParser from "js-video-url-parser";

export default function VideoPlayer({
  title,
  id,
  register,
  error,
  value = "",
  readOnly,
}) {
  const [validUrl, setValidUrl] = useState(false);

  const [videoLink, setVideoLink] = useState(value);

  const HandleYoutubeLink = (field, value) => {
    setVideoLink(value);

    var videoData = urlParser.parse(value);

    if (videoData) {
      setValidUrl(true);
    }
  };

  return (
    <Container>
      <SectionTitle>{title}</SectionTitle>
      {!readOnly && (
        <Legend>
          Recuerde que su video debe estar subido a una plataforma online para que podamos cargarlo en la postulación.
        </Legend>
      )}
      <TextContent>
        <InputField
          label="Ingrese el link del video"
          type="text"
          value={value}
          id={id}
          register={register}
          error={error}
          readOnly={readOnly}
          onChange={HandleYoutubeLink}
          width="100%"
        />
        <YellowAnchorButton
          target="_blank"
          href={videoLink}
          disabled={!validUrl}
        >
          Verificar
        </YellowAnchorButton>
      </TextContent>
    </Container>
  );
}

import React from "react";
import {
  FooterContainer,
  FooterBar,
  CessiContainer,
  Row,
  LegalFooter,
  Divider
} from "./styles";
import Cessi from "icons/logos/Cessi";
import MainLogo from "components/MainLogo";

export default function Footer() {
  return (
    <FooterBar>
      <FooterContainer>
        <Row>
          <MainLogo position="footer" />

          <CessiContainer>
            Una iniciativa de
            <a
              href="https://www.cessi.org.ar/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ height: "100%" }}
            >
              <Cessi />
            </a>
          </CessiContainer>
        </Row>
        <Divider />
        <LegalFooter>{`CESSI ARGENTINA :: Todos los derechos reservados © ${new Date().getFullYear()}`}</LegalFooter>
      </FooterContainer>
    </FooterBar>
  );
}

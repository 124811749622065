import styled from 'styled-components';

export const Container = styled.div`
	max-width: 124.5rem;
	padding-top: 65px;
	width: 95%;
	display: flex;
	flex-direction: column;
`;

export const ContainerItem = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	flex-basis: 49%;
	border-radius: 6px;
`;
import React from "react";

import { InputField, TextAreaField } from "basics";

export default function MentionForm({ register, setValue, errors, item }) {
  console.log(errors);

  return (
    <>
      <InputField
        label="Año"
        inputmode="numeric"
        type="number"
        value={item && item.year}
        id="year"
        register={register}
        errors={errors}
        mode="dark"
        required={true}
      />

      <InputField
        label="Mención"
        value={item && item.title}
        id="title"
        register={register}
        errors={errors}
        mode="dark"
        required={true}
      />

      <TextAreaField
        label="Detalle"
        register={register}
        errors={errors}
        value={item && item.details}
        id="details"
        required={true}
        mode="dark"
      />
    </>
  );
}

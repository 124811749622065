import React, {Fragment} from 'react';
import ContentLoader from "react-content-loader"
import useBreakpoints from 'hooks/useBreakpoint';
import {devices} from 'definitions';

export default function FormLoader() {
   
    const {IsLessThan, width} = useBreakpoints();

    /*Generals*/
    
    const positionRectOneX = (width*5/100);
    const positionCircle = (width*47/100);
    
    const positionLine = (width*5/100)

    const positionCheckboxX = (width*5/100)
    const positionLineCheckboxX = (width*15/100)

    /* Greater than TabletXL Sizes */
    
    const widthRect = (width*40/100);
    const positionRectTwoX = (width*50/100);
    const widthLine = (width*85/100)

    /* Less than TabletXL Sizes */

    const widthRectTablet = (width*80/100);
    const widthLineCheckBox = (width*35/100)
    const widthLineTablet = (width*80/100)


	return (
        <Fragment>
        {	IsLessThan(devices.tablet) ? 

        <ContentLoader 
        speed={2}
        width={width}
        height={'1200'}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        >
            <circle cx={positionCircle} cy="190" r="130" />


            <rect x={positionRectOneX} y="420" rx="0" ry="0" width={widthRectTablet} height="17" /> 
            <rect x={positionRectOneX} y="450" rx="0" ry="0" width={widthRectTablet} height="25" /> 

            <rect x={positionRectOneX} y="520" rx="0" ry="0" width={widthRectTablet} height="17" /> 
            <rect x={positionRectOneX} y="550" rx="0" ry="0" width={widthRectTablet} height="25" /> 

            <rect x={positionRectOneX} y="620" rx="0" ry="0" width={widthRectTablet} height="17" /> 
            <rect x={positionRectOneX} y="650" rx="0" ry="0" width={widthRectTablet} height="25" /> 

            <rect x={positionRectOneX} y="720" rx="0" ry="0" width={widthRectTablet} height="17" /> 
            <rect x={positionRectOneX} y="750" rx="0" ry="0" width={widthRectTablet} height="25" /> 

            <rect x={positionRectOneX} y="820" rx="0" ry="0" width={widthRectTablet} height="17" /> 
            <rect x={positionRectOneX} y="850" rx="0" ry="0" width={widthRectTablet} height="25" /> 

            <rect x={positionRectOneX} y="920" rx="0" ry="0" width={widthRectTablet} height="17" /> 
            <rect x={positionRectOneX} y="950" rx="0" ry="0" width={widthRectTablet} height="25" /> 

            <rect x={positionLine} y="1050" rx="0" ry="0" width={widthLineTablet} height="5" /> 

            <rect x={positionCheckboxX} y="1100" rx="0" ry="0" width="30" height="30" />
            <rect x={positionLineCheckboxX} y="1102" rx="0" ry="0" width={widthLineCheckBox} height="25" /> 


        </ContentLoader>

        : (IsLessThan(devices.wide)) ? 

        <ContentLoader 
        speed={2}
        width={width}
        height={880}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        >
            <circle cx={positionCircle} cy="190" r="150" />


            <rect x={positionRectOneX } y="420" rx="0" ry="0" width={widthRect} height="17" /> 
            <rect x={positionRectOneX } y="450" rx="0" ry="0" width={widthRect} height="25" /> 

            <rect x={positionRectOneX } y="520" rx="0" ry="0" width={widthRect} height="17" /> 
            <rect x={positionRectOneX } y="550" rx="0" ry="0" width={widthRect} height="25" /> 

            <rect x={positionRectOneX } y="620" rx="0" ry="0" width={widthRect} height="17" /> 
            <rect x={positionRectOneX } y="650" rx="0" ry="0" width={widthRect} height="25" /> 



            <rect x={positionRectTwoX } y="420" rx="0" ry="0" width={widthRect} height="17" /> 
            <rect x={positionRectTwoX } y="450" rx="0" ry="0" width={widthRect} height="25" /> 

            <rect x={positionRectTwoX } y="520" rx="0" ry="0" width={widthRect} height="17" /> 
            <rect x={positionRectTwoX } y="550" rx="0" ry="0" width={widthRect} height="25" /> 

            <rect x={positionRectTwoX } y="620" rx="0" ry="0" width={widthRect} height="17" /> 
            <rect x={positionRectTwoX } y="650" rx="0" ry="0" width={widthRect} height="25" /> 

            <rect x={positionLine} y="750" rx="0" ry="0" width={widthLine} height="5" /> 

            <rect x={positionCheckboxX} y="800" rx="0" ry="0" width="30" height="30" />
            <rect x={positionLineCheckboxX} y="802" rx="0" ry="0" width="340" height="25" /> 

        </ContentLoader>

        :

        <ContentLoader 
        speed={2}
        width={width}
        height={500}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        >
            <circle cx="190" cy="190" r="150" /> 


            <rect x="400" y="56" rx="0" ry="0" width="340" height="17" /> 
            <rect x="400" y="86" rx="0" ry="0" width="340" height="25" /> 

            <rect x="400" y="156" rx="0" ry="0" width="340" height="17" /> 
            <rect x="400" y="186" rx="0" ry="0" width="340" height="25" />

            <rect x="400" y="256" rx="0" ry="0" width="340" height="17" /> 
            <rect x="400" y="286" rx="0" ry="0" width="340" height="25" /> 


            <rect x="780" y="56" rx="0" ry="0" width="340" height="17" /> 
            <rect x="780" y="86" rx="0" ry="0" width="340" height="25" /> 

            <rect x="780" y="156" rx="0" ry="0" width="340" height="17" /> 
            <rect x="780" y="186" rx="0" ry="0" width="340" height="25" />

            <rect x="780" y="256" rx="0" ry="0" width="340" height="17" /> 
            <rect x="780" y="286" rx="0" ry="0" width="340" height="25" /> 

            <rect x="50" y="370" rx="0" ry="0" width="1085" height="5" /> 

            <rect x="50" y="420" rx="0" ry="0" width="30" height="30" />
            <rect x="95" y="422" rx="0" ry="0" width="340" height="25" /> 
        </ContentLoader>
        }
        </Fragment>
	)
}

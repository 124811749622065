import React, { useCallback } from "react";
import styled from "styled-components";

export default function Spinner({ size, blur, position }) {
  const getSize = useCallback(() => {
    let width;

    if (size === "small") {
      width = 90;
    } else if (size === "medium") {
      width = 110;
    } else {
      width = 140;
    }

    return `${width}`;
  }, [size]);

  return (
    <StyledSpinner blur={blur} size={getSize()} position={position}>
      <div className="cssload-container">
        <div className="cssload-whirlpool"></div>
      </div>
    </StyledSpinner>
  );
}

const StyledSpinner = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  backdrop-filter: ${({ blur }) => (blur === true ? "blur(4px)" : "")};
  position: absolute;

  .cssload-container {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .cssload-whirlpool,
  .cssload-whirlpool::before,
  .cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 3px solid rgb(190, 213, 59);
    border-left-color: rgb(8, 37, 90);
    border-radius: 2622px;
    -o-border-radius: 2622px;
    -ms-border-radius: 2622px;
    -webkit-border-radius: 2622px;
    -moz-border-radius: 2622px;
  }

  .cssload-whirlpool {
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};

    margin: ${({ position }) =>
      position === "left"
        ? "auto 0 auto 0"
        : position === "right"
        ? "auto 0 auto 0"
        : "auto"};
    top: 0;
    left: ${({ position }) =>
      position === "left" ? "20px" : position === "right" ? "auto" : 0};
    right: ${({ position }) =>
      position === "right" ? "20px" : position === "left" ? "auto" : 0};
    bottom: 0;

    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
  }

  .cssload-whirlpool::before {
    content: "";
    margin: ${({ size }) =>
      size === "90"
        ? `${(size * -3.9) / 10}px`
        : size === "110"
        ? `${(size * -4.28) / 10}px`
        : `${(size * -4.2) / 10}px`};

    height: ${({ size }) => `${size - 20}px`};
    width: ${({ size }) => `${size - 20}px`};

    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
  }

  .cssload-whirlpool::after {
    content: "";
    margin: ${({ size }) =>
      size === "90"
        ? `${(size * -3.9) / 10 + 12}px `
        : size === "110"
        ? `${(size * -4.28) / 10 + 12}px`
        : `${(size * -4.2) / 10 + 12}px`};

    height: ${({ size }) => `${size - 45}px`};
    width: ${({ size }) => `${size - 45}px`};

    animation: cssload-rotate 2300ms linear infinite;
    -o-animation: cssload-rotate 2300ms linear infinite;
    -ms-animation: cssload-rotate 2300ms linear infinite;
    -webkit-animation: cssload-rotate 2300ms linear infinite;
    -moz-animation: cssload-rotate 2300ms linear infinite;
  }

  @keyframes cssload-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes cssload-rotate {
    100% {
      -o-transform: rotate(360deg);
    }
  }

  @-ms-keyframes cssload-rotate {
    100% {
      -ms-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes cssload-rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes cssload-rotate {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
`;

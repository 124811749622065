import React from 'react'
import { colors } from 'definitions';

export default function UploadIcon(props) {
    return (
        <svg viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill={props.color} style={{ ...props.style, width: "15rem", height: "15rem", cursor: "pointer"}}>
            <path d="M15.213 6.639c-.276 0-.546.025-.809.068C13.748 4.562 11.716 3 9.309 3c-2.939 0-5.32 2.328-5.32 5.199 0 .256.02.508.057.756a3.567 3.567 0 00-.429-.027C1.619 8.928 0 10.51 0 12.463S1.619 16 3.617 16H8v-4H5.5L10 7l4.5 5H12v4h3.213C17.856 16 20 13.904 20 11.32c0-2.586-2.144-4.681-4.787-4.681z"></path>
        </svg>
    )
}

UploadIcon.defaultProps = {
    height: `15rem`,
    width: `15rem`,
    color: `${colors.icons.lightGray}`
};

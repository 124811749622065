import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';
import {widths} from 'definitions'

export default function useBreakpoint() {
	const [ width, setWidth ] = useState(() => window.innerWidth);

	const IsLessThan = (device) => {
		return (width <= widths[device]);
	}

	const IsGreaterThan = (device) => {
		return (width >= widths[device]);
	}

	useEffect(() => {
		const calcInnerWidth = throttle(function() {
			setWidth(window.innerWidth);
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

	return {width, IsLessThan, IsGreaterThan};
}

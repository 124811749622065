import React, {useEffect, useState} from 'react';

import { InputField, CheckBoxesField } from 'basics';

import { Roles } from 'model/options';

export default function TopicForm({register, errors, item})
{
	const [awardRoles, setAwardRoles] = useState([]);

	useEffect(()=>{

		if (item && item.roles)
		{
			let roles = [];

			Roles.forEach(i => {

				const rol = item.roles.filter(rol => rol.value === i.value);
	
				roles.push(rol.length > 0 ? rol[0] : i);
			});	

			setAwardRoles(roles);
		}
		else
		{
			setAwardRoles(Roles);
		}
		
	}, [item]);

	return (
		<>
			<InputField
				label="Título"
				type="text"
				value={item && item.title}
				id="title"
				register={register}
				errors={errors}
				mode="dark"
			/>
			<InputField
				label="Descripción"
				type="text"
				value={item && item.description}
				id="description"
				register={register}
				errors={errors}
				mode="dark"
			/>
			<CheckBoxesField
				options={awardRoles}
				register={register}
				error={errors.roles}
				id="roles"
				label="Roles Asociados"
				mode="dark"
				twoLines={true}
			/>
		</>
	)
}
import * as React from "react"
import { icons } from 'definitions';

export default function SectionIcon(props) {
  return (
    <svg height={icons.entities.height} width={icons.entities.width} fill={icons.entities.color} viewBox="0 0 512 512" {...props} style={{ ...props.style, cursor: 'pointer' }}>
      <path d="M175.721 410.252C78.828 410.252 0 331.424 0 234.531S78.828 58.81 175.721 58.81c6.903 0 12.5 5.597 12.5 12.5v150.721h150.721c6.902 0 12.5 5.597 12.5 12.5-.001 96.893-78.829 175.721-175.721 175.721zm-12.5-325.928C85.941 90.7 25 155.634 25 234.531c0 83.107 67.613 150.721 150.721 150.721 78.897 0 143.831-60.939 150.207-138.221H175.721c-6.903 0-12.5-5.598-12.5-12.5V84.324z" />
      <path d="M397.752 188.221H234.531c-6.903 0-12.5-5.597-12.5-12.5V12.5c0-6.903 5.597-12.5 12.5-12.5 96.893 0 175.721 78.828 175.721 175.721 0 6.903-5.597 12.5-12.5 12.5zm-150.721-25h137.707C378.702 90.065 320.187 31.55 247.031 25.514v137.707z" />
    </svg>
  )
}

import React from 'react';
import ContentLoader from "react-content-loader"
import useBreakpoints from 'hooks/useBreakpoint';
import {devices} from 'definitions';
import {ContainerItem} from 'components/ListOf/styles'
import {Container} from 'basics/cards'

export default function CrudLoader({counter}) {
	
	const {IsLessThan} = useBreakpoints();

	let numbers = Array.from(Array(counter).keys());

	return (
		<>
			{numbers.map((number) => (
				<ContainerItem key={number}>
					<Container>

						{	IsLessThan(devices.mobile) ? 
								<ContentLoader 
								speed={2}
								width={304}
								height={178}
								viewBox="0 0 304 178"
								backgroundColor="#f3f3f3"
								foregroundColor="#ecebeb"
								>
									<circle cx="40" cy="75" r="18" /> 
									<rect x="70" y="16" rx="0" ry="0" width="121" height="22" /> 
									<rect x="70" y="55" rx="0" ry="0" width="217" height="17" /> 
									<rect x="70" y="90" rx="0" ry="0" width="217" height="17" /> 
									<rect x="120" y="140" rx="6" ry="6" width="28" height="28" />
									<rect x="160" y="140" rx="6" ry="6" width="28" height="28" />
								</ContentLoader>

							: (IsLessThan(devices.phablet)) ? 
								<ContentLoader 
								speed={2}
								width={494}
								height={208}
								viewBox="0 0 494 208"
								backgroundColor="#f3f3f3"
								foregroundColor="#ecebeb"
								>
									<circle cx="100" cy="75" r="28" /> 
									<rect x="150" y="26" rx="0" ry="0" width="151" height="22" /> 
									<rect x="150" y="65" rx="0" ry="0" width="267" height="17" /> 
									<rect x="150" y="100" rx="0" ry="0" width="267" height="17" /> 
									<rect x="220" y="160" rx="6" ry="6" width="38" height="38" />
									<rect x="280" y="160" rx="6" ry="6" width="38" height="38" />
								</ContentLoader>

							:

								<ContentLoader 
								speed={2}
								width={1215}
								height={60}
								viewBox="0 0 1215 60"
								backgroundColor="#f3f3f3"
								foregroundColor="#ecebeb"
								>
									<rect x="13" y="9" rx="100" ry="100" width="40" height="40" /> 
									<rect x="63" y="20" rx="0" ry="0" width="142" height="16" /> 
									<rect x="212" y="22" rx="0" ry="0" width="178" height="13" /> 
									<rect x="399" y="22" rx="0" ry="0" width="178" height="13" /> 
									<rect x="1080" y="14" rx="6" ry="6" width="28" height="28" /> 
									<rect x="1150" y="14" rx="6" ry="6" width="28" height="28" />
								</ContentLoader>
						}
					</Container>
				</ContainerItem>
			
			))}
		</>
	)
}

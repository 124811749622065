import styled from "styled-components";
import { addOpacityToColor } from "helpers";
import { colors, breakpoints } from "definitions";

import Logo from "assets/logo-sadosky.webp";

export const Container = styled.div`
  max-width: 124.5rem;
  padding-top: 2rem;

  ${breakpoints.max} {
    width: 95%;
  }

  ${breakpoints.tablet} {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    justify-content: space-around;
  }

  ${breakpoints.mobile} {
    width: 95%;
  }
`;

export const EntryItemContainer = styled.div`
  box-shadow: ${({ showBorder }) =>
    showBorder ? "4px 8px 8px #353535" : "4px 4px 8px rgba(0, 0, 0, 0.15)"};
  border-radius: ${({ selected, mode }) =>
    selected || mode === "finalists" || mode === "winners"
      ? "6px 6px 0 0"
      : "6px"};
  background-color: #ffffff;
  border-color: ${({ showBorder }) =>
    showBorder ? `${colors.background.darkBlue}` : ""};
  border-width: ${({ showBorder }) => (showBorder ? "5px" : "")};
  border-style: ${({ showBorder }) => (showBorder ? "solid" : "")};

  display: flex;
  align-items: center;

  height: 15rem;

  border-bottom: ${({ showBorder, selected, mode }) =>
    showBorder && selected && (mode === "finalists" || mode === "winners")
      ? `5px solid ${colors.background.darkBlue}`
      : "none"};

  margin-bottom: ${({ selected, mode }) =>
    selected || mode === "finalists" || mode === "winners" ? "0px" : "2rem"};

  ${breakpoints.tablet} {
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 35rem;
    margin: 2.5rem 0;
    margin-bottom: ${({ selected, mode }) =>
      selected || mode === "finalists" || mode === "winners" ? "0px" : "2rem"};
    justify-content: center;
  }
`;

export const ImageItem = styled.div`
  border-radius: 0px;
  background-image: ${({ image }) => `url(${image === "" ? Logo : image})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  z-index: 1;
  width: 100%;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(156, 156, 153, 0.2);
    z-index: -1;
  }

  ${breakpoints.tablet} {
    border-radius: ${({ showBorder }) =>
      showBorder ? "0 0 0 0" : "0 6px 0 0 "};
    background-size: 87%;
    background-position-y: center;

    flex-basis: auto;
  }
`;

export const State = styled.div`
  background: ${({ color }) => `${addOpacityToColor(color, 0.8)}`};
  position: relative;
  width: 15%;
  display: flex;
  align-items: center;
  border-radius: ${({ showBorder, mode, selected }) =>
    showBorder
      ? "0 0 0 0"
      : mode === "finalists" || mode === "winners" || selected
      ? "4px 0 0 0"
      : "4px 0 0 4px"};
  justify-content: center;

  ${breakpoints.tablet} {
    border-radius: 4px 0 0px 0px;
  }
`;

export const SectionOne = styled.div`
  display: flex;
  flex-direction: row; /*Change */
  height: ${({ showBorder }) => (showBorder ? "101%" : "100%")};
  width: 20%;
  margin-left: ${({ showBorder }) => (showBorder ? "-1px" : "")};

  ${breakpoints.tablet} {
    width: 100%;
    min-height: 15rem;
  }
`;

export const Section = styled.div`
  /*NEW */
  display: flex;
  flex-basis: 60%;
  justify-content: space-between;
  height: auto;

  ${breakpoints.tablet} {
    height: auto;
    flex-basis: auto;
    flex-direction: column;
    align-self: flex-start;
  }
`;

export const ItemDescription = styled.div`
  /*NEW */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-basis: 100%;

  margin-left: 2.4rem;
  position: relative;

  ${breakpoints.tablet} {
    margin: 0 auto;
    width: auto;
    padding: 1rem 2rem;
  }
`;

export const Name = styled.span`
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 22px;
  color: #666666;
  margin-bottom: 0.3rem;
  margin-right: 0.5rem;

  ${breakpoints.tablet} {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 17px;
  color: #333333;
  margin-bottom: 0.3rem;

  ${breakpoints.tablet} {
    margin-bottom: 1rem;
  }
`;

export const ProjectDescription = styled.span`
  font-weight: 600;
  color: #333333;
  font-size: 1.2rem;
  line-height: 17px;
  margin-bottom: 0.3rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${breakpoints.tablet} {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;

export const ProjectDate = styled.span`
  font-weight: normal;
  color: #333333;
  font-size: 1.2rem;
  line-height: 17px;
  margin-bottom: 0.3rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Icons = styled.div`
  display: flex;
  flex-basis: 20%;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-right: 10px;

  ${breakpoints.tablet} {
    margin-bottom: 1rem;
    align-self: flex-end;
    width: ${({ selected }) => (selected ? "7rem" : "18rem")};
    align-items: ${({ selected }) => (selected ? "center" : "baseline")};
    margin-top: 1rem;
    flex-basis: 0;
  }
`;

export const Divider = styled.div`
  background: ${colors.background.lightGray};

  height: 80%;
  width: 0.1rem;

  ${breakpoints.tablet} {
    width: 90%;
    height: 0.1rem;
    background: ${colors.background.lightGray};
  }
`;

export const ProjectSelection = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 22.1%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  label:first-of-type {
    margin-bottom: 0;

    span:first-of-type {
      width: 25.5px;
      height: 25.5px;
    }
  }

  ${breakpoints.laptop} {
    margin: 0 auto;
    padding: 2rem;

    label:first-of-type {
      width: 13rem;
    }
  }

  ${breakpoints.tablet} {
    height: 19.5%;

    label:first-of-type {
      width: auto;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

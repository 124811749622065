import { breakpoints } from 'definitions';
import styled from 'styled-components';

export const ContainerAnimated = styled.div`
    width: 95%;
    max-width: 124.5rem;

    ${breakpoints.tablet}{
        width: 80%;
    }
`;
import React from "react";
import { colors } from "definitions";

export default function EyeIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height={props.height}
      width={props.width}
      aria-hidden="true"
      focusable="false"
      fill="none"
      stroke={props.color}
      style={{ ...props.style, cursor: "pointer" }}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
      ></path>
    </svg>
  );
}

EyeIcon.defaultProps = {
  height: `3rem`,
  width: `3rem`,
  color: `${colors.icons.gray}`,
};

import styled from "styled-components";
import { colors, breakpoints } from "definitions";

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const MessageForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 61.1rem;
  align-items: center;

  ${breakpoints.phablet} {
    width: auto;
  }
`;

export const CompletedMessage = styled.h3`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  line-height: 49px;
  display: flex;
  text-align: center;
  color: ${colors.fonts.gray};

  &:nth-of-type(1) {
    font-weight: bold;
    margin-bottom: 0.8rem;
    margin-top: 5rem;
  }

  &:nth-of-type(2) {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  ${breakpoints.phablet} {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }

  ${breakpoints.mobile} {
    width: 20rem;
    justify-content: center;

    &:nth-of-type(2) {
      margin-top: 1rem;
    }
  }
`;

export const LogoImage = styled.img`
  width: 637px;
  height: 650px;

  ${breakpoints.phablet} {
    width: 50%;
    height: 50%;
  }
`;

export const SocialNetworksContent = styled.div`
  height: auto;
`;

export const EmailMessage = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.fonts.veryDarkGray};
  margin: 0.5rem;

  &:nth-of-type(1) {
    font-weight: 800;
    text-align: center;
  }
`;

import React from 'react';

import { FormStatus, SelectionMode, EntryListingMode } from 'model/dictionary';
import EntriesPage from 'components/EntriesPage';

export default function Winners() {

	return (
		<EntriesPage
			title="Ganadores"
			entryListMode={EntryListingMode.WINNERS}
			formStatusFiltered={FormStatus.FINALIST}
			formStatusConfirmed={FormStatus.WINNER}
			selectionMode={SelectionMode.SIMPLE}
		/>
	);
}

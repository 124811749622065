import React from "react";

import { FieldSeparator } from "App/styles";

import {
  Container,
  MainSection,
  CardMainText,
  CardSecondaryText,
  Details,
} from "basics/cards";

import ItemCardToolBar from "components/ItemCardToolBar";

import AwardIcon from "icons/entities/AwardIcon";
import SectionIcon from "icons/entities/SectionIcon";
import { Link } from "wouter";

export default function AwardCard({
  item,
  selected,
  topicId,
  onSelectEditedItem,
  onCleanItemId,
  onSelectDeletedItem,
}) {
  return (
    <Container selected={selected}>
      <MainSection>
        <AwardIcon />
        <CardMainText>{item.title}</CardMainText>
      </MainSection>

      <ItemCardToolBar
        id={item.id}
        actived={item.actived}
        selected={selected}
        onCleanItemId={onCleanItemId}
        onSelectEditedItem={onSelectEditedItem}
        onSelectDeletedItem={onSelectDeletedItem}
      >
        <Link to={`/administracion/secciones/${topicId}/${item.id}`}>
          <SectionIcon />
        </Link>
      </ItemCardToolBar>
    </Container>
  );
}

import React, { useState } from "react";
import Modal from "components/Modal";
import styled, { keyframes } from "styled-components";
import { yupResolver } from "@hookform/resolvers";
import { object, string, number } from "yup";
import { useForm } from "react-hook-form";
import { TextAreaField } from "basics";
import EvaluationRate from "components/EvaluationRate";
import { YellowButton } from "basics/buttons";
import { colors } from "definitions";

export const Form = styled.form`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 2rem;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SuccessMessage = styled.div`
  color: ${colors.background.yellowGreen};
  font-weight: bold;
  text-align: center;
  animation: ${fadeIn} 1.5s ease-in-out;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  line-height: 2.5rem;
`;

const schema = object().shape({
  evaluation: number().typeError("Debe seleccionar una opción"),
  comments: string().required("Los comentarios son obligatorios"),
});

function Survey({ onClose, showSurvey, email }) {
  if (!showSurvey) return null;

  return (
    <Modal
      locked
      onClose={() => {
        onClose();
      }}
      open={showSurvey}
    >
      <SurveyForm onClose={onClose} email={email} />
    </Modal>
  );
}

const formId = "1FAIpQLSe3DrMkePOD3NRBOWyj41jjy0JJiSprLK_kqjoY02KEtojt9g";

const SurveyForm = ({ onClose, email }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onSubmit = async ({ evaluation, comments }) => {
    const formData = new FormData();
    formData.append("entry.1443363499", evaluation);
    formData.append("entry.929236214", comments);
    formData.append("entry.2136000645", email);

    fetch(`https://docs.google.com/forms/u/4/d/e/${formId}/formResponse`, {
      method: "POST",
      mode: "no-cors",
      body: formData,
    })
      .then((response) => {
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          onClose();
        }, 3000);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });
  };

  return (
    <div style={{ height: "37rem", width: "370px" }}>
      {showSuccessMessage ? (
        <SuccessMessage>¡Gracias por completar la encuesta!</SuccessMessage>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <EvaluationRate
            register={register}
            id="evaluation"
            error={errors && errors.evaluation}
          />

          <TextAreaField
            label="Ayudanos a mejorar, dejanos tus comentarios"
            register={register}
            errors={errors}
            id="comments"
            style={{ rows: "5" }}
          />

          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <YellowButton type="submit" disabled={!errors}>
              Enviar
            </YellowButton>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Survey;

import React from "react";

import { useRoute } from "wouter";

import {
  HeroContainer,

} from "./styles";

import MainVideo from "components/MainVideo";

export default function Hero() {
  const [match] = useRoute("/");

  return (
    <HeroContainer isHome={match}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: "50%",
          backgroundColor: "#000000",
          zIndex: "0",
        }}
      ></div>
      {match && (
        <MainVideo />)}
    </HeroContainer>
  );
}

import React, { useState } from "react";
import InformationIcon from "icons/InformationIcon";
import Portal from "components/Portal";
import TooltipPopover from "components/TooltipPopover";
import useOutsideClose from "hooks/useOutsideClose";

export default function Help({ helpText }) {
  const [isOn, setOn] = useState(false); // toggles dropdown visibility
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const { node } = useOutsideClose(() => setOn(false));

  const updateTooltipCoords = (button) => {
    const rect = button.getBoundingClientRect();

    setCoords({
      left: rect.x + rect.width / 2, // add half the width of the button for centering
      top: rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  return (
    <>
      <button
        style={{ border: "none", backgroundColor: "transparent" }}
        ref={node}
        onClick={(e) => {
          e.preventDefault();
          updateTooltipCoords(e.target);
          setOn(!isOn);
        }}
      >
        <InformationIcon width="25px" height="25px" color="#006EDF" />
      </button>

      {isOn && (
        <Portal>
          <TooltipPopover
            coords={coords}
            updateTooltipCoords={() => {
              updateTooltipCoords(node.current);
            }}
          >
            <div>{helpText}</div>
          </TooltipPopover>
        </Portal>
      )}
    </>
  );
}

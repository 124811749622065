import React, { useState, useEffect, useContext } from "react";
import { Container } from "./styles";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { object, array, string, number } from "yup";
import { WhiteLargeButton } from "basics/buttons";
import useEntries from "hooks/useEntries";

import useFirestore from "hooks/useFirestore";
import { AuthContext } from "contexts/AuthContext";

import Spinner from "components/Spinner";
import Rate from "components/Rate";

const schema = object({
  criteria: array(
    object({
      id: string(),
      title: string(),
      ponderation: number(),
      value: string().required("Debe seleccionar un valor"),
    })
  ),
});

export default function Rating({ onClose, entry, formOpened }) {
  const { getCriteria } = useEntries();

  const [loading, setLoading] = useState();

  const [criteriaFiltered, setCriteriaFiltered] = useState([]);

  const { getItem } = useFirestore();

  const { currentUser } = useContext(AuthContext);

  const { updateCriteria } = useEntries();

  useEffect(() => {
    setLoading(true);

    getItem(`entries/${entry.id}/criteria`, currentUser.uid).then((result) => {
      if (result.exists) {
        setCriteriaFiltered(result.item.criteria);
        setLoading(false);
      } else {
        getCriteria().then((criteria) => {
          getItem(`topics/${entry.topic.id}/awards`, entry.award.id).then(
            (result) => {
              if (result.item.criteria) {
                setCriteriaFiltered(
                  criteria.filter((el) => result.item.criteria.includes(el.id))
                );
              }

              setLoading(false);
            }
          );
        });
      }
    });
  }, [currentUser, entry, getItem, getCriteria]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    updateCriteria(data, entry.id).then(() => {
      onClose();
    });
  };

  return (
    <Container>
      {loading && <Spinner size="medium" blur={true} />}

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          width: "100%",
          flexFlow: "wrap",
          justifyContent: "center",
        }}
      >
        {criteriaFiltered.map((criteriaItem, index) => (
          <Rate
            mode="dark"
            key={criteriaItem.id}
            criteriaItem={criteriaItem}
            register={register}
            prefix={`criteria[${index}]`}
            error={errors && errors["criteria"] && errors["criteria"][index]}
          />
        ))}
        <WhiteLargeButton
          type="submit"
          style={{ width: "100%", marginTop: "4rem" }}
        >
          Guardar cambios
        </WhiteLargeButton>
      </form>
    </Container>
  );
}

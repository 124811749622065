import styled from "styled-components";
import { colors, breakpoints } from "definitions";

export const Container = styled.div`
  box-shadow: ${(props) =>
    !props.selected
      ? "4px 4px 8px rgba(0, 0, 0, 0.15)"
      : "4px 0px 8px rgba(0, 0, 0, 0.15)"};
  border-radius: ${(props) => (!props.selected ? "6px" : "6px 6px 0 0")};

  background-color: ${colors.background.white};

  display: flex;
  align-items: center;

  height: 10rem;
  flex-basis: 86%;

  z-index: 5;

  padding: 15px;

  ${breakpoints.tabletXL} {
    align-items: center;
  }

  ${breakpoints.phablet} {
    flex-direction: column;
    height: auto;
    min-height: 168px;
  }
`;

export const MainSection = styled.div`
  display: flex;
  flex-basis: 86%;
  justify-content: flex-start;
  margin-left: 2rem;
  width: 86%;

  svg {
    margin-right: 2rem;
  }

  ${breakpoints.tabletXL} {
    margin-left: 0;
    width: 100%;
    align-items: center;
  }
`;

export const IconsSection = styled.div`
  display: flex;
  flex-basis: 14%;
  justify-content: space-evenly;
  width: 14%;

  ${breakpoints.tabletXL} {
    align-items: center;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ${breakpoints.tabletXL} {
    flex-direction: column;
    align-items: baseline;
    align-self: auto;
    margin-bottom: 15px;
  }
`;

export const CardMainText = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 22px;
  color: #666666;
  white-space: nowrap;
  display: block;

  ${breakpoints.tabletXL} {
    text-overflow: ellipsis;
    max-width: 240px;
    margin: 8px 0;
  }
`;

export const CardLargeText = styled.span`
  font-size: 1.6rem;
  line-height: 22px;
  color: #666666;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  }
  ${breakpoints.tabletXL} {
    
    margin: 8px 0;
  }
`;

export const CardNumber = styled.span`
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 22px;
  color: #666666;
`;

export const CardSecondaryText = styled.span`
  font-weight: ${(props) => `${props.weight}`};

  font-size: 1.4rem;
  line-height: 17px;
  color: #333333;

  ${breakpoints.tabletXL} {
    text-overflow: ellipsis;
    margin: 4px 0;
  }
`;

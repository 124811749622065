import React from "react";

import { Roles } from "model/options";

import { SelectField, InputField } from "basics";

import { FilterContainer } from "components/ToolBar/styles";

export default function UserToolbar({ updateFiltering }) {
  const handleTextFilter = (field, value) => {
    updateFiltering(field, ">=", value.length >= 3 ? value : undefined);
  };

  const handleSelectArrayFilter = (field, value) => {
    updateFiltering(field, "array-contains", value);
  };

  return (
    <FilterContainer>
      <SelectField
        options={Roles}
        id="roles"
        label="Filtrar por Rol"
        defaultOption="Todos los roles"
        onChange={handleSelectArrayFilter}
      />
      <InputField
        label="Filtrar por nombre"
        type="text"
        id="title"
        placeholder="ingrese por lo menos 3 letras"
        onChange={handleTextFilter}
      />
    </FilterContainer>
  );
}

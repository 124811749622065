import { firebaseApp, cloudFunctions } from "firebase/client";

import { useState } from "react";

export default function useAuth() {
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const sendLinkMail = (userName) => {
    setLoading(true);

    var validateUser = cloudFunctions.httpsCallable("validateUser");

    let promise = new Promise((resolve, reject) => {
      validateUser({
        email: userName,
      }).then((result) => {
        setLoading(false);

        if (!result.data) {
          reject("Usuario sin autorización");
        } else {
          var actionCodeSettings = {
            url: `${window.location.origin}?cartId=1234`,
            handleCodeInApp: true,
          };

          setWaiting(true);

          firebaseApp
            .auth()
            .sendSignInLinkToEmail(userName, actionCodeSettings)
            .then((result) => {
              window.localStorage.setItem("emailForSignIn", userName);

              resolve(result);
            })
            .catch((error) => {
              if (error.code === "auth/user-not-found") {
                reject("Usuario sin autorización");
              } else {
                reject("Error al tratar de ingresar al sistema");
              }
            })
            .finally(() => {
              setLoading(false);
              setWaiting(false);
            });
        }
      });
    });

    return promise;
  };

  const signOut = () => {
    setLoading(true);

    let promise = new Promise((resolve, reject) => {
      firebaseApp
        .auth()
        .signOut()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    });

    return promise;
  };

  const createUser = async (userName, displayName, roles, awards) => {
    setLoading(true);

    var createUserFunction = cloudFunctions.httpsCallable("createUser");

    return createUserFunction({
      displayName: displayName,
      email: userName,
      roles,
      awards,
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateUser = async (uid, email, displayName, roles, awards) => {
    setLoading(true);

    var updateUserFunction = cloudFunctions.httpsCallable("updateUser");

    return updateUserFunction({
      uid,
      displayName: displayName,
      email: email,
      roles,
      awards,
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteUser = async (uid) => {
    setLoading(true);

    var removeUserRef = cloudFunctions.httpsCallable("removeUser");

    return removeUserRef({ uid })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    waiting,
    sendLinkMail,
    signOut,
    createUser,
    deleteUser,
    updateUser,
  };
}

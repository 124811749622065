import React from 'react';
import ContentLoader from "react-content-loader"
import useBreakpoints from 'hooks/useBreakpoint';
import {devices} from 'definitions';

export default function MenuLoader() {
	
	const {IsLessThan} = useBreakpoints();

	return (
			IsLessThan(devices.tabletXL) ? 

				''

			: 

                <ContentLoader 
                speed={2}
                width={366}
                height={70}
                viewBox="0 0 366 70"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                >
                    <rect x="0" y="26" rx="0" ry="0" width="100" height="15" /> 
                    <rect x="120" y="26" rx="0" ry="0" width="100" height="15" />
                    <rect x="240" y="26" rx="0" ry="0" width="100" height="15" />
                </ContentLoader>
	)
}

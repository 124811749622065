/* eslint-disable jsx-a11y/anchor-is-valid */
import SocialNetworks from 'components/SocialNetworks'
import Sadosky from 'icons/logos/Sadosky'
import React from 'react'
import { Container } from './styles'
import { Link } from 'wouter'

export default function MainLogo({ position }) {
    return (
        <Container>
            <Link to="/">
                <a>
                    <Sadosky position={position} />
                </a>
            </Link>

            <SocialNetworks location={position} />
        </Container>
    )
}

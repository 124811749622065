/* eslint-disable no-unused-vars */
import React from "react";
import {
  BreadcrumbContainer,
  BreadcrumbItem,
  BreadcrumbItemNumber,
  BreadcrumbItemDescription,
} from "./styles";
import CheckIcon from "icons/CheckIcon";
import { colors } from "definitions";
export function InternalBreadcrumb({
  topicCompleted,
  awardCompleted,
  formCompleted = false,
}) {
  return (
    !formCompleted && (
      <BreadcrumbContainer>
        <BreadcrumbItem type="topic">
          {topicCompleted ? (
            <CheckIcon
              color={colors.fonts.white}
              style={{ marginLeft: "0px" }}
            />
          ) : (
            <>
              <BreadcrumbItemNumber type="topic">01</BreadcrumbItemNumber>
              <BreadcrumbItemDescription type="award">
                Seleccione la temática en la que quieres participar
              </BreadcrumbItemDescription>
            </>
          )}
        </BreadcrumbItem>
        <BreadcrumbItem type="award">
          {awardCompleted ? (
            <CheckIcon
              color={colors.fonts.white}
              style={{ marginLeft: "30px" }}
            />
          ) : (
            <>
              <BreadcrumbItemNumber type="award">02</BreadcrumbItemNumber>
              <BreadcrumbItemDescription type="award">
                Seleccione el premio en el que quiere participar
              </BreadcrumbItemDescription>
            </>
          )}
        </BreadcrumbItem>
        <BreadcrumbItem type="form">
          <BreadcrumbItemNumber type="form">03</BreadcrumbItemNumber>
          <BreadcrumbItemDescription type="form">
            Complete los campos para postular su proyecto
          </BreadcrumbItemDescription>
        </BreadcrumbItem>
      </BreadcrumbContainer>
    )
  );
}

export const Breadcrumb = React.memo(InternalBreadcrumb);

import React from "react";

/*
export default function HappyFaceIcon(props) {
  return (
    <svg
      {...props}
      height={props.height}
      width={props.width}
      style={{ ...props.style, cursor: "pointer" }}
      viewBox="0 0 256 256"
    >
      <g
        fill={props.fill}
        strokeMiterlimit="10"
        strokeWidth="0"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      >
        <path d="M45 90C20.187 90 0 69.813 0 45S20.187 0 45 0s45 20.187 45 45-20.187 45-45 45zm0-86C22.393 4 4 22.393 4 45s18.393 41 41 41 41-18.393 41-41S67.607 4 45 4z"></path>
        <circle cx="31" cy="35.55" r="5"></circle>
        <circle cx="59" cy="35.55" r="5"></circle>
        <path d="M45 69.345c-7.954 0-15.337-3.969-19.751-10.617a2 2 0 113.332-2.212c3.671 5.529 9.809 8.83 16.419 8.83 6.61 0 12.748-3.301 16.419-8.83a2 2 0 113.332 2.212C60.337 65.376 52.953 69.345 45 69.345z"></path>
      </g>
    </svg>
  );
}*/

export default function HappyFaceIcon(props) {
  return (
    <svg
      {...props}
      height={props.height}
      width={props.width}
      style={{ ...props.style, cursor: "pointer" }}
      viewBox="0 0 32 32"
    >
      <path d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm-4.5 9A2.5 2.5 0 1 1 9 13.5a2.48 2.48 0 0 1 2.5-2.5ZM16 24a8 8 0 0 1-6.85-3.89l1.71-1a6 6 0 0 0 10.28 0l1.71 1A8 8 0 0 1 16 24Zm4.5-8a2.5 2.5 0 1 1 2.5-2.5 2.48 2.48 0 0 1-2.5 2.5Z" />
      <path
        d="M0 0h32v32H0z"
        style={{
          fill: "none",
        }}
      />
    </svg>
  );
}

HappyFaceIcon.defaultProps = {
  height: "6.5rem",
  width: "6.5rem",
  fill: "black",
};

import React, { useEffect, useState } from "react";
import { Image } from "./styles";

//import ItemsCarousel from "react-items-carousel";
import useBreakpoint from "hooks/useBreakpoint";
import { devices } from "definitions";
import { Sizes } from "model/dictionary";
import AutoPlayCarousel from "components/AutoPlayCarousel";

export default function SponsorsCarousel({ items, autoPlayDelay, size }) {
  const [numberOfCards, setNumberOfCards] = useState(0);

  const { IsLessThan } = useBreakpoint();

  useEffect(() => {
    let cards;

    cards = size === Sizes.MEDIUM ? 6 : 5;

    if (IsLessThan(devices.phablet)) {
      cards = size === Sizes.MEDIUM ? 1 : 1;
    } else if (IsLessThan(devices.tabletXL)) {
      cards = size === Sizes.MEDIUM ? 3 : 2;
    } else if (IsLessThan(devices.laptop)) {
      cards = size === Sizes.MEDIUM ? 4 : 3;
    } else if (IsLessThan(devices.wide)) {
      cards = size === Sizes.MEDIUM ? 5 : 4;
    }

    setNumberOfCards(cards);
  }, [IsLessThan, size]);

  return (
    <AutoPlayCarousel
      show={numberOfCards}
      infiniteLoop
      showControls={false}
      autoplay={true}
    >
      {items.map((partner) => {
        return (
          <Image
            key={partner.name}
            image={partner.url}
            height="75px"
            size={size}
            length={items.length}
            cards={numberOfCards}
          />
        );
      })}
    </AutoPlayCarousel>
  );
}

import React, { useState, memo, useEffect, useCallback } from "react";
import {
  FieldGroup,
  FieldInput,
  LinkInput,
  LinkContainer,
  FieldSelect,
  FieldLabel,
  CheckContainer,
  CheckInput,
  CheckSpan,
  CheckContainerInput,
  TextArea,
  ErrorMessage,
  RadioGroupOptions,
  RadioGroupContainer,
  Col,
  Break,
  FieldAutoCompleteGroup,
  AutoCompleteOptionContainer,
  AutoCompleteOption,
  InputContainer,
} from "./styles";

import useOutsideClose from "hooks/useOutsideClose";
import { wordCounter } from "helpers";
import Help from "components/Help";
export const InputField = memo(
  ({
    label,
    id,
    type = "text",
    register,
    required = false,
    error,
    value,
    onChange,
    placeholder,
    className,
    mode,
    readOnly,
    tabIndex,
    width,
    inputmode,
    cancelPaste,
    icon,
    at = false,
    prefix = "",
  }) => {
    const onPaste = (e) => {
      if (cancelPaste) {
        e.preventDefault();
      }
    };
    return (
      <FieldGroup
        withError={error}
        className={`field-input-group ${className}`}
        width={width}
      >
        <FieldLabel
          withError={error}
          mode={mode}
          htmlFor={id}
          required={required}
        >
          {label}
        </FieldLabel>

        <InputContainer>
          {icon && icon}
          {readOnly && type === "link" ? (
            <LinkContainer>
              {value && (
                <LinkInput
                  href={`${value.startsWith(prefix) ? "" : prefix}${value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${value.startsWith(prefix) ? "" : prefix}${value.substring(
                    0,
                    42
                  )}...`}
                </LinkInput>
              )}
            </LinkContainer>
          ) : (
            <FieldInput
              inputmode={inputmode}
              defaultValue={value}
              placeholder={placeholder}
              name={id}
              readOnly={readOnly}
              type={type}
              id={id}
              ref={register}
              mode={mode}
              tabIndex={tabIndex}
              autocomplete="off"
              //onPaste={onPaste}
              //onChange={(e) => onChange && onChange(id, e.target.value)}
              icon={icon}
              at={at}
            />
          )}
        </InputContainer>
        {error && <ErrorMessage mode={mode}>{error.message}</ErrorMessage>}
      </FieldGroup>
    );
  }
);

export const TextAreaField = ({
  id,
  label = "",
  required = false,
  register,
  error,
  onCounter,
  value,
  readOnly,
  mode,
}) => {

  return (
    <FieldGroup withError={error}>
      {label !== "" && (
        <FieldLabel htmlFor={id} required={required} mode={mode}>
          {label}
        </FieldLabel>
      )}
      <TextArea
        readOnly={readOnly}
        name={id}
        //id={id}
        ref={register}
        rows={5}
        defaultValue={value}
        //onChange={onChange}
        mode={mode}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </FieldGroup>
  );
};

export const RadioGroupField = memo(
  ({ id, value, register, options, error, mode, readOnly, required }) => {
    return (
      <RadioGroupContainer>
        <RadioGroupOptions mode={mode}>
          {options.map((opt, index) => {
            return (
              <Col key={index}>
                <input
                  type="hidden"
                  name={`${id}.options[${index}].label`}
                  value={opt.label}
                  ref={register}
                />
                <input
                  type="hidden"
                  name={`${id}.options[${index}].value`}
                  value={opt.value}
                  ref={register}
                />
                <li>
                  <input
                    type="radio"
                    id={`${id}.value_${opt.value}`}
                    defaultChecked={opt.value === value}
                    ref={register}
                    value={opt.value}
                    disabled={readOnly}
                    name={`${id}.value`}
                  />

                  <label htmlFor={`${id}.value_${opt.value}`}>
                    <div className="check" />
                    <span>{opt.label}</span>
                    {opt.comment && <Help helpText={opt.comment} />}
                  </label>
                </li>
              </Col>
            );
          })}
        </RadioGroupOptions>
        {error && <ErrorMessage mode={mode}>{error.message}</ErrorMessage>}
      </RadioGroupContainer>
    );
  }
);

export const CheckBoxesField = ({
  id,
  label,
  required,
  options,
  error,
  register,
  mode,
  twoLines,
  readOnly,
  onChange,
}) => {
  return (
    <FieldGroup className={`field-input-group`}>
      <CheckContainerInput mode={mode}>
        {label && (
          <FieldLabel htmlFor={id} required={required} mode={mode}>
            {label}
          </FieldLabel>
        )}

        {options.map((opt, index) => {
          return (
            <Col key={`${id}_${opt.value}`}>
              <input
                type="hidden"
                name={`${id}[${index}].label`}
                value={opt.label}
                ref={register}
              />
              <input
                type="hidden"
                name={`${id}[${index}].value`}
                value={opt.value}
                ref={register}
              />

              <CheckBoxField
                bold={false}
                register={register}
                value={opt.value}
                checked={opt.checked}
                label={opt.label}
                id={`${id}[${index}].checked`}
                twoLines={twoLines}
                readOnly={readOnly}
                mode={mode}
                onChange={(value) => {
                  onChange && onChange(opt.value, value);
                }}
              />
            </Col>
          );
        })}
        {error && <ErrorMessage mode={mode}>{error.message}</ErrorMessage>}
      </CheckContainerInput>
    </FieldGroup>
  );
};

export const CheckBoxField = memo(
  ({
    id,
    label,
    value,
    secondary,
    link,
    error,
    onChange,
    register,
    bold = true,
    checked = false,
    showRow,
    twoLines,
    mode,
    readOnly,
    required,
    filter = false,
  }) => {
    const [internalChecked, setInternalChecked] = useState(checked);

    return (
      <CheckContainer
        checked={internalChecked}
        showRow={showRow}
        mode={mode}
        className={`checkbox-field`}
      >
        <CheckInput
          name={`${id}`}
          id={`${id}_${value}`}
          type="checkbox"
          onChange={(event) => {
            setInternalChecked(!internalChecked);
            onChange && onChange(event.target.checked);
          }}
          ref={register}
          defaultChecked={checked}
          mode={mode}
          readOnly={readOnly}
          required={required}
        />

        <CheckSpan readOnly={readOnly} showRow={showRow} mode={mode} />

        {twoLines && <Break show={twoLines} />}

        {label && (bold ? <b>{label}</b> : <label>{label}</label>)}
        <div>
          {secondary && <em>{secondary}</em>}
          {link && (
            <a href="/reglamento" target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          )}
        </div>

        {error && <ErrorMessage mode={mode}>{error.message}</ErrorMessage>}
      </CheckContainer>
    );
  }
);

export const ErrorMessageBar = memo((message) => <small>{message}</small>);

export function SelectField({
  id,
  label,
  placeholder = "Seleccionar un elemento",
  defaultOption,
  options,
  required,
  onChange,
  value,
  register,
  error,
  tabIndex,
  readOnly,
  mode,
  multiple,
}) {
  const [optionSelected, setOptionSelected] = useState();

  useEffect(() => {
    if (value) {
      setOptionSelected(value);
    }
  }, [value]);

  const handleOptionSelected = (target) => {
    if (multiple) {
      var selected = [];

      for (var i = 0, l = target.options.length; i < l; i++) {
        if (target.options[i].selected) {
          selected.push(options[i].value);
        }
      }

      setOptionSelected(selected);
    } else {
      setOptionSelected(target.value);
    }
  };

  return (
    <FieldGroup className={`field-input-group`} multiple={multiple}>
      <FieldLabel mode={mode} htmlFor={id} required={required}>
        {label}
      </FieldLabel>
      <FieldSelect
        multiple={multiple}
        value={optionSelected}
        tabIndex={tabIndex}
        placeholder={placeholder}
        witherror={error}
        name={id}
        id={id}
        ref={register}
        mode={mode}
        disabled={readOnly}
        onChange={(e) => {
          handleOptionSelected(e.target);

          onChange && onChange(id, e.target.value);
        }}
      >
        {defaultOption && <option value="">{defaultOption}</option>}
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </FieldSelect>
      {error && <ErrorMessage mode={mode}>{error.message}</ErrorMessage>}
    </FieldGroup>
  );
}

export function AutoCompleteField({
  label,
  id,
  register,
  required = false,
  error,
  value,
  setValue,
  mode,
  readOnly,
  tabIndex,
  parentId,
  onFetch,
}) {
  const [display, setDisplay] = useState(false);

  const [options, setOptions] = useState([value]);

  const [search, setSearch] = useState((value && value.label) || "");

  const { node } = useOutsideClose(() => setDisplay(false));

  useEffect(() => {
    register({ name: id });
    setValue(id, value || {});

    setSearch(value.label);
  }, [register, setValue, id, value]);

  const onChange = (label) => {
    setSearch(label);

    if (label.length >= 3) {
      onFetch(parentId, label).then(setOptions);

      setDisplay(true);
    } else {
      setDisplay(false);
      setOptions([]);
    }
  };

  const onSelected = (value) => {
    setSearch(value.label);
    setDisplay(false);

    setValue(id, value);
  };

  return (
    <FieldAutoCompleteGroup ref={node} withError={error}>
      <FieldLabel
        withError={error}
        mode={mode}
        htmlFor={id}
        required={required}
      >
        {label}
      </FieldLabel>
      <FieldInput
        value={search}
        placeholder="escriba por lo menos 3 letras"
        name={id}
        readOnly={readOnly}
        type="text"
        id={id}
        ref={register}
        mode={mode}
        autocomplete="off"
        tabIndex={tabIndex}
        onChange={(event) => onChange(event.target.value)}
      />

      {error && <ErrorMessage mode={mode}>{error.message}</ErrorMessage>}

      {display && (
        <AutoCompleteOptionContainer>
          {options
            .filter(
              ({ label }) =>
                label.toLowerCase().indexOf(search.toLowerCase()) > -1
            )
            .map((value, i) => {
              return (
                <AutoCompleteOption
                  onClick={() => onSelected(value)}
                  className="option"
                  key={i}
                  tabIndex={parseInt(tabIndex) + 1}
                >
                  {value.label}
                </AutoCompleteOption>
              );
            })}
        </AutoCompleteOptionContainer>
      )}
    </FieldAutoCompleteGroup>
  );
}

export function ColorField({ value }) {
  return (
    <div
      style={{
        padding: "2px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          backgroundColor: `${value}`,
        }}
      />
    </div>
  );
}

import styled from 'styled-components';

import { colors, breakpoints } from 'definitions';

import CameraIcon from 'icons/CameraIcon'

export const Camera = styled(CameraIcon)`
 	cursor: pointer;
    vertical-align: middle;
    width: 50%;
    height: 50%;
    padding: 25px;
    cursor: pointer;
    fill: ${colors.icons.white};
    content: '';
    background-color: #C4C4C4;
    border-radius: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: 100px 100px;
`;

export const Circle = styled.div`
	width: 300px;
	height: 300px;
	position: relative;
	border-radius: 50%;
	background: ${colors.background.veryLightGray};
	margin-right: 64px;
	margin-left: 15px;
	outline: none;
	transition: border .24s ease-in-out;

	:hover {
		opacity: 0.7;
	}

	${breakpoints.laptop} {
		margin: 0px;
	}

	${breakpoints.phablet} {
		width: 260px;
		height: 260px;
	}
`;

export const Picture = styled.img`
	width: 300px;
	height: 300px;
	position: relative;
	border-radius: 50%;
	margin-right: 64px;
	outline: none;
	transition: border .24s ease-in-out;
    cursor: pointer;
	object-fit: cover;
	:hover {
		opacity: 0.7;
	}

	${breakpoints.laptop} {
		margin: 0px;
	}

	${breakpoints.mobile} {
		width: 260px;
		height: 260px;
	}
`;

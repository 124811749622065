import * as React from "react";

const TwitterLineIcon = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.917 6.171a8.56 8.56 0 0 1-2.455.673 4.286 4.286 0 0 0 1.88-2.365c-.826.49-1.741.846-2.715 1.036a4.275 4.275 0 0 0-7.283 3.9 12.138 12.138 0 0 1-8.81-4.467 4.272 4.272 0 0 0-.578 2.15c0 1.482.755 2.791 1.9 3.557a4.264 4.264 0 0 1-1.935-.534v.054a4.276 4.276 0 0 0 3.429 4.192 4.304 4.304 0 0 1-1.93.073 4.277 4.277 0 0 0 3.992 2.967 8.577 8.577 0 0 1-5.308 1.83c-.345 0-.686-.02-1.02-.059a12.088 12.088 0 0 0 6.55 1.92c7.864 0 12.162-6.512 12.162-12.16 0-.184-.004-.369-.013-.553a8.688 8.688 0 0 0 2.132-2.21l.002-.004Z"
      stroke="#006EDF"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);

export default TwitterLineIcon;

import styled from "styled-components";
import { colors } from "definitions";

export const Container = styled.div`
  background-color: #451CB8;
  height: auto;

  width: 100%;
  max-width: 124.5rem;

  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  border-top: solid 1px ${colors.background.lightGray};

  padding: 1rem;

  border-radius: 0 0 6px 6px;
`;

import React from "react";

import {
  Container,
  Options,
  Block,
  Result,
  UnselectionButton,
  SelectionButton,
} from "./styles";

export default function Ponderation({
  criteria,
  entryId,
  onSelected,
  selected,
  onCleanSelected,
}) {
  return (
    <Container selected={selected}>
      <Options>
        <Block>
          <Result>
            <label>Valoraciones</label>
            <span> {criteria.users}</span>
          </Result>

          <Result>
            <label>Total</label>
            <span> {criteria.ponderation}</span>
          </Result>

          <Result>
            <label>Promedio</label>
            <span>
              {criteria.users === 0 ? 0 : criteria.ponderation / criteria.users}
            </span>
          </Result>
        </Block>

        <Block>
          {!selected && (
            <SelectionButton
              onClick={() => {
                onSelected(entryId);
              }}
            >
              Seleccionar
            </SelectionButton>
          )}

          {selected && (
            <UnselectionButton
              onClick={() => {
                onCleanSelected(entryId);
              }}
            >
              Borrar selección
            </UnselectionButton>
          )}
        </Block>
      </Options>
    </Container>
  );
}

export function Votes({
  votes,
  entryId,
  onSelected,
  selected,
  onCleanSelected,
}) {
  return (
    <Container selected={selected}>
      <Options>
        <Block>
          <Result>
            <label>Votos</label>
            <span> {votes}</span>
          </Result>
        </Block>

        <Block>
          {!selected && (
            <SelectionButton
              onClick={() => {
                onSelected(entryId);
              }}
            >
              Seleccionar
            </SelectionButton>
          )}

          {selected && (
            <UnselectionButton
              onClick={() => {
                onCleanSelected(entryId);
              }}
            >
              Borrar selección
            </UnselectionButton>
          )}
        </Block>
      </Options>
    </Container>
  );
}

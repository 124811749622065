import React from "react";
import AdminPage from "components/AdminPage";
import CeoToolbar from "crud/ceo/CeoToolbar";
import CeoCard from "crud/ceo/CeoCard";
import CeoForm from "crud/ceo/CeoForm";

import { object, string, boolean } from "yup";
import useEntryForm from "hooks/useEntryForm";

export default function Ceo() {
  const { createCEOForm } = useEntryForm();

  const schema = object({
    actived: boolean(),
    title: string().required("El nombre es obligatorio"),
    lastName: string().required("El apellido es obligatorio"),
    company: string().required("El empresa es obligatoria"),
    award: string().required("El premio es obligatorio"),
    email: string()
      .email("Ingrese un mail con el formato correcto")
      .required("El mail es obligatorio"),
  });

  const sortOptions = [
    {
      value: "title",
      label: "Nombre",
    },
    {
      value: "lastName",
      label: "Apellido",
    },
  ];

  const createItem = (fields) => {
    return createCEOForm(
      fields.title,
      fields.lastName,
      fields.company,
      fields.email,
      fields.reason,
      fields.award
    );
  };

  return (
    <AdminPage
      title="CEO del año"
      seoTitle="Administración de CEO"
      externalCreateItem={createItem}
      sortOptions={sortOptions}
      collection="ceos"
      schema={schema}
      ItemFormFields={CeoForm}
      ItemCard={CeoCard}
      ItemToolBar={CeoToolbar}
    />
  );
}

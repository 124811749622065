import styled from "styled-components";
import { colors, breakpoints } from "definitions";

export const TitleContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;

  margin: -18rem 0 0 0;
  z-index: 1000;

  ${breakpoints.phablet} {
    margin: -16rem 0 0 0;
  }
`;
export const Title = styled.h1`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 56px;
  color: ${colors.fonts.white};
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1000;

  ${breakpoints.phablet} {
    font-size: 40px;
    line-height: 52px;
  }

  ${breakpoints.mobile} {
    font-size: 35px;
  }
`;

import React from 'react';

import { Container, Message, Options, Block } from './styles';

import { ConfirmButton, CancelButton } from 'basics/buttons';

export default function Confirmation({ type, text, onClick, paramOk, cancel, paramCancel}) {

    return (
        <Container>
            <Block>
                <Message type={type} >{text}</Message>
            </Block>

            <Block>
                <Options>
                    <ConfirmButton type={type} onClick={ () => onClick(paramOk)} >Aceptar</ConfirmButton>
                    <CancelButton onClick={() => cancel(paramCancel)}>Cancelar</CancelButton>
                </Options>
            </Block>
        </Container>
    );
}
import { useRef, useCallback, useEffect } from 'react';

export default function useOutsideClose(onClose) {
    const node = useRef(null)

    const handleClick = useCallback((e) => {
		if (node.current && node.current.contains(e.target)) {
			return;
        }
        
        onClose();
        
	}, [onClose]);

    useEffect(() => {
		document.addEventListener("mousedown", handleClick);

		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [handleClick]);


    return {node}
}

import React from "react";
import { Container, Main, Footer, Buttons, ContainerField } from "./styles";

import { SelectField } from "basics";

import { YellowButton } from "basics/buttons";

import { CheckBoxField } from "basics";

export default function ToolBar({
  onOpenNewForm,
  sortOptions,
  showNewButton,
  onSorting,
  children,
  onOnlyActivated,
}) {
  const handleSorting = (field, value) => {
    onSorting(value, "asc");
  };

  return (
    <Container>
      <Main>{children}</Main>
      <Footer>
        <ContainerField>
          <SelectField
            options={sortOptions}
            id="sortQuery"
            value={sortOptions[0].value}
            label="Ordenar Por"
            onChange={handleSorting}
          />
          <CheckBoxField
            label="Mostrar activos"
            id="switchActived"
            showRow={true}
            checked={true}
            onChange={(checked) => onOnlyActivated(checked)}
          />
        </ContainerField>
        <Buttons>
          {showNewButton && (
            <YellowButton onClick={() => onOpenNewForm()}>Nuevo</YellowButton>
          )}
        </Buttons>
      </Footer>
    </Container>
  );
}

import React from "react";

import Header from "components/Header";
import Hero from "components/Hero";
import Footer from "components/Footer";

import Routes from "components/Routes";

import { AppContainer } from "./styles";

import { AuthProvider } from "contexts/AuthContext";
import { CompetitionProvider } from "contexts/CompetitionContext";
import { AlertProvider } from "contexts/AlertContext";

export default function App() {

  return (
    <AppContainer>
      <AlertProvider>
        <AuthProvider>
          <Header />
          <CompetitionProvider>
            <Hero />
            <Routes />
          </CompetitionProvider>
        </AuthProvider>
        <Footer />
      </AlertProvider>
    </AppContainer>
  );
}

import React from "react";

import { InputField, SelectField } from "basics";
import FileUploader from "components/FileUploader";

import { Sizes } from "model/options";
export default function SponsorForm({ register, setValue, errors, item }) {
  return (
    <>
      <InputField
        label="Categoria"
        type="text"
        value={item && item.title}
        id="title"
        register={register}
        errors={errors}
        mode="dark"
      />

      <InputField
        label="Color"
        type="color"
        value={item && item.color}
        id="color"
        register={register}
        errors={errors}
        mode="dark"
      />

      <InputField
        label="Orden"
        type="number"
        value={item && item.order}
        id="order"
        register={register}
        errors={errors}
        mode="dark"
      />

      <SelectField
        options={Sizes}
        id="size"
        label="Tamaño de los logos"
        value={item && item.size}
        placeholder="Elija un tamaño"
        register={register}
        errors={errors}
        mode="dark"
      />

      <FileUploader
        label="Logos"
        id="logos"
        value={item && item.logos}
        folder={`sponsors`}
        register={register}
        error={errors.logos}
        setValue={setValue}
        accepted="image/*"
        mode="dark"
        multiple={true}
      />
    </>
  );
}
